import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { diff } from 'deep-object-diff';

export interface FormMessageType {
  status: string;
  message: string;
}

export const defaultFormMessage = { status: '', message: '' };

export function useFormMessage() {
  return useState<FormMessageType>(defaultFormMessage);
}

export interface BasicFormProps {
  values?: any;
  translation?: string;
  onSubmit: (...args: any[]) => Promise<void>;
  onDelete?: (id: number) => void;
  formMessage?: FormMessageType;
}

export interface FormProps {
  translation?: string;
}

interface FormMessageProps {
  message?: FormMessageType;
  className?: string;
}

export const FormMessage: React.SFC<FormMessageProps> = ({
  message = defaultFormMessage,
  className = 'text-right mb-3',
}) => {
  return (
    message && (
      <Form.Text className={`text-${message.status} ${className}`}>{message.message}</Form.Text>
    )
  );
};

export const getChangedFields = (initialValues: any, values: any) =>
  Object.keys(diff(initialValues, values)).filter((val: string) => val !== 'changedFields');
