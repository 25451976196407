import React, { useState } from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  SpinnerButton,
  DeleteButton,
  BasicFormProps,
  FormProps,
  SpinnerPageLoading,
} from '../../components';
import { useStateValue } from '../../state';
import { Field, Option } from '../../components/fields';
import { toOptions, keysToOptions, useApi, validateOrganizationNumber } from '../../lib';
import { DialogValues } from '../Customers/CreateCustomerDialog';

export interface FormValues extends DialogValues {
  id: number;
  meta: any;
  departments: number[] | Option[] | undefined;
  kamailio: number;
}

interface CustomerFormProps extends BasicFormProps {
  id: string;
  onDelete: (id: number) => void;
}

export const CustomerForm: React.SFC<CustomerFormProps> = ({
  translation,
  onSubmit,
  onDelete,
  id,
}) => {
  const { t } = useTranslation();
  const [{ settings }] = useStateValue();
  const [customer, loadingCustomer] = useApi('customers', id);

  if (loadingCustomer) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  if (!settings) {
    return null;
  }

  const options = {
    departments: toOptions(customer.meta.departments),
  };

  const formValues = {
    ...customer,
    departments: keysToOptions(customer.meta.departments, customer.departments as number[]),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t(`${translation}.name.invalid`)),
    orgNumber: Yup.string()
      .required(t(`${translation}.orgNumber.missing`))
      .test('check-format', t(`${translation}.orgNumber.invalid`), (value) =>
        Boolean(validateOrganizationNumber(value || ''))
      ),
  });

  const CustomerFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const { handleSubmit, dirty, isSubmitting, submitForm } = props;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <div>
          <Form.Row>
            <Field {...props} id="name" type="text" md={6} />
            <Field {...props} id="orgNumber" type="text" md={3} />
            <Field
              {...props}
              type="reactSelect2"
              id="departments"
              options={options.departments}
              md={3}
              isMulti
            />
            <Field {...props} id="address" type="text" md={6} />
            <Field {...props} id="postCode" type="text" md={3} />
            <Field {...props} id="postAddress" type="text" md={3} />
          </Form.Row>
          <h5>{t(`${translation}.contactsHeader`)}</h5>
          <Form.Row>
            <Field {...props} id="emailContact1" type="text" md={6} />
            <Field {...props} id="phoneNumberContact1" type="text" md={6} />
            <Field {...props} id="emailContact2" type="text" md={6} />
            <Field {...props} id="phoneNumberContact2" type="text" md={6} />
            <Field {...props} id="emailTechContact1" type="text" md={6} />
            <Field {...props} id="phoneNumberTechContact1" type="text" md={6} />
          </Form.Row>
        </div>

        <div className="d-flex justify-content-end">
          <DeleteButton
            onDelete={onDelete}
            disabled
            title="Not implemented in database!"
            isSubmitting={isSubmitting}
          />
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </div>
      </Form>
    );
  };

  const CustomerFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => formValues,
    validationSchema,
    handleSubmit: onSubmit,
  })(CustomerFormInner);

  return <CustomerFormFormik translation={translation} />;
};
