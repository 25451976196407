import { useEffect } from 'react';
import { History } from 'history';
import { useTranslation } from 'react-i18next';
import {
  useStateValue,
  setIsAuthenticated,
  setAdministrator,
  setSettings,
  Administrator,
} from '../state';
import { api, logger } from '../lib';
import { changeLanguage } from '../locales/i18n';

interface ConnectProps {
  history: History;
}

export const Connect: React.SFC<ConnectProps> = ({ history }) => {
  const [{ settings }, dispatch] = useStateValue();
  const { t } = useTranslation();

  const translateSettings = ({ meta }: Administrator) => {
    // mobileStatus
    meta.mobileStatus = meta.mobileStatus.map(({ value, label }) => {
      const translatedLabel = t(`statusIcons.mobileStatus.${value}.label`, label);
      return {
        value,
        label: translatedLabel,
        hover: t(`statusIcons.mobileStatus.${value}.hover`, translatedLabel),
        class: t(`statusIcons.mobileStatus.${value}.class`),
        icon: t(`statusIcons.mobileStatus.${value}.icon`),
      };
    });

    // subscriptionCallTypes
    meta.subscriptionCallTypes = meta.subscriptionCallTypes.map(({ value, label }) => ({
      value,
      label: t(`subscriptionCallTypes.${value}`, label),
    }));

    // subscriptionDataTypes
    meta.subscriptionDataTypes = meta.subscriptionDataTypes.map(({ value, label }) => ({
      value,
      label: t(`subscriptionDataTypes.${value}`, label),
    }));

    // subscriptionStatus
    meta.subscriptionStatus = meta.subscriptionStatus.map(({ value, label, description }: any) => {
      const translatedLabel = t(`statusIcons.subscriptionStatus.${value}.label`, label);
      return {
        value,
        label: translatedLabel,
        hover: t(`statusIcons.subscriptionStatus.${value}.hover`, description || translatedLabel),
        class: t(`statusIcons.subscriptionStatus.${value}.class`),
        icon: t(`statusIcons.subscriptionStatus.${value}.icon`),
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (document.cookie.indexOf('jwt=') >= 0) {
        try {
          // Load profile and settings
          const administrator = (await api.getEntry('administrators', 'connect')) as Administrator;
          changeLanguage(administrator.language);
          translateSettings(administrator);
          dispatch(setAdministrator(administrator));
          dispatch(setSettings({ ...settings, ...administrator.meta }));
          dispatch(setIsAuthenticated(true));
        } catch (error) {
          history.push(`/login`);
          logger('Connect.js', error);
        }
      } else {
        // Anonymous administrator
        changeLanguage(localStorage.getItem('language') || 'sv');
      }
    };

    fetchData();
  }, []);

  return null;
};
