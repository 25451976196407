import React from 'react';
import { Toast as BootstrapToast } from 'react-bootstrap';
import { AlertTriangle, Info } from 'react-feather';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { useStateValue, delToast, addToast, DispatchAction } from '../state';

export interface Toast {
  id: string;
  title: string;
  body: string;
  type: string;
  time?: string;
  delay?: number;
}

export const showToast = ({ title, body, type }: Omit<Toast, 'id'>, dispatch: DispatchAction) => {
  dispatch(addToast({ title, body, type, id: uuidv4(), time: format(new Date(), 'HH:mm:ss') }));
};

export const deleteToast = (toasts: Toast[], id: string) => {
  const toastsCopy = [...toasts]; // Do not mutate :-).
  toastsCopy.splice(
    toastsCopy.findIndex((toast) => id === toast.id),
    1
  );
  return toastsCopy;
};

export const ToastComponent = ({ id, title, body, type, time, delay = 3000 }: Toast) => {
  const [, dispatch] = useStateValue();

  const icon = type === 'danger' ? <AlertTriangle /> : <Info />;
  return (
    <BootstrapToast
      onClose={() => dispatch(delToast(id))}
      // delay={delay}
      // autohide
      key={id}
      className={`bg-${type}`}
    >
      <BootstrapToast.Header>
        {icon}
        <strong className="mr-auto">{title}</strong>
        <small>{time}</small>
      </BootstrapToast.Header>
      <BootstrapToast.Body>{body}</BootstrapToast.Body>
    </BootstrapToast>
  );
};
