import React from 'react';
import AsyncSelect from 'react-select/async';
import { Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ReactAsyncSelectFieldProps {
  id: string;
  setFieldValue: any;
  values: any;
  touched: any;
  errors: any;
  isClearable?: boolean;
  md?: number;
  translation?: string;
  isDisabled?: boolean;
  onFieldChange?: any;
  loadOptions: any;
  info?: string;
  dialog?: any;
}

// https://react-select.com/styles
const customStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: state.isDisabled ? '#e9ecef' : 'white',
      color: state.isDisabled ? '#495057' : 'red',
      border: '1px solid #ced4da',
    };
  },
  singleValue: (provided: any) => {
    return { ...provided, color: '#495057' };
  },
};

export const ReactAsyncSelectField: React.SFC<ReactAsyncSelectFieldProps> = (props) => {
  const {
    id,
    md,
    translation,
    setFieldValue,
    values,
    touched,
    errors,
    onFieldChange,
    loadOptions,
    info,
    dialog,
    ...rest
  } = props;

  const { t } = useTranslation();
  const transBase = `${translation}.${id}`;

  const handleChange = (option: any) => {
    if (onFieldChange) {
      onFieldChange(option, id, setFieldValue);
    }

    setFieldValue(id, option);
  };

  return (
    <Form.Group as={Col} md={md}>
      <Form.Label>
        {t(`${transBase}.label`, '')} {dialog}
      </Form.Label>
      <AsyncSelect
        {...rest}
        name={id}
        value={values[id]}
        loadOptions={loadOptions}
        styles={customStyles}
        onChange={handleChange}
        placeholder={t([`${transBase}.placeholder`, 'placeholders.select'], '')}
      />
      {touched[id] && errors[id] && (
        <small className="form-text text-danger">{t(`${transBase}.invalid`)}</small>
      )}
      {touched[id] && !errors[id] && (
        <small className="form-text text-success">{t(`${transBase}.valid`, '')}</small>
      )}
      <small className="form-text text-muted">{t(`${transBase}.info`, '')}</small>
      {info && (
        <small className="form-text text-muted">{t(info ? `${transBase}.info2` : '', info)}</small>
      )}
    </Form.Group>
  );
};
