import React from 'react';
import { Button } from 'react-bootstrap';
import { PlusSquare, MinusSquare } from 'react-feather';

export const CollapseButton = ({
  collapsed,
  onClick,
}: {
  collapsed: boolean;
  onClick: () => void;
}) => (
  <Button variant="link" role="button" onClick={onClick}>
    {collapsed ? <MinusSquare /> : <PlusSquare />}
  </Button>
);
