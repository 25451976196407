/**
 *  Generics
 */

const generics = {
  languages: {
    sv: 'Svenska',
    en: 'Engelska',
  },
  fields: {
    toggle: {
      on: 'På',
      off: 'Av',
    },
  },
  buttons: {
    save: 'Spara',
    new: 'Ny',
    delete: 'Radera',
    cancel: 'Avbryt',
    ok: 'Ok',
    yes: 'Yes',
    no: 'No',
    previous: 'Föregående',
    next: 'Nästa',
    import: 'Importera',
    export: 'Exportera',
    run: 'Kör',
    plus: '+',
    disable: 'Avsluta',
  },
  placeholders: {
    select: 'Välj...',
  },
  loading: {
    page: 'Laddar sida...',
    form: 'Laddar form...',
    connecting: 'Ansluter...',
  },
  subscriptionCallTypes: {
    1: 'Full MEX',
    2: 'Halv MEX',
    3: 'Standalone',
  },
  subscriptionDataTypes: {
    // 1: '1 GB',
    // 2: '5 GB',
    // 3: '10 GB',
    // 4: '50 GB',
    // 5: '100 GB',
    // 6: 'Unlimited!',
  },
  statusIcons: {
    administratorStatus: {
      blocked: {
        label: 'Blockerad',
        hover: 'Användaren är blockerad.',
        class: 'text-danger',
        icon: 'AlertTriangle',
      },
      notBlocked: {
        label: 'Ej blockerad',
        hover: 'Användaren är aktiv.',
        class: 'text-success',
        icon: 'CheckCircle',
      },
      confirmed: {
        label: 'Bekräftad',
        hover: 'Användaren har godkänts.',
        class: 'text-success',
        icon: 'CheckCircle',
      },
      notConfirmed: {
        label: 'Ej bekräftad',
        hover: 'Användaren har skapat ett konto men har ännu inte godkänts.',
        class: 'text-warning',
        icon: 'HelpCircle',
      },
    },
    mobileStatus: {
      1: {
        label: 'Används ej',
        class: 'text-muted',
        icon: 'MinusCircle',
      },
      2: {
        label: 'Porteras',
        hover: 'Portering pågår',
        class: 'text-warning',
        icon: 'AlertCircle',
      },
      3: {
        label: 'Aktiv',
        class: 'text-success',
        icon: 'CheckCircle',
      },
      4: {
        label: 'Flyttad',
        hover: 'Flyttad till annan operatör',
        class: 'text-danger',
        icon: 'XCircle',
      },
      5: {
        label: 'Avslutad',
        class: 'text-danger',
        icon: 'XCircle',
      },
    },
    subscriptionStatus: {
      1: {
        label: 'Ej komplett',
        hover: 'Abonnemang saknar data.',
        class: 'text-danger',
        icon: 'HelpCircle',
      },
      2: {
        label: 'Porteras',
        hover: 'Portering är beställd och bearbetas.',
        class: 'text-warning',
        icon: 'AlertCircle',
      },
      3: {
        label: 'Aktiveras',
        hover: 'Abonnemanget håller på att aktiveras.',
        class: 'text-warning',
        icon: 'AlertCircle',
      },
      4: {
        label: 'Aktiverat',
        hover: 'Abonnemanget är aktivt.',
        class: 'text-success',
        icon: 'CheckCircle',
      },
      5: {
        label: 'Inaktiverat',
        hover: 'Abonnemanget är inaktiverat.',
        class: 'text-muted',
        icon: 'CheckCircle',
      },
      6: {
        label: 'Avslutas',
        hover: 'Abonnemanget här flaggat för att avslutas.',
        class: 'text-warning',
        icon: 'CheckCircle',
      },
      7: {
        label: 'Avslutat',
        hover: 'Abonnemanget är avslutat.',
        class: 'text-muted',
        icon: 'AlertTriangle',
      },
    },
    unknown: {
      label: 'Okänd',
      hover: 'Denna status är inte definierad.',
      class: 'text-muted',
      icon: 'HelpCircle',
    },
  },
  pager: {
    rowsPerPage: 'Antal rader:',
    totalCount: 'Totalt antal:',
  },
};

/**
 *  Dialogs
 */

const dialogs = {
  delete: {
    title: 'Radera',
    body: 'Är du säker på att du vill radera?',
  },
  createAdministrator: {
    title: 'Skapa administratör',
    firstName: {
      label: 'Förnamn',
      invalid: 'Förnamn krävs.',
      // valid: '',
      // info: '',
    },
    lastName: {
      label: 'Efternamn',
      invalid: 'Efternamn krävs.',
    },
    email: {
      label: 'E-post',
      invalid: 'Felaktigt E-postadress',
      // info: 'E-postadress måste bekräftas efter ändring.'
    },
    phone: {
      label: 'Telefon',
    },
    language: {
      label: 'Språk',
    },
  },
  createMobile: {
    title: 'Lägg till nytt mobilnummer',
    customer: {
      label: 'Kund',
      invalid: 'Kund krävs vid portering.',
    },
    mobileNumber: {
      label: 'Mobilnummer',
      invalid: 'Felaktigt nummerformat',
      missing: 'Mobilnummer krävs',
      info: 'Ex: 46701234567',
    },
    fixedNumber: {
      label: 'Fastnätsnummer',
      invalid: 'Felaktigt nummerformat',
      missing: 'Fastnätsnummer krävs',
      info:
        'Ex: 4684590000 eller 46701234567. Notera även att det är tillåtet att använda ett mobilnummer, men rekommendationen är att använda ett fastnätsnummer.',
    },
    portDate: {
      label: 'Porteringsdatum',
    },
    portTime: {
      label: 'Porteringstid',
    },
    port: {
      label: 'Portera nummer',
      info: 'Ange om och när numret ska porteras.',
    },
  },
  createDepartment: {
    title: 'Lägg till ny avdelning',
    customer: {
      label: 'Kund',
      invalid: 'Kund krävs.',
    },
    name: {
      label: 'Namn',
      invalid: 'Namn krävs.',
    },
    description: {
      label: 'Beskrivning',
      invalid: 'Fastnätsnummer krävs.',
    },
    kamailio: {
      label: 'Kamailio id',
      invalid: 'Kamailioi id krävs.',
    },
  },
  createCustomer: {
    title: 'Lägg till ny kund',
    contactsHeader: 'Kontakter',
    extrasHeader: 'Övrigt',
    name: {
      label: 'Namn',
      invalid: 'Namn krävs.',
    },
    address: {
      label: 'Adress',
    },
    postAddress: {
      label: 'Postadress',
    },
    postCode: {
      label: 'Postnummer',
    },
    orgNumber: {
      label: 'Organisationsnummer',
      invalid: 'Felaktigt format',
      info: 'Format: xxxxxx-xxxx',
      missing: 'Organisationsnummer krävs.',
    },
    kamailio: {
      label: 'Kamailio id',
      invalid: 'Kamailio id måste vara större än 0.',
      info: 'Vad vill vi att användarna ska göra med Kamailio id? Lite info.',
    },
    department: {
      label: 'Avdelning',
      invalid: 'Avdelning krävs.',
      info: 'En avdelning måste alltid anges.',
    },
    emailContact1: {
      label: 'E-post kontakt 1',
    },
    phoneNumberContact1: {
      label: 'Telefon kontakt 1',
    },
    emailContact2: {
      label: 'E-post kontakt 2',
    },
    phoneNumberContact2: {
      label: 'Telefon kontakt 2',
    },
    emailTechContact1: {
      label: 'E-post teknik 1',
    },
    phoneNumberTechContact1: {
      label: 'Telefon teknik 1',
    },
  },
  createImsi: {
    title: 'Lägg till nytt IMSI',
    imsi: {
      label: 'IMSI',
      invalid: 'IMSI krävs',
    },
    iccid: {
      label: 'ICCID',
      invalid: 'ICCID krävs',
    },
    pin1: {
      label: 'Pinkod 1',
    },
    pin2: {
      label: 'Pinkod 2',
    },
    puk1: {
      label: 'Pukkod 1',
    },
    puk2: {
      label: 'Pukkod 2',
    },
    state: {
      label: 'Status',
    },
  },
  createSubscription: {
    title: 'Lägg till nytt abonnemang',
    customer: {
      label: 'Kund',
      invalid: 'Kund krävs',
      // info2: 'Organisationsnummer: {{orgNumber}}' // Info with parameter
    },
    department: {
      label: 'Avdelning',
      invalid: 'Avdelning krävs',
    },
    mobileNumber: {
      label: 'Mobilnummer',
      invalid: 'Mobilnummer krävs',
      placeholder: 'Sök mobilnummer...',
      info: 'Ange minst 3 siffror.',
    },
    orgNumber: {
      label: 'Organisations-/personnummer',
      invalid: 'Felaktigt format på personnummer (exempel: ÅÅMMDD-XXXX)',
      missing: 'Personnummer krävs',
    },
    usePersonnummer: {
      label: 'Använd personnummer',
      info: 'Använd personnummer istället för organisationsnummer.',
    },
    username: {
      label: 'Användarnamn',
    },
    costCenter: {
      label: 'Kostnadsställe',
    },
    type: {
      label: 'Typ',
    },
    dataType: {
      label: 'Mobildata',
    },
    iccid: {
      label: 'ICCID',
    },
    portDate: {
      label: 'Porteringsdatum',
    },
    portTime: {
      label: 'Porteringstid',
    },
  },
  activateSubscription: {
    title: 'Aktivera abonnemang',
    errors: {
      invalidPin1: 'Ogiltig pinkod 1',
      imsiDoesNotExist: 'ICCID saknas',
      imsiConnectedToMobileNumber: 'Ett SIM-kort är knutet till detta mobilnummer',
      mobileNumberConnectedToImsi: 'Mobilnummer är redan knuten till ett SIM-kort',
      multipleSubscriptionsSelected:
        'Mer än ett abonnemanng är valt, max ett abonnemang kan aktiveras.',
    },
    imsi: {
      label: 'ICCID',
      invalid: 'ICCID krävs',
      info:
        'Välj ICCID för det SIM som ska aktiveras. Det går även att söka på, t.ex. de sista fyra siffrorna.',
    },
    pin: {
      label: 'Pinkod 1',
      // invalid: 'Pinkod 1 består av 4 siffror.',
      length: 'Pinkod 1 består av 4 siffror.',
      required: 'Pinkod krävs',
      info: 'Pinkod används när användare ska aktiver sitt eget SIM-kort.',
    },
    mobileNumber: {
      label: 'Mobilnummer',
    },
    portDate: {
      label: 'Porteringsdatum',
    },
    portTime: {
      label: 'Porteringstid',
    },
    port: {
      label: 'Portera nummer',
      info: 'Ange om och när numret ska porteras.',
    },
  },
};

/**
 *  Application layout
 */

const application = {
  appBar: {
    logoTitle: 'TeleOffice',
    admin: 'Administration',
    logout: 'Logga ut',
    login: 'Logga in',
    loginAs: 'Inloggad som',
    me: 'Mina inställningar',
  },
  sideBar: {
    customers: 'Kunder',
    departments: 'Avdelningar',
    administrators: 'Administratörer',
    mobiles: 'Mobilnummer',
    imsis: 'IMSI-nummer',
    subscriptions: 'Abonnemang',
    functions: 'Mina funktioner',
    settingsSection: 'Inställningar',
    me: 'Mina inställningar',
    version: 'Version',
    development: 'Dev',
    production: 'Prod',
  },
  footer: {
    version: 'version',
  },
};

/**
 *  Pages
 */

const pages = {
  home: {
    title: 'TeleOffice Mobilportal',
    text:
      'Välkommen till TeleOffice Portal för mobiltelefoni. Här kan Du enkelt administrera Din och Ditt företags mobiltelefoner.',
    login: 'Logga in',
    logout: 'Logga ut',
    register: 'Registrera dig',
  },
  page404: {
    title: 'Woops 404!',
    text: 'Sidan du söker saknas tyvärr... kan det vara någon av följande sidor?',
    home: 'Hem',
    login: 'Logga in',
    register: 'Registrera dig',
  },
  register: {
    title: 'Registrera dig',
    successMessage: 'Välkommen',
    emailValidation:
      'Du måste nu bekräfta din epostadress. Instruktioner har skickats till {{email}}.',
    errors: {
      emailExist: '{{email}} är redan registrerad.',
    },
    form: {
      firstName: {
        label: 'Förnamn',
        invalid: 'Förnamn krävs.',
        // valid: '',
        // info: '',
      },
      lastName: {
        label: 'Efternamn',
        invalid: 'Efternamn krävs.',
      },
      email: {
        label: 'E-post',
        placeholder: 'Ange din E-postadress.',
        invalid: 'Felaktigt E-postadress',
        emailFormat: 'Ogiltig E-post.',
      },
      password: {
        label: 'Lösenord',
        invalid: 'Lösenord krävs',
        passwordLength: 'Lösenordet måste vara minst 8 tecken.',
      },
      submitButton: 'Registrera dig',
    },
  },
  forgotPassword: {
    title: 'Glömt lösenord?',
    errors: {
      notFound: 'Användare {{email}} är ej registrerad',
    },
    emailPasswordRecovery:
      'Instruktioner om hur du återskapar ditt lösenord har skickats till {{email}}.',
    readyToLogin: 'Redo att logga in?',
    form: {
      email: {
        label: 'E-post',
        placeholder: 'Ange din E-postadress.',
        invalid: 'Felaktigt E-postadress',
        emailFormat: 'Ogiltig E-post.',
      },
      submitButton: 'Skicka E-post',
    },
  },
  login: {
    title: 'Logga in',
    errors: {
      unauthorized: 'Fel lösenord.',
      notFound: 'Användare {{email}} är ej registrerad',
      blocked: 'Användare {{email}} är spärrad',
    },
    forgotPassword: 'Glömt lösenord',
    form: {
      email: {
        label: 'E-post',
        placeholder: 'Ange din E-postadress.',
        invalid: 'Felaktigt E-postadress',
        emailFormat: 'Ogiltig E-post.',
        // valid: 'Valid',
        // info: 'Info'
      },
      password: {
        label: 'Lösenord',
        invalid: 'Lösenord krävs',
        passwordLength: 'Lösenordet måste vara minst 8 tecken.',
      },
      submitButton: 'Logga in',
    },
  },
  updatePassword: {
    title: 'Uppdatera lösenord',
    form: {
      password: {
        label: 'Lösenord',
        invalid: 'Lösenord krävs',
        passwordLength: 'Lösenordet måste vara minst 8 tecken.',
      },
      confirmPassword: {
        label: 'Bekräfta lösenord',
        invalid: 'Lösenorden är inte lika.',
      },
      submitButton: 'Uppdatera lösenord',
    },
  },
  public: {
    menu: 'Publik sida',
    title: 'Publik sida',
    text: 'Denna sida visas för alla användare.',
  },
  administrators: {
    title: 'Administratörer',
    text: 'Alla adminstratörer som du har rättigheter att se.',
    createButton: 'Ny administratör',
    actions: {
      create: 'Lägg till ny administratör',
    },
    // import: 'Importera',
    // export: 'Exportera',
    filters: {
      customers: 'Kunder',
      roles: 'Roller',
      status: 'Status',
    },
    table: {
      id: 'Id',
      firstName: 'Namn',
      customers: 'Kunder',
      customerDepartments: 'Avdelningar',
      roles: 'Roller',
      status: 'Status',
      lastAdminAccess: 'Senaste access',
    },
  },
  mobiles: {
    title: 'Mobilnummer',
    text: 'Info...',
    createButton: 'Nytt mobilnummer',
    actions: {
      create: 'Lägg till nytt mobilnummer',
    },
    // import: 'Importera',
    // export: 'Exportera',
    // totalCount: 'Totalt antal',
    table: {
      mobileNumber: 'Mobilnummer',
      fixedNumber: 'Fastnätsnummer',
      status: 'Status',
      customer: 'Kund',
      // imsi: 'IMSI',
      // iccid: 'ICCID',
      portTime: 'Porteringstid',
    },
    filters: {
      mobileNumber: 'Mobilnummer',
      fixedNumber: 'Fastnätsnummer',
      status: 'Status',
      customer: 'Kund',
      // imsi: 'IMSI',
      // iccid: 'ICCID',
    },
  },
  mobile: {
    title: 'Mobilnummer',
    text: 'Konfiguration av mobilnummer',
    errors: {
      cannotDeleteStatusNotOne: 'Du kan endast radera nummer som har status Används ej.',
      cannotDeleteUsedBySubscription:
        'Mobilnummer är kopplat till ett abonnemang och kan inte raderas.',
    },
    form: {
      mobileNumber: {
        label: 'Mobilnummer',
        invalid: 'Felaktigt nummerformat',
        missing: 'Mobilnummer krävs',
        info: 'Exempel: 46701234567',
      },
      fixedNumber: {
        label: 'Fastnätsnummer',
        invalid: 'Felaktigt nummerformat',
        info: 'Exempel: 4684590000',
      },
      portDate: {
        label: 'Porteringsdatum',
      },
      portTime: {
        label: 'Porteringstid',
      },
      status: {
        label: 'Status',
      },
      customer: {
        label: 'Kund',
      },
      // imsi: {
      //   label: 'IMSI',
      // },
      iccid: {
        label: 'ICCID',
      },
    },
  },
  me: {
    title: 'Mina inställningar',
    text: 'Konfiguration och inställningar för denna användare.',
    form: {
      sections: {
        contactInfo: 'Kontaktinformation',
        permissions: 'Behörighet',
      },
      firstName: {
        label: 'Förnamn',
        invalid: 'Förnamn krävs.',
        // valid: '',
        // info: '',
      },
      lastName: {
        label: 'Efternamn',
        invalid: 'Efternamn krävs.',
      },
      description: {
        label: 'Titel/beskrivning',
        placeholder: 'Not implemented in database!',
      },
      email: {
        label: 'E-post',
        invalid: 'Felaktigt E-postadress',
        // info: 'E-postadress måste bekräftas efter ändring.'
      },
      password: {
        label: 'Lösenord',
        invalid: 'Lösenord krävs',
        passwordLength: 'Lösenordet måste vara minst 8 tecken.',
      },
      phone: {
        label: 'Telefon',
      },
      mobile: {
        label: 'Mobil',
      },
      language: {
        label: 'Språk',
      },
      blocked: {
        label: 'Blockerad',
      },
      confirmed: {
        label: 'E-post bekräftad',
      },
      customers: {
        label: 'Kund',
        info:
          'Kunder som denna administratör kan administrera. Denna inställning saknar tabell. Behövs den?',
      },
      admDepartments: {
        label: 'Administrativa avdelningar',
        info: 'Avdelningar som denna administratör kan administrera.',
      },
      roles: {
        label: 'Roller',
        info: 'Behörighet för denna administratör.',
      },
    },
  },
  imsis: {
    title: 'IMSI',
    text: 'Info...',
    createButton: 'Nytt IMSI',
    createNotAllowed: 'Ej tillåtet att skapas IMSI i detta gränssnitt.',
    actions: {
      create: 'Lägg till nytt IMSI',
    },
    table: {
      imsi: 'IMSI',
      iccid: 'ICCID',
      customer: 'Kund',
      pin1: 'Pinkod 1',
      pin2: 'Pinkod 2',
      puk1: 'Pukkod 1',
      puk2: 'Pukkod 2',
      state: 'Status',
    },
    filters: {
      imsi: 'IMSI',
      iccid: 'ICCID',
      customer: 'Kund',
    },
  },
  imsi: {
    title: 'IMSI',
    text: 'Konfiguration av IMSI',
    form: {
      imsi: {
        label: 'IMSI',
        invalid: 'IMSI krävs',
      },
      iccid: {
        label: 'ICCID',
        invalid: 'ICCID krävs',
      },
      customer: {
        label: 'Kund',
      },
      pin1: {
        label: 'Pinkod 1',
      },
      pin2: {
        label: 'Pinkod 2',
      },
      puk1: {
        label: 'Pukkod 1',
      },
      puk2: {
        label: 'Pukkod 2',
      },
      state: {
        label: 'Status',
      },
    },
  },
  customers: {
    title: 'Kunder',
    text: 'Info...',
    createButton: 'Ny kund',
    actions: {
      create: 'Lägg till ny kund',
    },
    table: {
      name: 'Namn',
      address: 'Adress',
      postAddress: 'Postadress',
      postCode: 'Postnummer',
      orgNumber: 'Organisationsnr',
      emailContact1: 'E-post kontakt 1',
      phoneNumberContact1: 'Telefon kontakt 1',
      emailContact2: 'E-post kontakt 2',
      phoneNumberContact2: 'Telefon kontakt 2',
      emailTechContact1: 'E-post teknik 1',
      phoneNumberTechContact1: 'Telefon teknik 1',
    },
    filters: {
      name: 'Kund',
      orgNumber: 'Organisationsnummer',
      postAddress: 'Postadress',
      email: 'E-post',
      phone: 'Telefon',
    },
  },
  customer: {
    title: 'Kund',
    text: 'Konfiguration av kund',
    form: {
      contactsHeader: 'Kontakter',
      name: {
        label: 'Namn',
        invalid: 'Namn krävs.',
      },
      address: {
        label: 'Adress',
      },
      postAddress: {
        label: 'Postadress',
      },
      postCode: {
        label: 'Postnummer',
      },
      orgNumber: {
        label: 'Organisationsnummer',
        invalid: 'Felaktigt format',
        info: 'Format: xxxxxx-xxxx',
        missing: 'Organisationsnummer krävs.',
      },
      kamailio: {
        label: 'Kamailio id',
      },
      departments: {
        label: 'Avdelningar',
      },
      emailContact1: {
        label: 'E-post kontakt 1',
      },
      phoneNumberContact1: {
        label: 'Telefon kontakt 1',
      },
      emailContact2: {
        label: 'E-post kontakt 2',
      },
      phoneNumberContact2: {
        label: 'Telefon kontakt 2',
      },
      emailTechContact1: {
        label: 'E-post teknik 1',
      },
      phoneNumberTechContact1: {
        label: 'Telefon teknik 1',
      },
    },
  },
  departments: {
    title: 'Avdelningar',
    text: 'Info...',
    createButton: 'Ny avdelning',
    actions: {},
    table: {
      name: 'Namn',
      customer: 'Kund',
      description: 'Beskrivning',
      kamailio: 'Kamailio id',
    },
    filters: {
      name: 'Avdelning',
      customer: 'Kund',
      kamailio: 'Kamailio ID',
    },
  },
  department: {
    title: 'Avdelning',
    text: 'Konfiguration av avdelning',
    form: {
      name: {
        label: 'Namn',
        invalid: 'Namn krävs.',
      },
      customer: {
        label: 'Kund',
        invalid: 'Kund krävs.',
      },
      description: {
        label: 'Beskrivning',
      },
      kamailio: {
        label: 'Kamailio id',
        invalid: 'Kamailio id krävs.',
      },
    },
  },
  subscriptions: {
    title: 'Abonnemang',
    text: 'Info...',
    createButton: 'Nytt abonnemang',
    activateButton: 'Aktivera abonnemang',
    actions: {
      create: 'Lägg till nytt abonnemang',
      activate: 'Aktivera abonnemang',
    },
    table: {
      customer: 'Kund',
      department: 'Avdelning',
      customerReference: 'Kundreferens',
      username: 'Användarnamn',
      startTime: 'Starttid',
      endTime: 'Sluttid',
      mobileNumber: 'Mobilnummer',
      mobileStatus: 'Mobilstatus',
      meta: 'Meta',
      iccid: 'ICCID',
      status: 'Status',
    },
    filters: {
      customer: 'Kund',
      department: 'Avdelning',
      mobileNumber: 'Mobilnummer',
      mobileStatus: 'Mobilstatus',
      iccid: 'ICCID',
      status: 'Status',
    },
  },
  subscription: {
    title: 'Abonnemang',
    text: 'Konfiguration av abonnemang',
    mobileData: 'Mobildata',
    totalData: 'Total data (denna månad):',
    gigabyte: 'GB',
    megabyte: 'MB',
    usedData: 'Använd data (denna månad):',
    form: {
      disable: {
        button: 'Avsluta abonnemang',
        info: 'Generera en begäran om att avsluta abonnemang.',
        messageTitle: 'Avsluta abonnemang',
        message: 'Är du säker på att du vill begära en avslutning av detta abonnemang?',
      },
      sections: {
        subsInfo: 'Abonnemangsuppgifter',
        customerInfo: 'Kunduppgifter',
        portingInfo: 'Porteringsuppgifter',
        subscription: 'Abonnemang',
        porting: 'Portering',
      },
      customer: {
        label: 'Kund',
      },
      customerReference: {
        label: 'Kundreferens',
      },
      department: {
        label: 'Avdelning',
      },
      username: {
        label: 'Användarnamn',
      },
      type: {
        label: 'Typ',
      },
      dataType: {
        label: 'Mobildata',
      },
      mobileNumber: {
        label: 'Mobilnummer',
      },
      fixedNumber: {
        label: 'Fastnätsnummer',
        missing: 'Om du sätter en porteringstid krävs även porteringsdatum.',
      },
      iccid: {
        label: 'ICCID',
      },
      imsi: {
        label: 'IMSI',
      },
      status: {
        label: 'Abonnemangsstatus',
      },
      startTime: {
        label: 'Start',
      },
      endTime: {
        label: 'Slut',
      },
      portDate: {
        label: 'Porteringsdatum',
      },
      portTime: {
        label: 'Porteringstid',
      },
      // meta: {
      //   label: 'Meta',
      // },
    },
  },
  function: {
    title: 'Funktioner',
    text: 'Konfiguration av mina funktioner',
    form: {
      sections: {
        callForward: 'Vidarekoppling',
      },
      callFwdUnconditional: {
        label: 'Alltid',
        invalid: 'Telefonnummer krävs.',
      },
      callFwdBusy: {
        label: 'Vid upptagen',
        invalid: 'Telefonnummer krävs.',
      },
      callFwdNoAnswer: {
        label: 'Vid ej svar',
        invalid: 'Telefonnummer krävs.',
      },
      callFwdNoReply: {
        label: 'Vid ej nåbar',
        invalid: 'Telefonnummer krävs.',
      },
      callFwdDelay: {
        label: 'Tid i sekunder',
        invalid: 'Tillåtet tidsintervall är 0 till 60 sekunder.',
      },
    },
  },
};

export default { ...generics, dialogs, ...application, ...pages };
