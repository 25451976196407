import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SpinnerButton } from '../../components/SpinnerButton';
import { api, logger } from '../../lib';
import { decodeJwt } from '../../lib/misc';
import { useFetch } from './fetch';
import { useFormMessage, defaultFormMessage } from '../../components';

const translation = 'login';

export const ConfirmEmail: React.SFC<RouteComponentProps<{ token: string }>> = ({
  location,
  match: { params },
}) => {
  const { token } = params;
  const { t } = useTranslation();
  const [validating, setValidating] = useState(false);
  const [validated, error] = useFetch(token);
  const [formMessage, setMessage] = useFormMessage();

  const handleSubmit = async () => {
    try {
      setValidating(true);
      setMessage(defaultFormMessage);
      const jwtData = decodeJwt(token);
      if (jwtData) {
        const url = `${process.env.REACT_APP_APP_PATH}/confirm-email`;
        const login = `${process.env.REACT_APP_APP_PATH}/login`;
        await api.verifyEmailAddress(jwtData.id, jwtData.email, url, login);
        setMessage({
          status: 'success',
          message: t(`${translation}.emailPasswordRecovery`, { email: jwtData.email }),
        });
      }
    } catch (err) {
      setMessage({ status: 'danger', message: err.message });
      logger('ConfirmEmail.js', err);
    } finally {
      setValidating(false);
    }
  };

  if (error !== '') {
    const { message, status } = formMessage;
    return (
      <Container>
        <Form className="text-center">
          <h4>Verifiering misslyckades</h4>
          <p>E-post kunde inte verifieras p.g.a. att tillfälligt token inte längre gäller.</p>
          {message && <Form.Text className={`text-${status}`}>{message}</Form.Text>}
          <div>
            <SpinnerButton
              label="Skicka en ny verifieringslänk"
              onClick={handleSubmit}
              isSubmitting={validating}
            />
          </div>
        </Form>
      </Container>
    );
  }

  return !validated ? (
    <Container>
      <h3>{t(`${translation}.changePassword`)} VALIDATING</h3>
    </Container>
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};
