const debug = require('debug')('mvno:logger');
import { api } from './api';

export const logger = async (label: string, error: object) => {
  // if (process.env.NODE_ENV !== 'production') {
  debug(label, error);
  // } else {
  await api.createEntry('misc/log', { label, error });
  // }
};
