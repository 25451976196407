import React from 'react';
// import { useTranslation } from 'react-i18next';
import logo from '../images/logo.png';

export const Footer: React.SFC = () => {
  // const { t } = useTranslation();

  return (
    <footer>
      <div className="d-sm-flex justify-content-around text-center text-light pt-2 pb-2 bg-dark">
        <div className="mt-4 mb-4">
          <div className="font-weight-bold">Stockholm (HK)</div>
          <div>Teleoffice AB</div>
          <div>Vallgatan 5B</div>
          <div>170 67 Solna</div>
          <div>
            Tel: <a href="tel:+46313900100">031-390 01 00</a>
          </div>
          <div>
            E-post: <a href="mailto:info@teleoffice.nu">info@teleoffice.nu</a>
          </div>
        </div>
        <div className="mt-4 mb-4">
          <div className="font-weight-bold">Göteborg</div>
          <div>Teleoffice AB</div>
          <div>Nobelgatan 21</div>
          <div>416 57 Göteborg</div>
          <div>
            Tel: <a href="tel:+46313900100">031-390 01 00</a>
          </div>
        </div>
        <div className="mt-4 mb-4">
          <div className="font-weight-bold">Malmö</div>
          <div>Teleoffice AB</div>
          <div>Pastoratvägen 35</div>
          <div>239 41 Falsterbo</div>
          <div>
            Tel: <a href="tel:+46313900100">031-390 01 00</a>
          </div>
        </div>
      </div>

      <div className="text-center pt-4 pb-4 bg-light">
        <span className="text-muted">
          <img src={logo} width="48" alt="logo" />
          <div>TeleOffice AB © {new Date().getFullYear()}.</div>
        </span>
      </div>
    </footer>
  );
};
