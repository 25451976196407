import React from 'react';
import { Spinner } from 'react-bootstrap';

interface SpinnerPageLoadingProps {
  message: string;
}

export const SpinnerPageLoading: React.SFC<SpinnerPageLoadingProps> = ({ message }) => {
  return (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <Spinner animation="border" />
      <div className="ml-2">{message}</div>
    </div>
  );
};
