import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface SpinnerButtonProps {
  label: string;
  type?: 'button' | 'reset' | 'submit';
  isSubmitting?: boolean;
  onClick?: any;
  disabled?: boolean;
  className?: string;
  title?: string;
  variant?: any;
}

export const SpinnerButton: React.SFC<SpinnerButtonProps> = ({
  label,
  type = 'submit',
  isSubmitting,
  ...rest
}) => (
  <Button className="text-nowrap" type={type} disabled={isSubmitting} {...rest}>
    {isSubmitting && (
      <Spinner
        className="mr-1"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    )}

    {label}
  </Button>
);
