import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, Prompt } from 'react-router-dom';
import { MobileForm, FormValues } from './MobileForm';
import { api, logger, getOptionValues, useApi } from '../../lib';
import { FormikHelpers } from 'formik';
import { useStateValue } from '../../state';
import { showToast } from '../../components';

export const Mobile: React.SFC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
  history,
}) => {
  const { id } = params;
  const translation = 'mobile';
  const { t } = useTranslation();
  const [, dispatch] = useStateValue();
  const [subscription] = useApi('subscriptions/mobiles', id);

  const handleSubmit = async (update: FormValues, props: FormikHelpers<FormValues>) => {
    const { setSubmitting, resetForm } = props;

    try {
      const { changedFields, ...values } = update;

      const updateValues = {
        ...values,
        customer: getOptionValues(values.customer),
        imsi: getOptionValues(values.iccid),
        status: getOptionValues(values.status),
        portTime: values.portTime && values.portDate ? `${values.portDate} ${values.portTime}` : '',
      };
      await api.replaceEntry('mobiles', String(updateValues.id), updateValues);

      if (subscription && changedFields && changedFields.includes('fixedNumber')) {
        await api.createEntry('misc/addjob', {
          parent: 0,
          type: 5, // Sync Kamailio
          data: { sub_id: subscription.id, type: 'check' },
        });
      }

      // Requiered to use initialValues to detect changes.
      resetForm({ values });
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Mobile (save)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      await api.deleteEntry('mobiles', id);
      history.push(`/admin/mobiles`);
    } catch (error) {
      const { message } = error.response.data.error;
      const reason = ['cannotDeleteStatusNotOne', 'cannotDeleteUsedBySubscription'].includes(
        message
      )
        ? message
        : 'unknown';
      showToast(
        { title: 'Delete', body: t(`${translation}.errors.${reason}`), type: 'warning' },
        dispatch
      );
      logger('Mobile (delete)', error);
    }
  };

  return (
    <>
      <h1>{t(`${translation}.title`)}</h1>
      <p>{t(`${translation}.text`)}</p>
      <MobileForm
        translation={`${translation}.form`}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        id={id}
      />
    </>
  );
};
