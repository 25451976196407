import React from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  SpinnerButton,
  BasicFormProps,
  FormProps,
  getChangedFields,
  SpinnerPageLoading,
  CollapseHeader,
} from '../../components';
import { useStateValue } from '../../state';
import { Field } from '../../components/fields';
import { useApi } from '../../lib';

export interface FormValues {
  callFwdUnconditional: string;
  callFwdUnconditional_checkbox: boolean;
  callFwdBusy: string;
  callFwdBusy_checkbox: boolean;
  callFwdNoReply: string;
  callFwdNoReply_checkbox: boolean;
  callFwdNoAnswer: string;
  callFwdNoAnswer_checkbox: boolean;
  callFwdDelay: string;
  changedFields?: string[];
}

interface FunctionFormProps extends BasicFormProps {
  id: string;
}

export const FunctionForm: React.SFC<FunctionFormProps> = ({ translation, onSubmit, id }) => {
  const { t } = useTranslation();
  const [{ settings, administrator }] = useStateValue();

  const [dummyFunctions, loadingFunctions] = useApi('customers'); // TODO: Dummy, replace with functions api call.

  if (!administrator || !settings || loadingFunctions) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const formValues: FormValues = {
    callFwdUnconditional: '+467123456',
    callFwdUnconditional_checkbox: false,
    callFwdBusy: '+468123456',
    callFwdBusy_checkbox: true,
    callFwdNoReply: '',
    callFwdNoReply_checkbox: false,
    callFwdNoAnswer: '+467123456',
    callFwdNoAnswer_checkbox: true,
    callFwdDelay: '20',
  };

  const validationSchema = Yup.object().shape({
    callFwdUnconditional: Yup.string().when('callFwdUnconditional_checkbox', {
      is: (callFwdUnconditional_checkbox: boolean) => callFwdUnconditional_checkbox,
      then: Yup.string().required(t(`${translation}.callFwdUnconditional.invalid`)),
    }),
    callFwdBusy: Yup.string().when('callFwdBusy_checkbox', {
      is: (callFwdBusy_checkbox: boolean) => callFwdBusy_checkbox,
      then: Yup.string().required(t(`${translation}.callFwdBusy.invalid`)),
    }),
    callFwdNoReply: Yup.string().when('callFwdNoReply_checkbox', {
      is: (callFwdNoReply_checkbox: boolean) => callFwdNoReply_checkbox,
      then: Yup.string().required(t(`${translation}.callFwdNoReply.invalid`)),
    }),
    callFwdNoAnswer: Yup.string().when('callFwdNoAnswer_checkbox', {
      is: (callFwdNoAnswer_checkbox: boolean) => callFwdNoAnswer_checkbox,
      then: Yup.string().required(t(`${translation}.callFwdNoAnswer.invalid`)),
    }),
    callFwdDelay: Yup.number()
      .min(0, t(`${translation}.callFwdDelay.invalid`))
      .max(20, t(`${translation}.callFwdDelay.invalid`))
      .required(),
  });

  const FunctionFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const {
      handleSubmit,
      // dirty,
      isSubmitting,
      values,
      initialValues,
      // submitCount,
      // status,
    } = props;

    // Pass along changedFields to handleSubmit.
    values.changedFields = getChangedFields(initialValues, values);
    const dirty = values.changedFields.length !== 0;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <CollapseHeader translation={translation} id="callForward">
          <div>
            {' '}
            <p className="text-danger">DEMO: Ej implementerat i backend.</p>
            <Form.Row>
              <Field
                {...props}
                id="callFwdUnconditional"
                type="text"
                prependCheckbox
                disabled={!values['callFwdUnconditional_checkbox']}
                md={4}
              />
              <Field
                {...props}
                id="callFwdBusy"
                type="text"
                prependCheckbox
                disabled={!values['callFwdBusy_checkbox']}
                md={4}
              />
              <Field
                {...props}
                id="callFwdNoReply"
                type="text"
                prependCheckbox
                disabled={!values['callFwdNoReply_checkbox']}
                md={4}
              />
              <Field
                {...props}
                id="callFwdNoAnswer"
                type="text"
                prependCheckbox
                disabled={!values['callFwdNoAnswer_checkbox']}
                md={4}
              />
              <Field
                {...props}
                id="callFwdDelay"
                type="number"
                disabled={!values['callFwdNoAnswer_checkbox']}
                md={2}
              />
            </Form.Row>
          </div>
        </CollapseHeader>

        <div className="d-flex justify-content-end">
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </div>
      </Form>
    );
  };

  const FunctionFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => formValues,
    validationSchema,
    handleSubmit: onSubmit,
  })(FunctionFormInner);

  return <FunctionFormFormik translation={translation} />;
};
