import React from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  SpinnerButton,
  ConfirmButton,
  BasicFormProps,
  FormProps,
  SpinnerPageLoading,
  CollapseHeader,
  getChangedFields,
} from '../../components';
import { useStateValue } from '../../state';
import { Field } from '../../components/fields';
import {
  useApi,
  toOptions,
  toOption,
  toLocaleTimeString,
  toLocaleDateString,
  getValueFromArray,
  validateFixedPhoneNumber,
  getOption,
} from '../../lib';
import { FormValues as CreateSubscriptionFormValues } from '../Subscriptions/CreateSubscription';

export interface FormValues extends CreateSubscriptionFormValues {
  id: number;
  imsi?: any;
  changedFields?: string[];
  fixedNumber?: any;
  portTime?: string;
  portDate?: string;
}

interface SubscriptionFormProps extends BasicFormProps {
  subscription: any;
  onDisable: (id: number) => void;
}

export const SubscriptionForm: React.SFC<SubscriptionFormProps> = ({
  translation,
  subscription,
  onSubmit,
  onDisable,
}) => {
  const { t } = useTranslation();
  const [{ settings }] = useStateValue();

  const [customer, loadingCustomer] = useApi('customers', subscription.customer);
  const [mobiles, loadingMobiles] = useApi('mobiles/customers', subscription.customer);
  const mobile = mobiles && mobiles.find((m: any) => subscription.mobileNumber === m.id);
  const [imsis, loadingImsis] = useApi('imsis/customers', subscription.customer);

  if (loadingCustomer || loadingMobiles || !settings || loadingImsis) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const imsi = getValueFromArray(imsis, mobile.imsi);
  const disabled = subscription && subscription.status === 7;
  const disableDisableButton = subscription && subscription.status > 5;

  const formValues = {
    ...subscription,
    startTime: toLocaleDateString(subscription.startTime),
    endTime: toLocaleDateString(subscription.endTime),
    customer: toOption(customer),
    department: toOption(
      customer.meta.departments.find((d: any) => subscription.department === d.id)
    ),
    mobileNumber: toOption(mobile, 'mobileNumber'),
    fixedNumber: mobile.fixedNumber,
    imsi: toOption(imsi, 'imsi'),
    portDate: toLocaleDateString(mobile.portTime),
    portTime: toLocaleTimeString(mobile.portTime),
    status: getOption(settings.subscriptionStatus, subscription.status),
    type: getOption(settings.subscriptionCallTypes, subscription.type),
    dataType: getOption(settings.subscriptionDataTypes, subscription.dataType),
  };

  const validationSchema = Yup.object().shape({
    fixedNumber: Yup.string().test(
      'check-format',
      t(`${translation}.fixedNumber.invalid`),
      (value) => value === undefined || validateFixedPhoneNumber(value)
    ),
    portDate: Yup.string().when('portTime', {
      is: (portTime: string) => !!portTime,
      then: Yup.string().required(t(`${translation}.fixedNumber.missing`)),
      otherwise: Yup.string(),
    }),
  });

  const SubscriptionFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const { handleSubmit, dirty, isSubmitting, initialValues, values } = props;

    const mobileNumberChanged =
      (initialValues.mobileNumber && initialValues.mobileNumber.label) !==
      (values.mobileNumber && values.mobileNumber.label);

    // Pass along changedFields to handleSubmit.
    values.changedFields = getChangedFields(initialValues, values);
    // const dirty = values.changedFields.length !== 0;

    const updateDependentFields = (option: any, name: any, setFieldValue: any) => {
      if (name === 'mobileNumber' && option) {
        const mobile = getValueFromArray(mobiles, option.value);
        if (mobile) {
          const imsi = getValueFromArray(imsis, mobile.imsi);
          setFieldValue('fixedNumber', mobile.fixedNumber);
          setFieldValue('imsi', toOption(imsi, 'imsi'));
          setFieldValue('portTime', toLocaleTimeString(mobile.portTime));
          setFieldValue('portDate', toLocaleDateString(mobile.portTime));
        }
      }
    };

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <CollapseHeader translation={translation} id="subsInfo">
          <div>
            <h5 className="mt-4">{t(`${translation}.sections.customerInfo`)}</h5>
            <Form.Row>
              <Field
                {...props}
                id="customer"
                type="reactSelect2"
                options={toOptions([customer])}
                isClearable
                isDisabled
                md={3}
              />
              <Field {...props} id="customerReference" disabled={disabled} type="text" md={3} />
              <Field
                {...props}
                id="department"
                type="reactSelect2"
                options={toOptions(customer.meta.departments)}
                isDisabled={disabled}
                md={3}
              />
              <Field {...props} id="username" disabled={disabled} type="text" md={3} />
            </Form.Row>

            <h5 className="mt-4">{t(`${translation}.sections.subscription`)}</h5>
            <Form.Row>
              <Field
                {...props}
                id="type"
                type="reactSelect2"
                options={settings.subscriptionCallTypes}
                md={3}
              />
              <Field
                {...props}
                id="dataType"
                type="reactSelect2"
                options={settings.subscriptionDataTypes}
                md={3}
              />
              <Field
                {...props}
                id="mobileNumber"
                type="reactSelect2"
                options={toOptions(mobiles, 'mobileNumber')}
                onFieldChange={updateDependentFields}
                isDisabled={disabled}
                md={3}
              />
              <Field
                {...props}
                id="fixedNumber"
                disabled={disabled || mobileNumberChanged}
                type="text"
                md={3}
              />
              <Field
                {...props}
                id="imsi"
                type="reactSelect2"
                isDisabled={disabled || mobileNumberChanged}
                options={toOptions(imsis, 'imsi')}
                md={3}
              />
              <Field
                {...props}
                id="status"
                type="reactSelect2"
                isDisabled={disabled}
                options={settings.subscriptionStatus}
                md={3}
              />
              <Field {...props} id="startTime" disabled={disabled} type="date" md={3} />
              <Field {...props} id="endTime" disabled={disabled} type="date" md={3} />
            </Form.Row>

            <h5 className="mt-4">{t(`${translation}.sections.porting`)}</h5>
            <Form.Row>
              <Field
                {...props}
                id="portDate"
                disabled={disabled || mobileNumberChanged}
                type="date"
                md={3}
              />
              <Field
                {...props}
                id="portTime"
                disabled={disabled || mobileNumberChanged}
                type="time"
                md={3}
              />
            </Form.Row>

            <div className="d-flex justify-content-end">
              <ConfirmButton
                onConfirm={() => onDisable(subscription)}
                label={t(`${translation}.disable.button`)}
                title={t(`${translation}.disable.info`)}
                messageTitle={t(`${translation}.disable.messageTitle`)}
                message={t(`${translation}.disable.message`)}
                isSubmitting={isSubmitting}
                disabled={disableDisableButton}
              />
              <SpinnerButton
                disabled={!dirty}
                label={t('buttons.save')}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
        </CollapseHeader>
      </Form>
    );
  };

  const SubscriptionFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => formValues,
    validationSchema,
    handleSubmit: onSubmit,
  })(SubscriptionFormInner);

  return <SubscriptionFormFormik translation={translation} />;
};
