export const validateMobileNumber = (number: string): boolean => {
  return RegExp('^467[0-9]{8}$').test(number);
};

export const validateFixedPhoneNumber = (number: string): boolean => {
  return RegExp('^46[^07][0-9]{6,11}$').test(number);
};

// https://github.com/jop-io/formator.js/blob/master/formator.js

/**
 * Hjälpfunktion för att kontrollera att en sifferserie valideras
 * korrekt med Luhn-algoritmen.
 *
 * @param {String} number Sifferserie som ska kontrolleras
 * @returns {Boolean} "true" eller "false"
 */
const luhnMod10 = (number: string): boolean => {
  let arr = number
    .split('')
    .reverse()
    .map((x) => parseInt(x));
  let lastDigit = arr.splice(0, 1)[0];
  let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0);
  sum += lastDigit;
  return sum % 10 === 0;
};

/**
 * Kontrollerar om ett svenskt personnummer är giltigt i enlighet med
 * Folkbokföringslagen 1991:481 (18§) och SKV 704.
 *
 * För att retunera personnumret i ett visst format, kan en andra valfri
 * parameter anges. De format som stöds är:
 *
 *     1. DEFAULT = 12 siffror (standard), ÅÅÅÅMMDDNNNN
 *     2. SHORT   = 10 siffror med skiljetecken, ÅÅMMDD-NNNN
 *
 * @param {String} number Person- eller samordningnummer
 * @param {String} format Önskat format (1-2)
 * @returns {String|Boolean} Personnummer eller "false"
 */
export const validatePersonalNumber = (
  number: string,
  format: string = 'SHORT'
): string | boolean => {
  if (typeof number !== 'string') {
    return false;
  }

  let n = number.replace(/\D/g, '');

  if (!(n.length === 12 || n.length === 10) || luhnMod10(n.substr(n.length - 10, 10)) === false) {
    return false;
  }

  const months = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const month = parseInt(n.substr(n.length - 8, 2), 10);
  const day = parseInt(n.substr(n.length - 6, 2), 10);

  if (month < 1 || month > 12 || day < 1 || day > months[month - 1]) {
    return false;
  }

  const now = new Date();
  const y = now.getFullYear();
  const sep = number.substr(-5, 1) === '+' ? '+' : '-';

  if (n.length === 10) {
    const input_year = parseInt(n.substr(0, 2), 10);
    let century = parseInt(y.toString().substr(0, 2), 10);
    const year_2di = parseInt(y.toString().substr(2, 2), 10);
    century -= input_year > year_2di ? 1 : 0;
    century -= sep === '+' ? 1 : 0;
    n = century.toString().concat(n);
  }

  return format === 'SHORT' ? n.substr(2, 6) + sep + n.substr(-4, 4) : n;
};

/**
 * Kontrollerar om ett svenskt organisationsnummer är giltigt i enlighet
 * med Lagen om identitetsbeteckning för juridiska personer m.fl.
 * (SFS 1974:174) och SKV 709.
 *
 *     1. DEFAULT = 10 siffror utan skiljetecken (standard), NNNNNNNNNN
 *     2. DASH    = 10 siffror med skiljetecken, NNNNNN-NNNN
 *
 * @param {String} number Organisationsnummer
 * @param {String} format Önskat format (1-2)
 * @returns {String|Boolean} Organisationsnummer eller "false"
 */
export const validateOrganizationNumber = (
  number: string,
  format: string = 'DASH'
): string | boolean => {
  if (typeof number !== 'string') {
    return false;
  }

  const n = number.replace(/\D/g, '');
  if (n.length !== 10 || parseInt(n.substr(-8, 1), 10) < 2 || !luhnMod10(n.substr(-10, 10))) {
    return false;
  }

  return format === 'DASH' ? n.substr(-10, 6) + '-' + n.substr(-4, 4) : n.substr(-10, 10);
};
