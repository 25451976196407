import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useStateValue, Department } from '../../state';
import { ActivateSubscriptionDialog } from './ActivateSubscriptionDialog';
import {
  toLocaleDateTimeString,
  useApi,
  toOptions,
  withFilters,
  getPropertyFromArray,
  getValueFromArray,
  getOption,
} from '../../lib';
import {
  StatusIcon,
  SpinnerPageLoading,
  Pager,
  Table,
  defaultSort,
  FilterForm,
  Filter,
} from '../../components';

export const Subscriptions: React.SFC<RouteComponentProps> = ({ history }) => {
  const translation = 'subscriptions';
  const { t } = useTranslation();
  const [{ settings, administrator }, dispatch] = useStateValue();
  const [filter, setFilter] = useState<Filter[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [action, setAction] = useState(2);
  const [selected, setSelected] = useState<(number | string)[]>([]);
  const customerId = administrator.customers[0] || -1; // Sätt id till -1 för användare som ej är kopplad till kund.

  const [populated, loadingPopulated] = administrator.isRole.sysAdmin
    ? useApi('subscriptions/populated')
    : useApi(`subscriptions/populated/${customerId}`);

  if (loadingPopulated || !settings || !administrator) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const { subscriptions: rawSubscriptions, departments, mobiles, customers, imsis } = populated;

  const subscription = selected[0] && getValueFromArray(rawSubscriptions, Number(selected[0]));

  const subscriptionCustomerId = subscription
    ? getPropertyFromArray(departments, subscription.department, 'customer')
    : -1;

  const activateSubscription = subscription && {
    id: subscription.id,
    status: subscription.status,
    mobileNumber: getPropertyFromArray(mobiles, subscription.mobileNumber, 'mobileNumber'),
    mobileNumberId: subscription.mobileNumber,
    multipleSubscriptionsSelected: selected.length > 1,
    mobileNumberPortTime: getPropertyFromArray(mobiles, subscription.mobileNumber, 'portTime'),
    mobileNumberImsi: getPropertyFromArray(mobiles, subscription.mobileNumber, 'imsi'),
  };

  // Prepare subscriptions
  const subscriptions = rawSubscriptions.map((subscription: any) => ({
    ...subscription,
    customer: getPropertyFromArray(departments, subscription.department, 'customer'),
    mobileStatus: getPropertyFromArray(mobiles, subscription.mobileNumber, 'status'),
    iccid: getPropertyFromArray(mobiles, subscription.mobileNumber, 'imsi'),
  }));

  // Filter options
  const departmentOptions = departments.map((department: Department) => ({
    value: department.id,
    label: department.name,
  }));
  const customersSet = new Set(); // Use set to remove duplicates
  departments.forEach((department: Department) => customersSet.add(department.customer));
  const customerOptions = [...Array.from(customersSet)].map((val) => ({
    value: val,
    label: getPropertyFromArray(customers, val as number, 'name'),
  }));
  const mobilesSet = new Set();
  rawSubscriptions.forEach((subscription: any) => mobilesSet.add(subscription.mobileNumber));
  const mobileOptions = [...Array.from(mobilesSet)].map((val) => ({
    value: val,
    label: getPropertyFromArray(mobiles, val as number, 'mobileNumber'),
  }));
  const iccidOptions = toOptions(imsis, 'iccid');

  const getCustomer = (department: number) =>
    getPropertyFromArray(
      customers,
      getPropertyFromArray(departments, department, 'customer'),
      'name'
    );

  const getRow = ({
    department,
    username,
    startTime,
    endTime,
    mobileNumber,
    status,
    iccid,
  }: any) => (
    <>
      <Col>
        <div style={{ fontWeight: 600 }}>{getCustomer(department)}</div>
        <div>{getPropertyFromArray(departments, department, 'name')}</div>
      </Col>
      <Col>{username}</Col>
      <Col>
        <div>{toLocaleDateTimeString(startTime)}</div>
        <div>{toLocaleDateTimeString(endTime)}</div>
      </Col>
      <Col>
        <div>{getPropertyFromArray(mobiles, mobileNumber, 'mobileNumber')}</div>
        <div>
          <StatusIcon
            status={getOption(
              settings.mobileStatus,
              getPropertyFromArray(mobiles, mobileNumber, 'status')
            )}
            iconFirst
          />
        </div>
      </Col>
      <Col>{getPropertyFromArray(imsis, iccid, 'iccid')}</Col>
      <Col className="text-right">
        <StatusIcon status={getOption(settings.subscriptionStatus, status)} />
      </Col>
    </>
  );

  const handleRowClick = (row: { id: number }) => {
    history.push({
      pathname: `/admin/subscriptions/${row.id}`,
      state: { detail: 'response.data ' },
    });
  };

  const handleCompare = ({ field, ascending }: any) => (a: any, b: any) => {
    let fieldA = a[field];
    let fieldB = b[field];

    if (field === 'customer') {
      fieldA = getCustomer(a.department);
      fieldB = getCustomer(b.department);
    } else if (field === 'department') {
      fieldA = getPropertyFromArray(departments, a.department, 'name');
      fieldB = getPropertyFromArray(departments, b.department, 'name');
    } else if (field === 'mobileNumber') {
      fieldA = getPropertyFromArray(mobiles, a.mobileNumber, 'mobileNumber');
      fieldB = getPropertyFromArray(mobiles, b.mobileNumber, 'mobileNumber');
    } else if (field === 'mobileStatus') {
      fieldA = getPropertyFromArray(mobiles, a.mobileNumber, 'status');
      fieldB = getPropertyFromArray(mobiles, b.mobileNumber, 'status');
    } else if (field === 'iccid') {
      fieldA = getPropertyFromArray(imsis, a.iccid, 'imsi');
      fieldB = getPropertyFromArray(imsis, b.iccid, 'imsi');
    }

    return defaultSort(fieldA, fieldB, ascending);
  };

  const filteredItems = withFilters(subscriptions, filter);

  return (
    <>
      <Row className="mb-4">
        <Col md={6}>
          <h1>{t(`${translation}.title`)}</h1>
        </Col>
        {(administrator.isRole.sysAdmin || administrator.isRole.custAdmin) && (
          <Col md={6}>
            <div className="d-inline-flex justify-content-end w-100 mt-3">
              <div className="mr-2">
                <ActivateSubscriptionDialog
                  history={history}
                  subscription={activateSubscription}
                  buttonLabel={t(`${translation}.activateButton`)}
                  roles={administrator.isRole}
                  customerId={subscriptionCustomerId}
                />
              </div>
              <LinkContainer to="/admin/subscriptions/create">
                <Button className="text-nowrap">{t(`${translation}.createButton`)}</Button>
              </LinkContainer>
            </div>
          </Col>
        )}
      </Row>

      <FilterForm
        translation={translation}
        fields={[
          { name: 'customer', options: customerOptions },
          { name: 'department', options: departmentOptions },
          { name: 'mobileNumber', options: mobileOptions },
          { name: 'mobileStatus', options: settings.mobileStatus },
          { name: 'iccid', options: iccidOptions },
          { name: 'status', options: settings.subscriptionStatus },
        ]}
        filter={filter}
        setFilter={setFilter}
      />

      <Container fluid className="number-list mb-4 mt-4">
        <Table
          translation={translation}
          items={filteredItems}
          history={history}
          getRow={getRow}
          columns={[
            ['customer', 'department'],
            // 'customerReference',
            'username',
            ['startTime', 'endTime'],
            ['mobileNumber', 'mobileStatus'],
            // 'meta',
            'iccid',
            'status',
          ]}
          onRowClick={handleRowClick}
          onCompare={handleCompare}
          defaultCompare={{ field: 'firstName', ascending: true }}
          selected={selected}
          setSelected={setSelected}
          activePage={activePage}
          pageSize={pageSize}
        />
      </Container>

      <Pager
        totalItemsCount={filteredItems.length}
        pageRangeDisplayed={6}
        activePage={activePage}
        setActivePage={setActivePage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};
