import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { api, logger, getErrorMessage } from '../../lib';
import { useStateValue } from '../../state';
import { RegisterForm, FormValues } from './RegisterForm';

const translation = 'register';

export const Register: React.SFC<RouteComponentProps> = ({ location }) => {
  const [{ authenticated }] = useStateValue();
  const [registeredEmail, setRegisteredEmail] = useState('');
  const { t } = useTranslation();

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setStatus, setFieldError }: FormikHelpers<FormValues>
  ) => {
    try {
      const { firstName, lastName, email, password } = values;
      const url = `${process.env.REACT_APP_APP_PATH}/confirm-email`;
      const login = `${process.env.REACT_APP_APP_PATH}/login`;
      await api.register(email, password, firstName, lastName, 'sv', url, login);
      setRegisteredEmail(email);
    } catch (error) {
      logger('Register.js', error);
      const { code, message } = getErrorMessage(error);
      if (code === 'ER_DUP_ENTRY') {
        setFieldError('email', t(`${translation}.errors.emailExist`, { email: values.email }));
      } else {
        setStatus({ status: 'danger', message });
      }
    }
  };

  if (authenticated) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  return (
    <Container>
      {!registeredEmail ? (
        <>
          <h3>{t(`${translation}.title`)}</h3>
          <RegisterForm translation={`${translation}.form`} onSubmit={handleSubmit} />
        </>
      ) : (
        <>
          <h3>{t(`${translation}.successMessage`)}</h3>
          <p>{t(`${translation}.emailValidation`, { email: registeredEmail })}</p>
        </>
      )}{' '}
    </Container>
  );
};
