import React, { useState } from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  SpinnerButton,
  CollapseHeader,
  BasicFormProps,
  FormProps,
  DeleteButton,
} from '../../components';
import { useStateValue, Department } from '../../state';
import { Field, Option } from '../../components/fields';
import { toOptions, keysToValues, keysToOptions, useApi } from '../../lib';
import { DialogValues } from '../Administrators/CreateAdministratorDialog';

export interface FormValues extends DialogValues {
  id: number;
  customers: (Option | number)[];
  roles: (Option | number)[];
  // departments: Option | undefined;
  admDepartments: (Option | number)[];
  meta?: any;
}

interface AdministratorFormProps extends BasicFormProps {
  values: FormValues;
  departments: Department[];
  onDelete: (id: number) => void;
}

export const AdministratorForm: React.SFC<AdministratorFormProps> = ({
  values,
  translation,
  departments,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [
    {
      administrator: { isRole },
      settings,
    },
  ] = useStateValue();

  if (!settings) {
    return null;
  }

  const departmentsWithCustomerName = departments.map((d: any) => {
    const customer = settings.customers.find((c: any) => c.id === d.customer);
    return {
      label: customer ? `${customer.name} - ${d.name}` : d.name,
      value: d.id,
    };
  });

  const admDepartmentsWithCustomerName: any = values.admDepartments
    .map((departmentId: any) => {
      const department = departments.find((d: any) => d.id === departmentId);
      const customer = settings.customers.find(
        (c: any) => c.id === (department && department.customer)
      );

      if (!customer || !department) {
        return;
      }

      return {
        label: `${customer.name} - ${department.name}`,
        value: departmentId,
      };
    })
    .filter((d: any) => d !== undefined);

  const options = {
    customers: toOptions(settings.customers),
    departments: departmentsWithCustomerName,
    languages: settings.languages.map(
      ({ value }) =>
        ({
          value,
          label: t(`languages.${value}`),
        } as Option)
    ),
  };

  const formValues = {
    ...values,
    password: '********',
    language: options.languages.find((l: any) => values.language === l.value),
    roles: keysToValues(settings.roles, values.roles, 'value'),
    admDepartments: admDepartmentsWithCustomerName,
    customers: keysToOptions(values.meta.customers, values.customers),
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t(`${translation}.firstName.invalid`)),
    lastName: Yup.string().required(t(`${translation}.lastName.invalid`)),
    email: Yup.string()
      .email(t(`${translation}.email.invalid`))
      .required(t(`${translation}.email.invalid`)),
    password: Yup.string()
      .min(8, t(`${translation}.password.passwordLength`))
      .required(t(`${translation}.password.invalid`)),
  });

  const AdministratorFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const { handleSubmit, dirty, isSubmitting, initialValues } = props;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        {/* Contact information section */}
        <CollapseHeader translation={translation} id="contactInfo">
          <div>
            <Form.Row>
              <Field {...props} id="firstName" type="text" md={3} />
              <Field {...props} id="lastName" type="text" md={3} />
              <Field {...props} id="email" type="email" md={6} />
              <Field {...props} id="phone" type="text" md={3} />
              <Field
                {...props}
                id="language"
                type="reactSelect2"
                options={options.languages}
                md={3}
              />
              <Field {...props} id="password" type="password" md={6} />
            </Form.Row>
            <Form.Row>
              <Field {...props} id="blocked" type="checkbox" custom md={3} />
              <Field {...props} id="confirmed" type="checkbox" md={3} />
            </Form.Row>
          </div>
        </CollapseHeader>

        {/* Permissions section */}
        <CollapseHeader translation={translation} id="permissions">
          <Form.Row>
            <Field
              {...props}
              type="reactSelect2"
              id="customers"
              options={options.customers}
              isDisabled //={!isRole.sysAdmin}
              md={6}
              isClearable
              isMulti
            />
            <Field
              {...props}
              type="reactSelect2"
              id="admDepartments"
              options={options.departments}
              isDisabled={!isRole.sysAdmin}
              md={6}
              isMulti
            />
            <Field
              {...props}
              type="reactSelect2"
              id="roles"
              options={settings.roles}
              isDisabled={!isRole.sysAdmin}
              md={6}
              isMulti
            />
          </Form.Row>
        </CollapseHeader>

        <div className="d-flex justify-content-end">
          <DeleteButton
            onDelete={onDelete}
            disabled
            title="Not implemented in database!"
            isSubmitting={isSubmitting}
          />
          <SpinnerButton
            /* disabled={!dirty} */ label={t('buttons.save')}
            isSubmitting={isSubmitting}
          />
        </div>
      </Form>
    );
  };

  const AdministratorFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => formValues,
    validationSchema,
    handleSubmit: onSubmit,
  })(AdministratorFormInner);

  return <AdministratorFormFormik translation={translation} />;
};
