import React from 'react';
import { ProgressBar } from 'react-bootstrap';

interface ProgressBar2Props {
  remaining: number;
  height?: number;
}

export const ProgressBar2: React.SFC<ProgressBar2Props> = ({ remaining, height }) => {
  return (
    <div style={{ position: 'relative' }}>
      <ProgressBar
        striped
        style={{ height: `${height || 25}px` }}
        now={remaining}
        label={remaining > 10 ? `${remaining}%` : null}
      />
      {remaining < 11 && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
          }}
        >{`${remaining}%`}</div>
      )}
    </div>
  );
};
