import React from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SpinnerButton, BasicFormProps, FormProps, FormMessage } from '../../components';
import { Field } from '../../components/fields';

export interface FormValues {
  password: string;
  confirmPassword: string;
}

const defaultFormValues = {
  password: '',
  confirmPassword: '',
};

export const UpdatePasswordForm: React.SFC<BasicFormProps> = ({
  translation,
  onSubmit,
  formMessage,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t(`${translation}.password.passwordLength`))
      .required(t(`${translation}.password.invalid`)),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t(`${translation}.confirmPassword.invalid`))
      .required(t(`${translation}.confirmPassword.invalid`)),
  });

  const UpdatePasswordFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const { handleSubmit, dirty, isSubmitting, status } = props;
    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Row>
          <Field id="password" type="password" md={12} {...props} />
          <Field id="confirmPassword" type="password" md={12} {...props} />
        </Form.Row>

        <FormMessage message={status} />

        <div className="d-flex justify-content-end">
          <SpinnerButton
            disabled={!dirty}
            label={t(`${translation}.submitButton`)}
            isSubmitting={isSubmitting}
          />
        </div>
      </Form>
    );
  };

  const UpdatePasswordFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => defaultFormValues,
    validationSchema,
    handleSubmit: onSubmit,
  })(UpdatePasswordFormInner);

  return <UpdatePasswordFormFormik translation={translation} />;
};
