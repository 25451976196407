import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { History } from 'history';
import { Field, Option, optionSchemaShape } from '../../components/fields';
import { useStateValue } from '../../state';
import { SpinnerButton, showToast, FormProps, SpinnerPageLoading } from '../../components';
import {
  api,
  logger,
  toOptions,
  useApi,
  getOptionValues,
  getPropertyFromArray,
  validatePersonalNumber,
  toLocaleDateTimeString,
  getOption,
  // useFetchEntries,
  toOption,
  getCustomersFromMeta,
} from '../../lib';
// import { CreateCustomerDialog } from '../Customers/CreateCustomerDialog';
import { CreateMobileDialog } from '../Mobiles/CreateMobileDialog';
// import { CreateDepartmentDialog } from '../Departments/CreateDepartmentDialog';

export interface FormValues {
  customer: Option | undefined;
  department: Option | undefined;
  orgNumber?: string;
  mobileNumber: Option | undefined;
  username: string;
  usePersonnummer?: boolean;
  mobileNumberStatus?: boolean;
  costCenter: String;
  status: Option | undefined | number;
  type: Option | undefined | number;
  dataType: Option | undefined | number;
  iccid?: Option;
  portDate?: String;
  portTime?: String;
}

interface CreateSubscriptionProps {
  history: History;
  buttonLabel?: string;
}

export const CreateSubscription: React.SFC<CreateSubscriptionProps> = ({ history }) => {
  const translation = 'dialogs.createSubscription';
  const { t } = useTranslation();
  const [{ settings, administrator }, dispatch] = useStateValue();
  const [customers, loadingCustomers] = administrator.isRole.sysAdmin
    ? useApi('customers')
    : getCustomersFromMeta(administrator);

  if (loadingCustomers || !settings) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const defaultValues = {
    customer: customers && customers.length === 1 ? toOption(customers[0]) : undefined, // Auto populate, if only one customer.
    department: undefined,
    mobileNumber: undefined,
    username: '',
    orgNumber: '',
    usePersonnummer: false,
    costCenter: '',
    status: -1,
    type: getOption(settings.subscriptionCallTypes, 1),
    dataType: getOption(settings.subscriptionDataTypes, 1),
    iccid: undefined,
    portDate: '',
    portTime: '',
  };

  const validationSchema = Yup.object().shape({
    customer: Yup.object()
      .shape(optionSchemaShape)
      .nullable()
      .required(t(`${translation}.customer.invalid`)),
    department: Yup.object()
      .shape(optionSchemaShape)
      .nullable()
      .required(t(`${translation}.department.invalid`)),
    mobileNumber: Yup.object()
      .shape(optionSchemaShape)
      .nullable()
      .required(t(`${translation}.mobileNumber.invalid`)),
    orgNumber: Yup.string().when('usePersonnummer', {
      is: (usePersonnummer: boolean) => usePersonnummer,
      then: Yup.string()
        .required(t(`${translation}.orgNumber.missing`))
        .test('check-format', t(`${translation}.orgNumber.invalid`), (value) =>
          Boolean(validatePersonalNumber(value || ''))
        ),
    }),
  });

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      const metaValues = {
        costCenter: values.costCenter,
        personnummer: values.usePersonnummer ? values.orgNumber : '',
      };

      const { id: metaId } = (await api.createEntry('subscriptions/meta', metaValues)) as {
        id: number;
      };

      const { portTime, portDate, iccid, ...subscriptionUpdate } = values;

      const updatedValues = {
        ...subscriptionUpdate,
        customer: getOptionValues(values.customer),
        department: getOptionValues(values.department),
        mobileNumber: getOptionValues(values.mobileNumber),
        meta: metaId,
        type: getOptionValues(values.type as any),
        dataType: getOptionValues(values.dataType as any),
      };

      const { id } = (await api.createEntry('subscriptions', updatedValues)) as { id: number };
      const start = toLocaleDateTimeString((values.mobileNumber as any).portTime);

      // Add jobs
      if (values.status === 2) {
        await api.createEntry('misc/addjob', {
          parent: 0,
          type: 6, // Port number
          start,
          data: { sub_id: id },
        });
      }

      if (values.status === 1 || values.status === 2) {
        await api.createEntry('misc/addjob', {
          parent: 0,
          type: 5, // Sync Kamailio
          start,
          data: { sub_id: id, type: 'check' },
        });
      }

      // Update portTime
      if (portTime && portDate && updatedValues.mobileNumber) {
        try {
          const mobile = (await api.getEntry('mobiles', String(updatedValues.mobileNumber))) as any;
          await api.replaceEntry('mobiles', String(mobile.id), {
            ...mobile,
            imsi: mobile.imsi || undefined,
            portTime: `${portDate} ${portTime}`,
          });
        } catch (err) {}
      }

      // Activate aubscription
      if (iccid) {
        await api.createEntry('mobiles/addimsi', {
          imsi: iccid.value,
          mobileNumber: updatedValues.mobileNumber,
        });

        await api.createEntry('misc/addjob', {
          parent: 0,
          type: 1, // Activate Subscription
          data: { mnr_id: updatedValues.mobileNumber },
        });
      }

      // setShow(false);
      history.push(`/admin/subscriptions/${id}`);
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Subscription (create)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const SubscriptionFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const { handleSubmit, dirty, isSubmitting, values, initialValues, setValues } = props;

    const updateDependentFields = (option: any, name: any, setFieldValue: any) => {
      const setOrgNumber = (id: number) => {
        const orgNumber = getPropertyFromArray(customers, id, 'orgNumber');
        setFieldValue('orgNumber', orgNumber);
      };

      if (name === 'customer') {
        setFieldValue('department', '');
        setFieldValue('mobileNumber', '');
        if (!values.usePersonnummer) {
          setFieldValue('orgNumber', '');
        }
      }

      if (name === 'customer' && option && !values.usePersonnummer) {
        setOrgNumber(option.value);
      }

      if (name === 'usePersonnummer' && !option && values.customer) {
        setOrgNumber(Number(values.customer.value));
      }

      if (name === 'usePersonnummer' && option) {
        setFieldValue('orgNumber', '');
      }

      if (name === 'mobileNumber' && option) {
        const { status, portTime } = option;
        if (status === 1 || status === 2) {
          setFieldValue('status', status);
        }
        setFieldValue('portDate', portTime && new Date(portTime).toLocaleDateString());
        setFieldValue('portTime', portTime && new Date(portTime).toLocaleTimeString());
      }
    };

    // To load data that depends on a previous fetch we need a workaround because, rendered more hooks
    // than during the previous render is not allowed by REACT.
    const customerId = values.customer ? String(values.customer.value) : 'waitingOnData';
    const [departments] = useApi('departments/customers', customerId);
    const [imsis] = useApi(`imsis/available/${customerId}`);

    const loadMobiles = async (inputValue: string) => {
      if (values.customer && inputValue.length > 1) {
        const mobiles = (await api.createEntry('mobiles/search', {
          value: inputValue,
          customer: values.customer.value,
        })) as [];
        return toOptions(mobiles, 'mobileNumber', true);
      }
    };

    const dialogOnCreate = (dialog: string, item: any) => {
      // if (dialog === 'createCustomer') {
      //   setCustomers([...customers, { id: item.id, name: item.name, orgNumber: item.orgNumber }]);
      // }

      // if (dialog === 'createDepartment') {
      //   setValues({ ...values, department: { value: item.id, label: item.name } });
      // }

      if (dialog === 'createMobile') {
        setValues({
          ...values,
          mobileNumber: { value: item.id, label: item.mobileNumber },
          status: item.status,
        });
      }
    };

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Row>
          <Field
            {...props}
            id="customer"
            // dialog={<CreateCustomerDialog dialogOnCreate={dialogOnCreate} />}
            type="reactSelect2"
            options={toOptions(customers)}
            isClearable
            onFieldChange={updateDependentFields}
            md={6}
          />
          <Field
            {...props}
            id="department"
            // dialog={
            //   values.customer && (
            //     <CreateDepartmentDialog
            //       dialogOnCreate={dialogOnCreate}
            //       customer={values.customer}
            //     />
            //   )
            // }
            type="reactSelect2"
            isClearable
            options={toOptions(departments)}
            md={6}
            isDisabled={!values.customer}
          />
          <Field {...props} id="orgNumber" disabled={!values.usePersonnummer} type="text" md={6} />
          <Field
            {...props}
            id="mobileNumber"
            dialog={
              values.customer && (
                <CreateMobileDialog
                  dialogOnCreate={dialogOnCreate}
                  customerReadOnly
                  customer={values.customer}
                  customers={customers}
                />
              )
            }
            type="reactAsyncSelect"
            defaultOptions
            isClearable
            loadOptions={loadMobiles}
            onFieldChange={updateDependentFields}
            md={6}
            isDisabled={!values.customer}
          />
          <Field
            {...props}
            id="usePersonnummer"
            onFieldChange={updateDependentFields}
            type="checkbox"
            md={6}
          />
        </Form.Row>

        <Form.Row>
          <Field {...props} id="username" type="text" md={3} />
          <Field {...props} id="costCenter" type="text" md={3} />
          <Field
            {...props}
            id="type"
            type="reactSelect2"
            options={settings.subscriptionCallTypes}
            md={3}
          />
          <Field
            {...props}
            id="dataType"
            type="reactSelect2"
            options={settings.subscriptionDataTypes}
            md={3}
          />
        </Form.Row>

        <Form.Row>
          <Field
            {...props}
            id="iccid"
            type="reactSelect2"
            options={toOptions(imsis, 'iccid')}
            md={4}
            isClearable
            isSearchable
          />
          <Field {...props} id="portDate" type="date" md={4} />
          <Field {...props} id="portTime" type="time" md={4} />
        </Form.Row>

        <div className="d-flex justify-content-end">
          <Button
            variant="secondary"
            className="mr-1"
            onClick={() => history.push(`/admin/subscriptions`)}
          >
            {t('buttons.cancel')}
          </Button>
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </div>
      </Form>
    );
  };

  const SubscriptionFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => defaultValues,
    validationSchema,
    handleSubmit,
    // enableReinitialize: false,
  })(SubscriptionFormInner);

  return (
    <>
      <h1 className="mb-3">{t(`${translation}.title`)}</h1>
      <SubscriptionFormFormik translation={translation} />
    </>
  );
};
