import { useState, useEffect } from 'react';
import { api } from '../../lib';
import { useStateValue } from '../../state';
import { showToast } from '../../components';

export const useFetch = (token: string) => {
  const [, dispatch] = useStateValue();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');

  async function fetchUrl() {
    try {
      await api.getEntry('auth/confirm-email', token);
      showToast(
        { title: 'Confirm email', body: 'Email address confirmed', type: 'success' },
        dispatch
      );
      setValidated(true);
    } catch (err) {
      setError(err.message);
    }
  }

  useEffect(() => {
    fetchUrl();
  }, []);

  return [validated, error];
};
