import React from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  SpinnerButton,
  BasicFormProps,
  FormProps,
  getChangedFields,
  DeleteButton,
  SpinnerPageLoading,
} from '../../components';
import { useStateValue } from '../../state';
import { Field } from '../../components/fields';
import {
  toOptions,
  useApi,
  validateFixedPhoneNumber,
  validateMobileNumber,
  toLocaleTimeString,
  toLocaleDateString,
} from '../../lib';
import { DialogValues } from '../Mobiles/CreateMobileDialog';

export interface FormValues extends DialogValues {
  id: number;
  changedFields?: string[];
}

interface MobileFormProps extends BasicFormProps {
  id: string;
  onDelete: (id: number) => void;
}

export const MobileForm: React.SFC<MobileFormProps> = ({ translation, onSubmit, onDelete, id }) => {
  const { t } = useTranslation();
  const [{ settings, administrator }] = useStateValue();

  // Fetch data from api
  const customerId = administrator.customers[0];
  const [imsis, loadingImsis] = useApi(`imsis/available/${customerId}`);
  const [customers, loadingCustomers] = useApi('customers');
  // To load data that depends on a previous fetch we need a workaround because, rendered more hooks
  // than during the previous render is not allowed by REACT.
  // In this case we need to load mobile and get mobile.imsi before current imsi is loaded. To make sure
  // this can be done I added a condition in useApi that don't load data if id = 'waitingOnData'.
  const [mobile, loadingMobile] = useApi('mobiles', id);
  const imsiId = mobile ? String(mobile.imsi) : 'waitingOnData';
  const [imsi, loadingImsi] = useApi('imsis', imsiId);

  if (!settings || loadingMobile || loadingImsi || loadingImsis || loadingCustomers) {
    return <SpinnerPageLoading message={t('loading.form')} />;
  }

  // Merge current imsi with availabla imsis
  if (imsi) {
    imsis.push(imsi);
  }

  const formValues = {
    ...mobile,
    status: settings.mobileStatus.find((ms: any) => mobile.status === ms.value),
    iccid: toOptions(imsis, 'iccid').find((i: any) => mobile.imsi === i.value),
    customer: toOptions(customers).find((c: any) => mobile.customer === c.value),
    portDate: toLocaleDateString(mobile.portTime),
    portTime: toLocaleTimeString(mobile.portTime),
  };

  const validationSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required(t(`${translation}.mobileNumber.missing`))
      .test('check-format', t(`${translation}.mobileNumber.invalid`), (value) =>
        validateMobileNumber(value || '')
      ),
    fixedNumber: Yup.string().test(
      'check-format',
      t(`${translation}.fixedNumber.invalid`),
      (value) => value === undefined || validateFixedPhoneNumber(value)
    ),
  });

  const MobileFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const {
      handleSubmit,
      // dirty,
      isSubmitting,
      values,
      initialValues,
      // submitCount,
      // status,
    } = props;

    // Pass along changedFields to handleSubmit.
    values.changedFields = getChangedFields(initialValues, values);
    const dirty = values.changedFields.length !== 0;

    const status = values.status && values.status.value;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <div>
          <Form.Row>
            <Field
              {...props}
              id="mobileNumber"
              disabled={status !== 1 && status !== 2}
              type="text"
              md={4}
            />
            <Field {...props} id="fixedNumber" type="text" md={4} />
            <Field {...props} id="portDate" type="date" md={2} />
            <Field {...props} id="portTime" type="time" md={2} />
            <Field
              {...props}
              id="iccid"
              type="reactSelect2"
              options={toOptions(imsis, 'iccid')}
              md={4}
              isClearable
            />
            <Field
              {...props}
              id="customer"
              type="reactSelect2"
              options={toOptions(customers)}
              md={4}
              isClearable
              isDisabled
            />
            <Field
              {...props}
              id="status"
              isDisabled
              type="reactSelect2"
              options={settings.mobileStatus}
              md={4}
            />
          </Form.Row>
        </div>

        <div className="d-flex justify-content-end">
          <DeleteButton
            onDelete={onDelete}
            disabled /*={status !== 1}*/
            title="Not implemented in database!"
            isSubmitting={isSubmitting}
          />
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </div>
      </Form>
    );
  };

  const MobileFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => formValues,
    validationSchema,
    handleSubmit: onSubmit,
  })(MobileFormInner);

  return <MobileFormFormik translation={translation} />;
};
