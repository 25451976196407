import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { useStateValue, Administrator } from '../../state';
import {
  StatusIcon,
  SpinnerPageLoading,
  ImportButtons,
  Pager,
  Table,
  defaultSort,
  showToast,
  FilterForm,
  Filter,
} from '../../components';
import {
  toLocaleDateTimeString,
  getLabelsFromArray,
  useApi,
  withFilters,
  toOptions,
  getOption,
} from '../../lib';
import { CreateAdministratorDialog } from './CreateAdministratorDialog';

const withCustomFilters = (arr: any, filter: Filter[]) =>
  arr.filter((f: any) =>
    filter.every(({ name, value }) => {
      if (name === 'status') {
        return (
          (value === 'blocked' && f.blocked) ||
          (value === 'notBlocked' && !f.blocked) ||
          (value === 'confirmed' && f.confirmed) ||
          (value === 'notConfirmed' && !f.confirmed)
        );
      } else {
        return Array.isArray(f[name]) ? f[name].includes(value) : f[name] === value;
      }
    })
  );

export const Administrators: React.SFC<RouteComponentProps> = ({ history }) => {
  const translation = 'administrators';
  const { t } = useTranslation();
  const [{ administrator, settings }, dispatch] = useStateValue();
  const [filter, setFilter] = useState<Filter[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = useState<(number | string)[]>([]);
  const [administratorsRaw, loadingAdministrators] = useApi('administrators');
  const [customers, loadingCustomers] = useApi('customers');
  const [departments, loadingDepartments] = useApi('customers/departments');

  if (
    !administrator ||
    !settings ||
    loadingAdministrators ||
    loadingCustomers ||
    loadingDepartments
  ) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const { isRole } = administrator;
  const administrators = isRole.sysAdmin
    ? administratorsRaw
    : administratorsRaw.filter(({ customers }: Administrator) =>
        customers.some((c: any) => administrator.customers.includes(c))
      );

  const roleOptions = settings.roles;
  const customerOptions = toOptions(customers);
  const departmentOptions = toOptions(departments);

  const statusOptions = ['notBlocked', 'blocked', 'notConfirmed', 'confirmed'].map((value) => ({
    value,
    label: t(`statusIcons.administratorStatus.${value}.label`),
    hover: t(`statusIcons.administratorStatus.${value}.hover`),
    class: t(`statusIcons.administratorStatus.${value}.class`),
    icon: t(`statusIcons.administratorStatus.${value}.icon`),
  }));

  const getStatusOption = (blocked: boolean, confirmed: boolean) => {
    let value = 'notConfirmed';
    if (blocked) {
      value = 'blocked';
    } else if (confirmed) {
      value = 'confirmed';
    }

    return statusOptions.find((option) => option.value === value);
  };

  const getRow = ({
    id,
    firstName,
    lastName,
    customers,
    customerDepartments,
    roles,
    lastAdminAccess,
    blocked,
    confirmed,
  }: any) => (
    <>
      <Col>
        <div style={{ fontWeight: 600 }}>{`${firstName} ${lastName}`}</div>
        <div>{getLabelsFromArray(customerOptions, customers, true)}</div>
      </Col>
      <Col>{getLabelsFromArray(departmentOptions, customerDepartments)}</Col>
      <Col>{getLabelsFromArray(settings.roles, roles)}</Col>
      <Col className="text-right">
        <div>
          <StatusIcon status={getStatusOption(blocked, confirmed)} />
        </div>
        <div>{toLocaleDateTimeString(lastAdminAccess)}</div>
      </Col>
    </>
  );

  const handleRowClick = (row: any) => {
    history.push({
      pathname: `/admin/administrators/${row.id === administrator.id ? 'me' : row.id}`,
      state: { detail: 'response.data ' },
    });
  };

  const handleCompare = ({ field, ascending }: any) => (a: any, b: any) => {
    let fieldA = a[field];
    let fieldB = b[field];

    if (field === 'customer') {
      fieldA = getLabelsFromArray(customerOptions, a.customers[0]);
      fieldB = getLabelsFromArray(customerOptions, b.customers[0]);
    } else if (field === 'customerDepartments') {
      fieldA = getLabelsFromArray(departmentOptions, a.customerDepartments[0]);
      fieldB = getLabelsFromArray(departmentOptions, b.customerDepartments[0]);
    } else if (field === 'status') {
      const val = ({ blocked, confirmed }: { blocked: boolean; confirmed: boolean }) =>
        (blocked ? 0 : 2) + (confirmed ? 0 : 4);
      fieldA = val(a);
      fieldB = val(b);
    }

    return defaultSort(fieldA, fieldB, ascending);
  };

  const actions = [{ value: 0, label: t(`${translation}.actions.create`) }];

  const filteredItems = withCustomFilters(administrators, filter);

  return (
    <>
      {(isRole.sysAdmin || isRole.custAdmin) && (
        <div className="float-right mt-2">
          <CreateAdministratorDialog
            history={history}
            buttonLabel={t(`${translation}.createButton`)}
          />
        </div>
      )}
      <h1>{t(`${translation}.title`)}</h1>

      {/* <Row>
        <Col md={8}>
          <h1>{t(`${translation}.title`)}</h1>
        </Col>
        <Col md={4}>
          <div className="d-inline-flex w-100 mt-3">
            <Select className="w-100" value={actions[0]} options={actions} />
            <div className="ml-1">
              <CreateAdministratorDialog history={history} />
            </div>
          </div>
        </Col>
      </Row> */}

      <p>{t(`${translation}.text`)}</p>

      <FilterForm
        translation={translation}
        fields={[
          { name: 'customers', options: customerOptions },
          { name: 'roles', options: roleOptions },
          { name: 'status', options: statusOptions },
        ]}
        filter={filter}
        setFilter={setFilter}
      />

      <Container fluid className="number-list mb-4 mt-4">
        <Table
          translation={translation}
          items={filteredItems}
          history={history}
          getRow={getRow}
          columns={[
            ['firstName', 'customers'],
            'customerDepartments',
            'roles',
            ['status', 'lastAdminAccess'],
          ]}
          onRowClick={handleRowClick}
          onCompare={handleCompare}
          defaultCompare={{ field: 'firstName', ascending: true }}
          // selected={selected}
          // setSelected={setSelected}
          activePage={activePage}
          pageSize={pageSize}
        />
      </Container>

      <Pager
        totalItemsCount={filteredItems.length}
        pageRangeDisplayed={6}
        activePage={activePage}
        setActivePage={setActivePage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};
