import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStateValue } from '../state';

export const PrivateRoute: React.SFC<any> = ({ component: Component, ...rest }) => {
  const [{ authenticated, administrator }] = useStateValue();
  const { t } = useTranslation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authenticated) {
          return (
            <div className="d-flex h-100 justify-content-center align-items-center">
              <Spinner animation="border" />
              <div className="ml-2">{t('loading.connecting')}</div>
            </div>
          );
        }

        return administrator ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
