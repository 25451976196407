import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { showToast } from '../../components';
import { api, logger } from '../../lib';
import { useStateValue, setAdministrator, setIsAuthenticated } from '../../state';
import { UpdatePasswordForm, FormValues } from './UpdatePasswordForm';
import { decodeJwt } from '../../lib/misc';

const translation = 'updatePassword';

export const UpdatePassword: React.SFC<RouteComponentProps<{ token: string }>> = ({
  location,
  match: { params },
}) => {
  const { token } = params;
  const [{ authenticated }, dispatch] = useStateValue();
  const { t } = useTranslation();

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    try {
      const { password, confirmPassword } = values;
      await api.updatePassword(token, password, confirmPassword);
      const jwtData = decodeJwt(token);
      if (jwtData) {
        const { profile: administrator } = await api.login(jwtData.email, password);
        dispatch(setAdministrator(administrator));
        dispatch(setIsAuthenticated(true));
        showToast({ title: `Password`, body: 'Password was saved.', type: 'success' }, dispatch);
      }
    } catch (error) {
      logger('UpdatePassword.js', error);
      setStatus({ status: 'danger', message: error.message });
    }
  };

  return !authenticated ? (
    <Container>
      <h3>{t(`${translation}.title`)}</h3>
      <UpdatePasswordForm translation={`${translation}.form`} onSubmit={handleSubmit} />
    </Container>
  ) : (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
};
