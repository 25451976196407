import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
// import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { Field, Option } from '../../components/fields';
import { useStateValue } from '../../state';
import { SpinnerButton, showToast, FormProps } from '../../components';
import { api, logger, getOptionValues } from '../../lib';

export interface DialogValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  language: Option | undefined;
  description?: string;
}

interface CreateAdministratorDialogProps {
  history: History;
  buttonLabel?: string;
}

export const CreateAdministratorDialog: React.SFC<CreateAdministratorDialogProps> = ({
  history,
  buttonLabel,
}) => {
  const translation = 'dialogs.createAdministrator';
  const { t } = useTranslation();
  const [{ settings }, dispatch] = useStateValue();
  // const [values, setValues] = useState<DialogValues>(defaultValues);
  const [show, setShow] = useState(false);

  const languages = settings.languages.map(
    ({ value }) =>
      ({
        value,
        label: t(`languages.${value}`),
      } as Option)
  );

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    language: languages[0],
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t(`${translation}.firstName.invalid`)),
    lastName: Yup.string().required(t(`${translation}.lastName.invalid`)),
    email: Yup.string()
      .email(t(`${translation}.email.invalid`))
      .required(t(`${translation}.email.invalid`)),
  });

  const handleSubmit = async (
    values: DialogValues,
    { setSubmitting }: FormikHelpers<DialogValues>
  ) => {
    try {
      const updateValues = {
        ...values,
        language: getOptionValues(values.language),
      };
      const { id } = (await api.createEntry('administrators', updateValues)) as { id: number };
      setShow(false);
      history.push(`/admin/administrators/${id}`);
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Administrator (create)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const AdministratorFormInner = (props: FormProps & FormikProps<DialogValues>) => {
    const { handleSubmit, dirty, isSubmitting } = props;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Field {...props} id="firstName" type="text" md={5} />
            <Field {...props} id="lastName" type="text" md={5} />
            <Field {...props} id="language" type="reactSelect2" options={languages} md={2} />
            <Field {...props} id="email" type="email" md={6} />
            <Field {...props} id="phone" type="text" md={6} />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t('buttons.cancel')}
          </Button>
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </Modal.Footer>
      </Form>
    );
  };

  const AdministratorFormFormik = withFormik<FormProps, DialogValues>({
    mapPropsToValues: () => defaultValues,
    validationSchema,
    handleSubmit,
  })(AdministratorFormInner);

  return (
    <>
      <SpinnerButton
        onClick={() => setShow(!show)}
        type="button"
        label={buttonLabel || t('buttons.plus')}
      />
      {show && (
        <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${translation}.title`)}</Modal.Title>
          </Modal.Header>
          <AdministratorFormFormik translation={translation} />;
        </Modal>
      )}
    </>
  );
};
