import React from 'react';
import { Form, Col, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ReactBootstrapFieldProps {
  id: string;
  type: string;
  handleChange: any;
  values: any;
  touched: any;
  errors: any;
  options?: any;
  md?: number;
  translation?: string;
  disabled?: boolean;
  readOnly?: boolean;
  setFieldValue: any;
  onFieldChange?: any;
  prependCheckbox?: any;
}

export const ReactBootstrapField: React.SFC<ReactBootstrapFieldProps> = (props) => {
  const {
    id,
    md,
    type,
    translation,
    // handleChange,
    values,
    touched,
    errors,
    disabled,
    readOnly,
    onFieldChange,
    setFieldValue,
    prependCheckbox,
  } = props;
  const { t } = useTranslation();
  const transBase = `${translation}.${id}`;
  const id_checkbox = `${id}_checkbox`;

  const handleChange = (e: any) => {
    const { name } = e.target;
    let value = e.target.value;
    let fieldId = id;

    // Exeption when checkbox is used
    if (type === 'checkbox') {
      value = !values[id];
    }

    // Exeptions when prepended checkbox is used
    if (name !== id && name.endsWith('_checkbox')) {
      value = !values[name];
      fieldId = name;
    }

    // Pass on the onChange event to the parent. Can be used to handle dependent fields.
    if (onFieldChange) {
      onFieldChange(value, fieldId, setFieldValue);
    }

    setFieldValue(fieldId, value);
  };

  const FormControl = (
    <Form.Control
      type={type}
      name={id}
      onChange={handleChange}
      value={values[id] || ''}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={t(`${transBase}.placeholder`, '')}
      as={type === 'textarea' ? 'textarea' : 'input'}
    />
  );

  return (
    <Form.Group as={Col} md={md}>
      {type === 'checkbox' ? (
        <Form.Check
          custom // custom kräver både id och name
          type={type}
          id={id}
          name={id}
          onChange={handleChange}
          label={t(`${transBase}.label`, '')}
          checked={values[id]}
          disabled={disabled}
        />
      ) : (
        <>
          <Form.Label>{t(`${transBase}.label`, '')}</Form.Label>
          {prependCheckbox ? (
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  onChange={handleChange}
                  checked={values[id_checkbox]}
                  // id={id_checkbox}
                  name={id_checkbox}
                  aria-label="Checkbox for following text input"
                />
              </InputGroup.Prepend>
              {FormControl}
            </InputGroup>
          ) : (
            FormControl
          )}
        </>
      )}

      {touched[id] && errors[id] && <small className="form-text text-danger">{errors[id]}</small>}
      {touched[id] && !errors[id] && (
        <small className="form-text text-success">{t(`${transBase}.valid`, '')}</small>
      )}
      <small className="form-text text-muted">{t(`${transBase}.info`, '')}</small>
    </Form.Group>
  );
};
