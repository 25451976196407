import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { History } from 'history';
import { SortedHeaders, Sort, Column } from './SortedHeaders';
import { isArray } from 'util';

interface Items {
  id: string | number;
}

type Selected = number | string;

interface TableProps {
  translation: string;
  items: Items[];
  columns: Column[];
  getRow: any;
  defaultCompare: Sort;
  onRowClick: (row: { id: number }) => void;
  history?: History;
  onCompare?: any; // (a: any, b: any) => number;
  selected?: Selected[];
  setSelected?: (selected: Selected[]) => void;
  activePage: number;
  pageSize: number;
}

export const defaultSort = (a: any, b: any, ascending = true): number => {
  if (a > b) {
    return ascending ? -1 : 1;
  }
  if (a < b) {
    return ascending ? 1 : -1;
  }
  return 0;
};

export const Table: React.SFC<TableProps> = ({
  translation,
  items,
  getRow,
  columns,
  onRowClick,
  defaultCompare,
  onCompare,
  selected,
  setSelected,
  activePage,
  pageSize,
}) => {
  const [sort, setSort] = useState(defaultCompare);

  const allSelected = items.every(({ id }) => selected && selected.includes(id));

  const handleCheckboxChange = ({ id, isSelected }: { id: string; isSelected: boolean }) => {
    if (!isArray(selected)) {
      return;
    }

    if (isSelected) {
      const selectedCopy = [...selected];
      selectedCopy.splice(
        selected.findIndex((val) => val === id),
        1
      ); // Remove index
      typeof setSelected === 'function' && setSelected(selectedCopy);
    } else {
      typeof setSelected === 'function' && setSelected([...selected, id]);
    }
  };

  const handleAllCheckboxChange = () => {
    typeof setSelected === 'function' && setSelected(allSelected ? [] : items.map(({ id }) => id));
  };

  const handleSortHeaderClick = (field: string) => {
    setSort({
      field,
      ascending: field === sort.field ? !sort.ascending : sort.ascending,
    });
  };

  function compare(a: any, b: any) {
    const fieldA = a[sort.field];
    const fieldB = b[sort.field];

    return defaultSort(fieldA, fieldB, sort.ascending);
  }

  // onCompare returns a compare function, if not we use the default compare.
  const table = items
    .sort(typeof onCompare === 'function' ? onCompare(sort) : compare)
    .slice((activePage - 1) * pageSize, activePage * pageSize)
    .map((item: any, index: number) => (
      <Row
        key={item.id}
        onClick={() => onRowClick(item)}
        style={{
          cursor: 'pointer',
          backgroundColor: index % 2 === 1 ? '#eee' : '#fff',
        }}
      >
        {isArray(selected) && (
          <Col style={{ maxWidth: '30px' }}>
            <Form.Check
              // NOTE: Using custom checkbox
              // - Row click triggered also when the checkbox is clicked (stopPropagation, preventDefault does not solve it).
              // - ID cannot be 0, but "0" is ok.
              // custom
              type="checkbox"
              id={item.id}
              checked={selected.includes(item.id)}
              onChange={() =>
                handleCheckboxChange({
                  id: item.id,
                  isSelected: selected.includes(item.id),
                })
              }
              onClick={(e: any) => e.stopPropagation()}
            />
          </Col>
        )}
        {getRow(item)}
      </Row>
    ));

  return (
    <>
      <SortedHeaders
        translation={translation}
        sort={sort}
        columns={columns}
        allSelected={allSelected}
        onAllCheckboxChange={
          typeof setSelected === 'function' ? handleAllCheckboxChange : undefined
        }
        onSortHeaderClick={handleSortHeaderClick}
      />
      {table}
    </>
  );
};
