import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, FormikHelpers, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { History } from 'history';
import { Field, optionSchemaShape } from '../../components/fields';
import { SpinnerButton, FormProps, useFormMessage } from '../../components';
import { api, useApi, logger, toOptions, toOption } from '../../lib';
import { Roles } from '../../state';

interface FormValues {
  id?: number;
  imsi: any;
  pin: string;
  port: boolean;
  portDate: string;
  portTime: string;
}

interface ActivateSubscriptionDialogProps {
  history: History;
  buttonLabel?: string;
  subscription: {
    id: number;
    mobileNumber: string;
    mobileNumberId: string;
    multipleSubscriptionsSelected: boolean;
    mobileNumberPortTime: string;
    status: number;
    mobileNumberImsi: number;
  };
  roles: Roles;
  customerId: number;
}

export const ActivateSubscriptionDialog: React.SFC<ActivateSubscriptionDialogProps> = ({
  history,
  subscription,
  buttonLabel,
  roles,
  customerId,
}) => {
  const translation = 'dialogs.activateSubscription';
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [formMessage, setMessage] = useFormMessage();
  const [imsis, loadingImsis] = useApi(`imsis/available/${customerId}`);
  const [imsi, loadingImsi] = useApi(
    'imsis',
    String((subscription && subscription.mobileNumberImsi) || 0)
  );

  const defaultValues = {
    imsi: toOption(imsi, 'iccid'),
    pin: '',
    mobileNumber: subscription && subscription.mobileNumber,
    port: false,
    portDate: subscription && new Date(subscription.mobileNumberPortTime).toLocaleDateString(),
    portTime: subscription && new Date(subscription.mobileNumberPortTime).toLocaleTimeString(),
  };

  const activated = subscription && subscription.status > 1;

  const requirePin = !roles.custAdmin && !roles.depAdmin && !roles.sysAdmin;

  const validationSchema = Yup.object().shape({
    imsi: Yup.object()
      .shape(optionSchemaShape)
      .nullable()
      .required(t(`${translation}.iccid.invalid`)),
    pin: requirePin
      ? Yup.string()
          .length(4, t(`${translation}.pin.length`))
          .required(t(`${translation}.pin.required`))
      : Yup.string(),
  });

  const validate = async (values: any /* , props: any */) => {
    const { pin, imsi } = values;

    if (!requirePin || !imsi || !pin || pin.length !== 4) {
      return;
    }

    try {
      await api.createEntry('imsis/verifypin', { pin, imsi: imsi.value });
    } catch (error) {
      const { message } = error.response.data.error;
      setMessage({
        status: 'danger',
        message: t(`${translation}.errors.invalidPin1`),
      });
      throw { pin: t(`${translation}.errors.${message}`) };
    }
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      const updatedValus = {
        pin: values.pin,
        imsi: values.imsi.value,
        mobileNumber: subscription && subscription.mobileNumberId,
      };

      await api.createEntry('mobiles/addimsi', updatedValus);

      // Add job
      await api.createEntry('misc/addjob', {
        parent: 0,
        type: 1, // Activate Subscription
        data: { mnr_id: subscription.mobileNumberId },
      });

      history.push(`/admin/subscriptions/${subscription.id}`);
    } catch (error) {
      const { message } = error.response.data.error;

      let reason = 'unknown';

      if (message === 'imsiConnectedToMobileNumber') {
        reason = 'imsiConnectedToMobileNumber';
      }

      if (message === 'mobileNumberConnectedToImsi') {
        reason = 'mobileNumberConnectedToImsi';
      }

      setMessage({
        status: 'danger',
        message: t(`${translation}.errors.${reason}`),
      });

      logger('Subscription (activate)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const SubscriptionFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const { handleSubmit, dirty, isSubmitting, values, validateField } = props;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          {subscription.multipleSubscriptionsSelected ? (
            <div className={`text-right text-danger`}>
              {t(`${translation}.errors.multipleSubscriptionsSelected`)}
            </div>
          ) : (
            <Form.Row>
              <Field {...props} disabled id="mobileNumber" type="text" md={4} />
              <Field
                {...props}
                id="imsi"
                type="reactSelect2"
                options={toOptions(imsis, 'iccid')}
                md={5}
                isClearable
                isSearchable
              />
              <Field {...props} id="pin" type="text" disabled={!requirePin} md={3} />
              <Field {...props} id="portDate" disabled={!values.port} type="date" md={4} />
              <Field {...props} id="portTime" disabled={!values.port} type="time" md={4} />
              <Field {...props} id="port" type="checkbox" md={4} />
            </Form.Row>
          )}

          {formMessage && (
            <div className={`text-right text-${formMessage.status}`}>{formMessage.message}</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t('buttons.cancel')}
          </Button>
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </Modal.Footer>
      </Form>
    );
  };

  const SubscriptionFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => defaultValues,
    validationSchema,
    validate,
    handleSubmit,
    validateOnChange: false, // Prevent brute force of pin codes.
  })(SubscriptionFormInner);

  return (
    <>
      <SpinnerButton
        onClick={() => setShow(!show)}
        disabled={!subscription || activated}
        label={buttonLabel || t('buttons.run')}
      />
      {show && (
        <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${translation}.title`)}</Modal.Title>
          </Modal.Header>
          <SubscriptionFormFormik translation={translation} />;
        </Modal>
      )}
    </>
  );
};
