import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { History } from 'history';
import { Field, Option } from '../../components/fields';
import { useStateValue } from '../../state';
import { SpinnerButton, showToast, FormProps } from '../../components';
import { api, logger, getOptionValues } from '../../lib';

export interface DialogValues {
  imsi: string;
  iccid: string;
  pin1: string;
  pin2: string;
  puk1: string;
  puk2: string;
}

interface CreateImsiDialogProps {
  history: History;
  buttonLabel?: string;
  disabled?: boolean;
  title?: string;
}

export const CreateImsiDialog: React.SFC<CreateImsiDialogProps> = ({
  history,
  buttonLabel,
  disabled,
  title,
}) => {
  const translation = 'dialogs.createImsi';
  const { t } = useTranslation();
  const [{ settings }, dispatch] = useStateValue();
  const [show, setShow] = useState(false);

  const defaultValues = {
    imsi: '',
    iccid: '',
    pin1: '',
    pin2: '',
    puk1: '',
    puk2: '',
  };

  const validationSchema = Yup.object().shape({
    imsi: Yup.string().required(t(`${translation}.imsi.invalid`)),
    iccid: Yup.string().required(t(`${translation}.iccid.invalid`)),
  });

  const handleSubmit = async (
    values: DialogValues,
    { setSubmitting }: FormikHelpers<DialogValues>
  ) => {
    try {
      const { id } = (await api.createEntry('imsis', values)) as { id: number };
      setShow(false);
      history.push(`/admin/imsis/refresh`);
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Imsi (create)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const ImsiFormInner = (props: FormProps & FormikProps<DialogValues>) => {
    const { handleSubmit, dirty, isSubmitting } = props;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Field {...props} id="imsi" type="text" md={6} />
            <Field {...props} id="iccid" type="text" md={6} />
            <Field {...props} id="pin1" type="text" md={6} />
            <Field {...props} id="pin2" type="text" md={6} />
            <Field {...props} id="puk1" type="text" md={6} />
            <Field {...props} id="puk2" type="text" md={6} />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t('buttons.cancel')}
          </Button>
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </Modal.Footer>
      </Form>
    );
  };

  const ImsiFormFormik = withFormik<FormProps, DialogValues>({
    mapPropsToValues: () => defaultValues,
    validationSchema,
    handleSubmit,
  })(ImsiFormInner);

  return (
    <>
      <SpinnerButton
        onClick={() => setShow(!show)}
        type="button"
        disabled={disabled}
        title={title}
        label={buttonLabel || t('buttons.plus')}
      />
      {show && (
        <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${translation}.title`)}</Modal.Title>
          </Modal.Header>
          <ImsiFormFormik translation={translation} />;
        </Modal>
      )}
    </>
  );
};
