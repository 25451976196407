const debug = require('debug')('mvno:hooks');

import { useState, useEffect } from 'react';
import { api } from './api';

export const useApi = (contentType: string, id?: string): any => {
  const [data, setValues] = useState<any>();
  const [loading, setLoading] = useState(true);

  async function fetchUrl() {
    if (id === '') {
      // Cancel fetch if if is empty string. Used with 'waitingOnData' to handle conditional hooks.
      setLoading(false);
    } else if (id && id !== 'waitingOnData') {
      setValues(await api.getEntry(contentType, String(id)));
      setLoading(false);
    } else if (!id) {
      setValues(await api.getEntries(contentType));
      setLoading(false);
    }
    debug(contentType, id);
  }

  useEffect(() => {
    fetchUrl();
  }, [contentType, id]); // Reload if constentType or id is changed

  return [data, loading, setValues];
};

// Faked API call to get customers from the administrator object.
export const getCustomersFromMeta = (administrator: any) => {
  if (!administrator) {
    return [[], false];
  }

  const { customers } = administrator.meta;

  return [administrator.customers.map((c: any) => customers.find((x: any) => x.id === c)), false];
};

// export const useFetchEntries = (contentType: string /* , refresh = 0 */) => {
//   const [data, setValues] = useState();
//   const [loading, setLoading] = useState(true);

//   async function fetchUrl() {
//     setValues(await api.getEntries(contentType));
//     setLoading(false);
//     debug(contentType /* , refresh */);
//   }

//   useEffect(() => {
//     fetchUrl();
//   }, [contentType /* , refresh */]); // Reload if constentType is changed

//   return [data, loading, setValues];
// };
