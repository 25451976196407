import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useStateValue } from '../../state';
import logoMitel from '../../images/logo-mitel.webp';
import logoSwyx from '../../images/logo-swyx.webp';
import logoSkype from '../../images/logo-skype.webp';

export const Home: React.SFC = () => {
  const [{ authenticated }] = useStateValue();
  const { t } = useTranslation();

  return (
    <>
      <div className="jumbotron-section pt-5 pb-5">
        <Container>
          <h1>{t('home.title')}</h1>
          <div>{t('home.text')}</div>

          <div className="buttons mt-5 d-sm-flex justify-content-end ">
            {!authenticated ? (
              <>
                <Link to="/login" className="mt-2 mr-2 d-block">
                  <Button size="lg" variant="primary">
                    {t('home.login')}
                  </Button>
                </Link>
                <Link to="/register" className="mt-2 d-block">
                  <Button size="lg" variant="success">
                    {t('home.register')}
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Link to="/logout">
                  <Button size="lg" variant="primary">
                    {t('home.logout')}
                  </Button>
                </Link>
              </>
            )}
          </div>
        </Container>
      </div>

      <div className="logo-section mt-5 mb-5">
        <Container>
          {/* <h3>Min fina titel</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at felis sit amet
            lectus ullamcorper vehicula. Donec tincidunt tellus mattis, varius quam eu, commodo
            magna.
          </p>
          <p>
            Nulla tempus venenatis magna, ut tempor dui auctor quis. Ut a vehicula nisl, vel
            efficitur metus. Fusce sodales erat eu blandit lobortis. Etiam pulvinar sem vel orci
            ultricies varius. Morbi molestie mauris ut luctus mollis. Vivamus sodales placerat erat,
            nec efficitur enim eleifend eleifend. Nulla a mi simport apien. Pellentesque massa
            augue, mattis a felis sed, elementum interdum leo. Sed pulvinar vulputate finibus.
          </p> */}
        </Container>
      </div>

      <div className="logo-section mt-5 mb-5">
        <Container>
          <div className="d-sm-flex justify-content-around">
            <div>
              <div className="font-weight-bold mb-3">Premium</div>
              <img src={logoMitel} alt="mitel logo" />
              <ul className="mt-3">
                <li>Funktionell</li>
                <li>Driftsäker</li>
                <li>Marknadsledande</li>
                <li>Bas-funktioner</li>
                <li>Integrerat Contact center</li>
                <li>Traditionell telefontjänst</li>
                <li>Telefonist</li>
              </ul>
            </div>
            <div>
              <div className="font-weight-bold mb-3">Flexible</div>
              <img width="160" src={logoSwyx} alt="swyx logo" />
              <ul className="mt-3">
                <li>Flexibel och anpassningsbar tjänst</li>
                <li>Marknadsledande på Tyska marknaden</li>
                <li>Flexibel</li>
                <li>Ny modern servertjänst</li>
                <li>Contact Center</li>
                <li>Telefonist</li>
              </ul>
            </div>
            <div>
              <div className="font-weight-bold mb-3">Unlimited</div>
              <img src={logoSkype} alt="skype logo" />
              <ul className="mt-3">
                <li>Komplett Skype tjänst</li>
                <li>Många telefonväxeltjänster</li>
                <li>Marknadsledande</li>
                <li>Ny modern Skype-tjänst</li>
                <li>En fullständig Microsoft integration</li>
                <li>Contact Center</li>
                <li>Telefonist</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
