import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from './dialogs';
import { SpinnerButton } from './SpinnerButton';

interface DeleteButtonProps {
  onDelete: (id: number) => void;
  isSubmitting?: boolean;
  disabled?: boolean;
  title?: string;
  label?: string;
  className?: string;
}

export const DeleteButton: React.SFC<DeleteButtonProps> = ({
  onDelete,
  isSubmitting,
  disabled,
  title,
  label,
  className,
}) => {
  const { t } = useTranslation();
  const [confirmDialog, setConfirmDialog] = useState(false);

  if (!label) {
  }

  return (
    <>
      <SpinnerButton
        className={className ? className : 'mr-1'}
        variant="danger"
        type="button"
        onClick={() => setConfirmDialog(true)}
        disabled={disabled}
        title={title}
        label={label ? label : t('buttons.delete')}
        isSubmitting={isSubmitting}
      />
      <ConfirmDialog
        show={confirmDialog}
        setShow={setConfirmDialog}
        onOk={onDelete}
        title={t('dialogs.delete.title')}
        message={t('dialogs.delete.body')}
      />
    </>
  );
};
