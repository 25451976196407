// import uuid from 'uuid/v4';
// import { format, parse } from 'date-fns';
import jwt from 'jsonwebtoken';
import { Filter } from '../components';
import { Option } from '../components/fields';

export const getOption = (options: Option[], value: Number) =>
  options.find((option) => option.value === value);

export const toOption = (value: any, field = 'name', keepAllProps = false, valueName = 'id') =>
  value &&
  (keepAllProps
    ? { ...value, value: value[valueName], label: value[field] }
    : { value: value[valueName], label: value[field] });

export const toOptions = (values: any[], field = 'name', keepAllProps = false, valueName = 'id') =>
  values ? values.map((value: any) => toOption(value, field, keepAllProps, valueName)) : [];

export const getOptionValues = (option: Option[] | Option | undefined) => {
  if (option) {
    return Array.isArray(option) ? option.map(({ value }) => value) : option.value;
  }
};

export const keyToValue = (values: any[], keys: number[]) =>
  values.filter((val: any) => keys.find((key: number) => val.id === key));

export const keysToValues = (values: any[], keys: any[], field = 'id') =>
  values.filter((val: any) => keys.find((key: number) => val[field] === key));

export const keysToOptions = (values: any[], keys: any[]) => toOptions(keysToValues(values, keys));

export const toKeys = (values: any[]) =>
  values ? values.map(({ id }: { id: string | number }) => id) : [];

export const toLocaleDateString = (datetime: string) =>
  datetime && new Date(datetime).toLocaleDateString();

export const toLocaleTimeString = (datetime: string) =>
  datetime && new Date(datetime).toLocaleTimeString();

export const toLocaleDateTimeString = (datetime: string, format = { hideSeconds: true }) => {
  if (!datetime) return;
  const localeString = new Date(datetime).toLocaleString();
  return format.hideSeconds ? localeString.slice(0, -3) : localeString;
};

interface JwtData {
  id: string;
  email: string;
}

export const decodeJwt = (token: string) => jwt.decode(token) as JwtData;

export const getValueFromArray = (values: any[], id: number) => {
  return values && values.find((value) => value.id === id);
};

export const getPropertyFromArray = (values: any[], id: number, field: string = 'label') => {
  const value = getValueFromArray(values, id);
  return value && value[field];
};

export const getLabelFromArray = (options: Option[], id: number) => {
  const value = options.find(({ value }: Option) => value === id);
  return value && value.label;
};

function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

export const getLabelsFromArray = (options: Option[], ids: number[], unique = false) => {
  const labels = ids.map((value) => getLabelFromArray(options, value));
  return unique ? labels.filter(onlyUnique).join(', ') : labels.join(', ');
};

export const withFilters = (arr: any, filter: Filter[]) =>
  arr.filter((f: any) =>
    filter.every(({ name, value }) =>
      Array.isArray(f[name]) ? f[name].includes(value) : f[name] === value
    )
  );

// https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
export const getErrorMessage = (error: any) =>
  error.response && error.response.data ? error.response.data.error : { message: error.message };

export const getSubscriptionStatusIds = (status: number) => {
  switch (status) {
    case 1:
      return 'dataIncomplete';
    case 2:
      return 'porting';
    case 3:
      return 'activating';
    case 4:
      return 'active';
    case 5:
      return 'inactive';
    case 6:
      return 'deactivating';
    case 7:
      return 'deactivated';
    default:
      return 'unknown';
  }
};

export const onlyUniqueObjects = (value: any, field = 'id') => {
  const unique = new Set();
  // Get all unique ids.
  value.forEach((v: any) => v && v[field] && unique.add(v[field]));
  // Find and return related objects.
  return Array.from(unique).map((c: any) => value.find((v: any) => v[field] === c));
};
