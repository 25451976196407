import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { AdministratorForm, FormValues } from './AdministratorForm';
import { FormikHelpers } from 'formik';
import { api, logger, useApi } from '../../lib';
import { Administrator as Admin, useStateValue } from '../../state';
import { SpinnerPageLoading, showToast } from '../../components';

const translation = 'me';

interface Admin2 extends Admin {
  values: FormValues;
  onDelete: (id: number) => void;
}

export const Administrator: React.SFC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
  history,
}) => {
  const { id } = params;
  const [{ settings }, dispatch] = useStateValue();
  const { t } = useTranslation();
  const [administrator, loadingAdministrator] = useApi('administrators', id);
  const [departments, loadingDepartments] = useApi('customers/departments');

  if (!settings || loadingAdministrator || loadingDepartments) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      const { customers, roles, admDepartments, meta, language, ...update } = values;
      // NOTE: Av någon anledning kan `id` undefined här när man uppdaterar en administratör, gäller bara prod.
      //       `params.id` är dock satt.
      const administrator = (await api.getEntry('administrators', String(params.id))) as Admin2;

      // Update roles
      const roleIds = roles.map((r: any) => r.value);
      for (const role of [1, 2, 3, 4]) {
        if (administrator.roles.includes(role) && !roleIds.includes(role)) {
          await api.deleteEntry(`administrators/role/${administrator.id}`, String(role));
        }
        if (!administrator.roles.includes(role) && roleIds.includes(role)) {
          await api.createEntry('administrators/role', {
            administrator: administrator.id,
            role,
          });
        }
      }

      // Update admDepartments
      const admDepartmentIds = admDepartments.map((a: any) => a.value);
      for (const { id } of departments) {
        if (administrator.admDepartments.includes(id) && !admDepartmentIds.includes(id)) {
          await api.deleteEntry(`administrators/admDepartment/${administrator.id}`, String(id));
        }
        if (!administrator.admDepartments.includes(id) && admDepartmentIds.includes(id)) {
          await api.createEntry('administrators/admDepartment', {
            administrator: administrator.id,
            admDepartment: id,
          });
        }
      }

      // Update administrator
      const updateValues = {
        ...update,
        language: language && language.value,
      };
      await api.replaceEntry('administrators', String(updateValues.id), updateValues);
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Administrator (save)', error);
    } finally {
      setSubmitting(false);
    }
  };

  // INFO: NO SP exists
  const handleDelete = async (id: number) => {
    try {
      await api.deleteEntry('administrators', String(id));
      history.push(`/admin/administrators`);
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Administrator (delete)', error);
    }
  };

  return (
    <>
      <h1>
        {id === 'me'
          ? t(`${translation}.title`)
          : `${administrator.firstName} ${administrator.lastName}`}
      </h1>
      <p>{t(`${translation}.text`)}</p>
      <AdministratorForm
        translation={`${translation}.form`}
        departments={departments}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        values={administrator}
      />
    </>
  );
};
