import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps {
  show: boolean;
  onOk: (val: any) => void;
  setShow: (show: boolean) => void;
  title: string;
  message: string;
  cancelButtonText?: string;
  okButtonText?: string;
}

export const ConfirmDialog: React.SFC<ConfirmDialogProps> = ({
  show,
  setShow,
  onOk,
  title,
  message,
  cancelButtonText,
  okButtonText,
}) => {
  const { t } = useTranslation();

  return (
    <Modal size="sm" centered show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          {cancelButtonText ? cancelButtonText : t('buttons.cancel')}
        </Button>
        <Button variant="primary" onClick={onOk}>
          {okButtonText ? okButtonText : t('buttons.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
