import React from 'react';
import { ToastComponent, Toast } from './Toast';
import { useStateValue } from '../state';

export const Toasts = () => {
  const [{ toasts }] = useStateValue();

  return (
    <div
      className="toasts"
      style={{
        position: 'fixed',
        right: 10,
        top: 10,
        minWidth: 300,
        zIndex: 100,
      }}
    >
      {toasts.map((toast: Toast) => (
        <ToastComponent key={toast.id} {...toast} />
      ))}
    </div>
  );
};
