import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row, Form, InputGroup, FormControl } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { useStateValue } from '../../state';
import { CreateImsiDialog } from './CreateImsiDialog';
import { getPropertyFromArray, useApi, withFilters, toOptions } from '../../lib';
import {
  SpinnerPageLoading,
  ImportButtons,
  Pager,
  Table,
  showToast,
  FilterForm,
  Filter,
} from '../../components';

export const Imsis: React.SFC<RouteComponentProps> = ({ history }) => {
  const translation = 'imsis';
  const { t } = useTranslation();
  const [{ settings, administrator }, dispatch] = useStateValue();
  const [filter, setFilter] = useState<Filter[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = useState<(number | string)[]>([]);
  const customerId = administrator.customers[0] || -1; // TODO: Fixa utan -1;

  const [imsis, loadingImsis] = administrator.isRole.sysAdmin
    ? useApi(`imsis`)
    : useApi(`imsis/customers/${customerId}`);

  if (loadingImsis || !settings) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const imsiOptions = toOptions(imsis, 'imsi', false, 'imsi');
  const iccidOptions = toOptions(imsis, 'iccid', false, 'iccid');
  const customers = new Set();
  imsis.forEach(({ customer }: { customer: number }) => customers.add(customer));
  const customerOptions = [...Array.from(customers)].map((c: any) => ({
    value: c,
    label: getPropertyFromArray(settings.customers, c, 'name'),
  }));

  const getRow = ({ iccid, imsi, customer, pin1, pin2, puk1, puk2 }: any) => (
    <>
      <Col>
        <div style={{ fontWeight: 600 }}>{imsi}</div>
      </Col>
      <Col>{iccid}</Col>
      <Col>{getPropertyFromArray(settings.customers, customer, 'name')}</Col>
      <Col>
        {pin1}/{pin2}
      </Col>
      <Col className="text-right">
        {puk1}/{puk2}
      </Col>
    </>
  );

  const handleRowClick = (row: any) => {
    history.push({
      pathname: `/admin/imsis/${row.id}`,
      state: { detail: 'response.data ' },
    });
  };

  const actions = [{ value: 0, label: t(`${translation}.actions.create`) }];

  const filteredItems = withFilters(imsis, filter);

  return (
    <>
      {administrator.isRole.sysAdmin && (
        <div className="float-right mt-2">
          <CreateImsiDialog
            history={history}
            buttonLabel={t(`${translation}.createButton`)}
            disabled
            title={t(`${translation}.createNotAllowed`)}
          />
        </div>
      )}
      <h1>{t(`${translation}.title`)}</h1>

      {/* <Row>
        <Col md={8}>
          <h1>{t(`${translation}.title`)}</h1>
        </Col>
        <Col md={4}>
          <div className="d-inline-flex w-100 mt-3">
            <Select className="w-100" value={actions[0]} options={actions} />
            <div className="ml-1">
              <CreateImsiDialog history={history} />
            </div>
          </div>
        </Col>
      </Row> */}

      <FilterForm
        translation={translation}
        fields={[
          { name: 'imsi', options: imsiOptions },
          { name: 'iccid', options: iccidOptions },
          { name: 'customer', options: customerOptions },
        ]}
        filter={filter}
        setFilter={setFilter}
      />

      <Container fluid className="number-list mb-4 mt-4">
        <Table
          translation={translation}
          items={filteredItems}
          history={history}
          getRow={getRow}
          columns={['imsi', 'iccid', 'customer', ['pin1', 'pin2'], ['puk1', 'puk2']]}
          onRowClick={handleRowClick}
          defaultCompare={{ field: 'imsi', ascending: true }}
          // selected={selected}
          // setSelected={setSelected}
          activePage={activePage}
          pageSize={pageSize}
        />
      </Container>

      <Pager
        totalItemsCount={filteredItems.length}
        pageRangeDisplayed={6}
        activePage={activePage}
        setActivePage={setActivePage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};
