import React from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { reducer, StateProvider, State, initialSettings, initialAdministrator } from './state';
import { PublicRoutes, LoginRoutes, AdminRoutes, Connect, PrivateRoute } from './routes';

const App: React.FC = () => {
  const initialState: State = {
    authenticated: false,
    administrator: initialAdministrator,
    toasts: [],
    settings: initialSettings,
  };

  const ConnectRouter = withRouter(Connect);

  return (
    // <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <Router>
        <ConnectRouter />
        {/* <Connect /> */}
        <Switch>
          <Route
            exact
            path={[
              '/login',
              '/register',
              '/confirm-email/:token',
              '/forgot-password',
              '/update-password/:token',
              '/logout',
            ]}
            component={LoginRoutes}
          />
          <PrivateRoute path="/admin" component={AdminRoutes} />
          <Route component={PublicRoutes} />
        </Switch>
      </Router>
    </StateProvider>
    // </React.StrictMode>
  );
};

export default App;
