import React from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SpinnerButton, BasicFormProps, FormProps, FormMessage } from '../../components';
import { Field } from '../../components/fields';

export interface FormValues {
  email: string;
  password: string;
}

const defaultFormValues = {
  email: '',
  password: '',
};

export const LoginForm: React.SFC<BasicFormProps> = ({ translation, onSubmit }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t(`${translation}.email.invalid`))
      .email(t(`${translation}.email.emailFormat`)),
    password: Yup.string()
      .min(8, t(`${translation}.password.passwordLength`))
      .required(t(`${translation}.password.invalid`)),
  });

  const LoginFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const { handleSubmit, dirty, isSubmitting, status } = props;
    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Row>
          <Field id="email" type="email" md={12} {...props} />
          <Field id="password" type="password" md={12} {...props} />
        </Form.Row>

        <FormMessage message={status} />

        <div className="d-flex justify-content-end">
          <SpinnerButton
            disabled={!dirty}
            label={t(`${translation}.submitButton`)}
            isSubmitting={isSubmitting}
          />
        </div>
      </Form>
    );
  };

  const LoginFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => defaultFormValues,
    validationSchema,
    handleSubmit: onSubmit,
  })(LoginFormInner);

  return <LoginFormFormik translation={translation} />;
};
