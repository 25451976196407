/**
 *  Publika sidor
 */

export default {
  home: {
    title: 'TeleOffice Mobile Portal',
    text:
      'Welcome to TeleOffice Portal for mobile phones. It provides easy access and administration of your and your companies mobile phones.',
    login: 'Login',
    logout: 'Logout',
    register: 'Register',
  },
  page404: {
    title: 'Woops 404!',
    text: 'The page you requested was not found... can it be any of these pages?',
    home: 'Hom',
    login: 'Login',
    register: 'Register',
  },
  appBar: {
    logoTitle: 'TeleOffice',
    admin: 'Administration',
    logout: 'Logout',
    login: 'Login',
    loginAs: 'Login as',
    me: 'My settings',
  },
  sideBar: {
    organizations: 'Organizations',
    departments: 'Departments',
    administrators: 'Administrators',
    numbers: 'Numbers',
    settingsSection: 'Settings',
    me: 'My settings',
    version: 'Version',
    development: 'Dev',
    production: 'Prod',
  },
  footer: {
    version: 'version',
  },
  languages: {
    sv: 'Swedish',
    en: 'English',
  },
  login: {
    connecting: 'connecting ...',
    forgotPassword: 'Forgot your password?',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    rememberMe: 'Remember me',
    login: 'Log in',
    loginFailed: 'Wrong username or password.',
    sendEmail: 'Send email',
    enterYourEmail: 'Enter your email',
    readyToLogin: 'Ready to login?',
    changePassword: 'Change password',
    register: 'Register',
    company: 'Company',
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    phone: 'Phone number',
    emailValidation:
      'You have to confirm your email address. An Email with instructions have been sent to {{email}}.',
    emailPasswordRecovery:
      'An Email with password recovery instructions have been sent to {{email}}.',
  },
  validation: {
    requiered: 'Requiered',
    company: 'Your company name is requiered.',
    name: 'Name is requiered.',
    firstName: 'First name is requiered.',
    lastName: 'Last name is requiered.',
    phone: 'Phone number is requiered.',
    email: 'Email is required.',
    emailFormat: 'Invalid email.',
    password: 'Password is required.',
    passwordLength: 'The password length must be at least 8 characters!',
    confirm: 'Passwords do not match!',
  },
  public: {
    menu: 'Public page',
    title: 'Public page',
    text: 'This page is available for everybody.',
  },
  administrator: {
    title: 'Administrators',
    text: 'Info...',
    table: {
      id: 'Id',
      name: 'Name',
      organization: 'Organization',
      role: 'Role',
      lastAdminAccess: 'Last access',
    },
  },
  numbers: {
    title: 'Number',
    text: 'Info...',
    table: {
      id: 'Service id',
      product: 'Product',
      mobile: 'Mobile numbrt',
      phone: 'Phone number',
      organization: 'Organisation',
      user: 'User',
      status: 'Status',
    },
  },
  fields: {
    toggle: {
      on: 'On',
      off: 'Off',
    },
  },
  buttons: {
    save: 'Save',
    new: 'New',
    delete: 'Delete',
    cancel: 'Cancel',
    ok: 'Ok',
    yes: 'Yes',
    no: 'No',
  },
  dialogs: {
    delete: {
      title: 'Radera',
      body: 'Är du säker på att du vill radera?',
    },
  },
  me: {
    title: 'My settings',
    text: '...',
    administratorForm: {
      title: 'Administrator',
      titleOrg: 'Organization',
      titleOrganizations: 'Permissions',
      saveButton: 'Save',
      firstName: {
        label: 'First name',
        invalid: 'First name is requiered.',
        valid: '',
        info: '',
      },
      lastName: {
        label: 'Last name',
        invalid: 'Last name is requiered.',
        valid: '',
        info: '',
      },
      description: {
        label: 'Title/description',
        invalid: '',
        valid: '',
        info: '',
      },
      email: {
        label: 'Email',
        invalid: 'Invalid email',
        valid: '',
        info: 'Email have to be reconfirmed if changed.',
      },
      phone: {
        label: 'Phone',
        invalid: '',
        valid: '',
        info: '',
      },
      language: {
        label: 'Language',
        invalid: '',
        valid: '',
        info: '',
      },
      organization: {
        label: 'Organization',
        invalid: '',
        valid: '',
        info: '',
      },
      department: {
        label: 'Department',
        invalid: '',
        valid: '',
        info: '',
      },
    },
  },
};
