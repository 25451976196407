import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row, Form, InputGroup, FormControl } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { useStateValue, Customer } from '../../state';
import { CreateCustomerDialog } from './CreateCustomerDialog';
import { useApi, withFilters, toOptions, getCustomersFromMeta } from '../../lib';

const withCustomFilters = (arr: any, filter: Filter[]) =>
  arr.filter((f: any) =>
    filter.every(({ name, value }) => {
      if (name === 'email') {
        return [f.emailContact1, f.emailContact2, f.emailTechContact1].includes(value);
      } else if (name === 'phone') {
        return [f.phoneNumberContact1, f.phoneNumberContact2, f.phoneNumberTechContact1].includes(
          value
        );
      } else {
        return Array.isArray(f[name]) ? f[name].includes(value) : f[name] === value;
      }
    })
  );

import {
  SpinnerPageLoading,
  ImportButtons,
  Pager,
  Table,
  showToast,
  FilterForm,
  Filter,
} from '../../components';

export const Customers: React.SFC<RouteComponentProps> = ({ history }) => {
  const translation = 'customers';
  const { t } = useTranslation();
  const [{ settings, administrator }, dispatch] = useStateValue();
  const [filter, setFilter] = useState<Filter[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = useState<(number | string)[]>([]);

  const [customers, loadingCustomers] = administrator.isRole.sysAdmin
    ? useApi('customers')
    : getCustomersFromMeta(administrator);

  if (loadingCustomers || !settings) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  // Filter options
  const customerOptions = toOptions(customers, 'name', false, 'name');
  const orgNumberOptions = toOptions(customers, 'orgNumber', false, 'orgNumber');
  const postAddressOptions = toOptions(customers, 'postAddress', false, 'postAddress');
  const emails = new Set();
  customers.forEach(({ emailContact1, emailContact2, emailTechContact1 }: Customer) =>
    [emailContact1, emailContact2, emailTechContact1]
      .filter((val) => val !== '')
      .forEach(emails.add, emails)
  );
  const emailOptions = [...Array.from(emails)].map((v) => ({ value: v, label: v }));
  const phoneNumbers = new Set();
  customers.forEach(
    ({ phoneNumberContact1, phoneNumberContact2, phoneNumberTechContact1 }: Customer) =>
      [phoneNumberContact1, phoneNumberContact2, phoneNumberTechContact1]
        .filter((val) => val !== '')
        .forEach(phoneNumbers.add, phoneNumbers)
  );
  const phoneNumberOptions = [...Array.from(phoneNumbers)].map((v) => ({ value: v, label: v }));

  const getRow = ({
    name,
    address,
    postAddress,
    postCode,
    orgNumber,
    emailContact1,
    phoneNumberContact1,
    emailContact2,
    phoneNumberContact2,
    emailTechContact1,
    phoneNumberTechContact1,
  }: any) => (
    <>
      <Col>
        <div style={{ fontWeight: 600 }}>{name}</div>
        <div>{orgNumber}</div>
      </Col>
      <Col>
        <div>{address}</div>
        <div>
          {postAddress} {postCode}
        </div>
      </Col>
      <Col>
        <div>{emailContact1}</div>
        <div>{phoneNumberContact1}</div>
      </Col>
      <Col>
        <div>{emailContact2}</div>
        <div>{phoneNumberContact2}</div>
      </Col>
      <Col className="text-right">
        <div>{emailTechContact1}</div>
        <div>{phoneNumberTechContact1}</div>
      </Col>
    </>
  );

  const handleRowClick = (row: any) => {
    history.push({
      pathname: `/admin/customers/${row.id}`,
      state: { detail: 'response.data ' },
    });
  };

  const actions = [{ value: 0, label: t(`${translation}.actions.create`) }];

  const filteredItems = withCustomFilters(customers, filter);

  return (
    <>
      {administrator.isRole.sysAdmin && (
        <div className="float-right mt-2">
          <CreateCustomerDialog history={history} buttonLabel={t(`${translation}.createButton`)} />
        </div>
      )}
      <h1>{t(`${translation}.title`)}</h1>

      <FilterForm
        translation={translation}
        fields={[
          { name: 'name', options: customerOptions },
          { name: 'orgNumber', options: orgNumberOptions },
          { name: 'postAddress', options: postAddressOptions },
          { name: 'email', options: emailOptions },
          { name: 'phone', options: phoneNumberOptions },
        ]}
        filter={filter}
        setFilter={setFilter}
      />

      <Container fluid className="number-list mb-4 mt-4">
        <Table
          translation={translation}
          items={filteredItems}
          history={history}
          getRow={getRow}
          columns={[
            ['name', 'orgNumber'],
            ['address', 'postAddress', 'postCode'],
            ['emailContact1', 'phoneNumberContact1'],
            ['emailContact2', 'phoneNumberContact2'],
            ['emailTechContact1', 'phoneNumberTechContact1'],
          ]}
          onRowClick={handleRowClick}
          defaultCompare={{ field: 'firstName', ascending: true }}
          // selected={selected}
          // setSelected={setSelected}
          activePage={activePage}
          pageSize={pageSize}
        />
      </Container>

      <Pager
        totalItemsCount={filteredItems.length}
        pageRangeDisplayed={6}
        activePage={activePage}
        setActivePage={setActivePage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};
