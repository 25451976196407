import React from 'react';
import { Nav, Navbar, Dropdown, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { LogOut, Settings } from 'react-feather';
import logo from '../images/logo.png';
import { useStateValue } from '../state';
import { SelectLanguage } from '../components';

interface AppBarProps {
  loginPage?: boolean;
  admin?: boolean;
}

export const AppBar: React.SFC<AppBarProps> = ({ loginPage, admin }) => {
  const [{ authenticated, administrator }] = useStateValue();
  const { t } = useTranslation();

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      // eslint-disable-next-line no-nested-ternary
      bg={loginPage ? 'light' : admin ? 'dark' : ''}
      variant={admin ? 'dark' : 'light'}
      // fixed="top"
    >
      <LinkContainer to="/">
        <Navbar.Brand as={Nav.Link}>
          <img alt="logo" src={logo} width="48" height="27" className="d-inline-block align-top" />{' '}
          {t('appBar.logoTitle')}
        </Navbar.Brand>
      </LinkContainer>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {/* {!authenticated && (
            <>
              <LinkContainer to="/public">
                <Nav.Link>{t('public.menu')}</Nav.Link>
              </LinkContainer>
            </>
          )} */}
          {authenticated && !admin && (
            <>
              <LinkContainer to="/admin">
                <Nav.Link>{t('appBar.admin')}</Nav.Link>
              </LinkContainer>
              {/* <LinkContainer to="/dbtest">
                <Nav.Link>Databastest</Nav.Link>
              </LinkContainer> */}
            </>
          )}
        </Nav>
        <Nav activeKey="/">
          {authenticated && (
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} id="login-dropdown">
                {t('appBar.loginAs')}{' '}
                <span className="text-success">{administrator.firstName}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <LinkContainer to="/admin/administrators/me">
                  <Dropdown.Item>
                    <div>
                      <Settings />
                      {t('appBar.me')}
                    </div>
                  </Dropdown.Item>
                </LinkContainer>
                {/* <NavDropdown.Divider />
                <Dropdown.Item onClick={() => showAppAlert(exampleAlert)}>Do stuff</Dropdown.Item> */}
                <NavDropdown.Divider />
                <LinkContainer to="/logout">
                  <Dropdown.Item>
                    <span>
                      <LogOut />
                      {t('appBar.logout')}
                    </span>
                  </Dropdown.Item>
                </LinkContainer>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {!authenticated && <SelectLanguage />}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
