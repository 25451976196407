import { State, Action, Administrator, Settings } from './state';
import { Toast, deleteToast } from '../components';

/**
 * Actions
 */
const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const setIsAuthenticated = (isAuthenticated: boolean): Action => ({
  type: SET_AUTHENTICATED,
  payload: isAuthenticated,
});

const SET_ADMINISTRATOR = 'SET_ADMINISTRATOR';
export const setAdministrator = (administrator: Administrator | null): Action => ({
  type: SET_ADMINISTRATOR,
  payload: administrator,
});

const SET_SETTINGS = 'SET_SETTINGS';
export const setSettings = (settings: Settings): Action => ({
  type: SET_SETTINGS,
  payload: settings,
});

const ADD_TOAST = 'ADD_TOAST';
export const addToast = (toast: Toast): Action => ({
  type: ADD_TOAST,
  payload: toast,
});

const DEL_TOAST = 'DEL_TOAST';
export const delToast = (id: string): Action => ({
  type: DEL_TOAST,
  payload: id,
});

/**
 * Reducer
 */
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload,
      };
    case SET_ADMINISTRATOR:
      return {
        ...state,
        administrator: action.payload,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case DEL_TOAST:
      return {
        ...state,
        toasts: deleteToast(state.toasts, action.payload),
      };
    default:
      return state;
  }
};
