import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, Prompt } from 'react-router-dom';
import { FunctionForm, FormValues } from './FunctionForm';
import { api, logger, getOptionValues, useApi } from '../../lib';
import { FormikHelpers } from 'formik';
import { useStateValue } from '../../state';
import { showToast } from '../../components';

export const Function: React.SFC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
  history,
}) => {
  const { id } = params;
  const translation = 'function';
  const { t } = useTranslation();
  const [, dispatch] = useStateValue();

  const handleSubmit = async (update: FormValues, props: FormikHelpers<FormValues>) => {
    const { setSubmitting, resetForm } = props;

    try {
      const { changedFields, ...values } = update;

      // Requiered to use initialValues to detect changes.
      resetForm({ values });
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Function (save)', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <h1>{t(`${translation}.title`)}</h1>
      <p>{t(`${translation}.text`)}</p>
      <FunctionForm translation={`${translation}.form`} onSubmit={handleSubmit} id={id} />
    </>
  );
};
