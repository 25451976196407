import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { Smartphone, Briefcase, Tv, Layers, Users, Hash, Settings, Sliders } from 'react-feather';
import { CollapseButton } from '../components';
import { useStateValue } from '../state';

export const SideBar: React.SFC = () => {
  const { t } = useTranslation();
  const [{ settingsCollapsed }, collapseSettings] = useState({ settingsCollapsed: true });
  const [
    {
      administrator: { isRole },
      settings: { buildInfo },
    },
  ] = useStateValue();

  const { buildTag, buildTime, commitTime } = buildInfo; // Api
  const { REACT_APP_BUILD_TAG, REACT_APP_BUILD_TIME, REACT_APP_COMMITTIME } = process.env; // Frontend

  return (
    <div className="side-bar h-100 pt-2 d-flex flex-column justify-content-between border-right">
      <nav className="flex-column bg-light">
        {/* Main links */}
        {(isRole.sysAdmin || isRole.custAdmin) && (
          <LinkContainer to="/admin/customers">
            <Nav.Link>
              <Briefcase />
              {t('sideBar.customers')}
            </Nav.Link>
          </LinkContainer>
        )}
        {(isRole.sysAdmin || isRole.custAdmin || isRole.depAdmin) && (
          <LinkContainer to="/admin/departments">
            <Nav.Link>
              <Layers />
              {t('sideBar.departments')}
            </Nav.Link>
          </LinkContainer>
        )}
        <LinkContainer to="/admin/administrators">
          <Nav.Link>
            <Users />
            {t('sideBar.administrators')}
          </Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/mobiles">
          <Nav.Link>
            <Smartphone />
            {t('sideBar.mobiles')}
          </Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/imsis">
          <Nav.Link>
            <Hash />
            {t('sideBar.imsis')}
          </Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/subscriptions">
          <Nav.Link>
            <Tv />
            {t('sideBar.subscriptions')}
          </Nav.Link>
        </LinkContainer>

        {/* Settings section */}
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          {' '}
          <span>{t('sideBar.settingsSection')}</span>
          <CollapseButton
            collapsed={settingsCollapsed}
            onClick={() => collapseSettings({ settingsCollapsed: !settingsCollapsed })}
          />
        </h6>
        {settingsCollapsed && (
          <>
            <LinkContainer to="/admin/administrators/me">
              <Nav.Link>
                <Settings />
                {t('sideBar.me')}
              </Nav.Link>
            </LinkContainer>
            {isRole.sysAdmin && (
              <LinkContainer to="/admin/functions/me">
                <Nav.Link>
                  <Tv />
                  {t('sideBar.functions')}
                </Nav.Link>
              </LinkContainer>
            )}
          </>
        )}
      </nav>

      <div className="m-1 text-muted text-center">
        <a
          className="small"
          title={REACT_APP_COMMITTIME || REACT_APP_BUILD_TIME}
          href={`https://gitlab.com/teleoffice/mvno/mvno.frontend/-/tags/${REACT_APP_BUILD_TAG}`}
        >
          gui, {REACT_APP_BUILD_TAG}
        </a>
        /
        <a
          className="small"
          title={commitTime || buildTime}
          href={`https://gitlab.com/teleoffice/mvno/mvno.frontend/-/tags/${buildTag}`}
        >
          api, {buildTag}
        </a>
      </div>
    </div>
  );
};
