import React from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import svFlag from '../images/flag-sv.svg'; // https://github.com/lipis/flag-icon-css/tree/master/flags
import gbFlag from '../images/flag-gb.svg';
import { changeLanguage } from '../locales/i18n';

export const SelectLanguage: React.SFC = () => {
  const { t } = useTranslation();

  const flag =
    localStorage.getItem('language') === 'en' ? (
      <img width="30" src={gbFlag} alt="English" className="flag" />
    ) : (
      <img width="30" src={svFlag} alt="Svenska" className="flag" />
    );

  return (
    <Dropdown as={Nav.Item} className="select-language">
      <Dropdown.Toggle as={Nav.Link} id="select-language">
        {flag}
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        <Dropdown.Item onClick={() => changeLanguage('sv')}>{t('languages.sv')}</Dropdown.Item>
        {/* <Dropdown.Item onClick={() => changeLanguage('en')}>{t('languages.en')}</Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};
