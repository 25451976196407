import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
  useStateValue,
  setAdministrator,
  setIsAuthenticated,
  initialAdministrator,
} from '../state';
import { api, logger } from '../lib';

export const Logout: React.SFC<RouteComponentProps> = ({ location }) => {
  const [{ administrator }, dispatch] = useStateValue();

  api.clearToken();
  logger('Logout.js', { email: administrator.email });
  dispatch(setIsAuthenticated(false));
  dispatch(setAdministrator(initialAdministrator));

  return (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
};
