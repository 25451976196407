import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { History } from 'history';
import { Field, Option, optionSchemaShape } from '../../components/fields';
import { useStateValue } from '../../state';
import { SpinnerButton, showToast, FormProps } from '../../components';
import {
  api,
  logger,
  toOptions,
  useApi,
  getOptionValues,
  validateMobileNumber,
  validateFixedPhoneNumber,
} from '../../lib';

export interface DialogValues {
  iccid: Option | undefined;
  mobileNumber: string;
  fixedNumber: string;
  status: Option | undefined;
  customer: Option | undefined;
  port: boolean;
  portDate: string;
  portTime: string;
}

interface CreateMobileDialogProps {
  history?: History;
  buttonLabel?: string;
  dialogOnCreate?: any;
  customer?: Option;
  customerReadOnly?: Boolean;
  customers: any;
}

export const CreateMobileDialog: React.SFC<CreateMobileDialogProps> = ({
  history,
  buttonLabel,
  dialogOnCreate,
  customer,
  customerReadOnly,
  customers,
}) => {
  const translation = 'dialogs.createMobile';
  const { t } = useTranslation();
  const [{ settings }, dispatch] = useStateValue();
  const [show, setShow] = useState(false);

  const defaultValues = {
    mobileNumber: '',
    fixedNumber: '',
    iccid: undefined,
    status: undefined,
    customer,
    port: false,
    portDate: '',
    portTime: '',
  };

  const validationSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required(t(`${translation}.mobileNumber.missing`))
      .test('check-format', t(`${translation}.mobileNumber.invalid`), (value) =>
        validateMobileNumber(value || '')
      ),
    fixedNumber: Yup.string().when('customer', {
      is: (customer: any) => !!customer,
      then: Yup.string()
        .required(t(`${translation}.fixedNumber.missing`))
        .test('check-format', t(`${translation}.fixedNumber.invalid`), (value) =>
          validateFixedPhoneNumber(value || '')
        ),
    }),
    customer: Yup.object()
      .nullable()
      .when('port', {
        is: true,
        then: Yup.object()
          .shape(optionSchemaShape)
          .nullable()
          .required(t(`${translation}.customer.invalid`)),
      }),
  });

  const handleSubmit = async (
    values: DialogValues,
    { setSubmitting }: FormikHelpers<DialogValues>
  ) => {
    try {
      const portTime = ({ port, portTime, portDate }: any) =>
        port && portTime && portDate ? `${values.portDate} ${values.portTime}` : '';

      const updateValues = {
        ...values,
        customer: getOptionValues(values.customer),
        status: values.port ? 2 : 1,
        portTime: portTime(values),
      };

      const { id } = (await api.createEntry('mobiles', updateValues)) as { id: number };

      setShow(false);
      if (dialogOnCreate) {
        dialogOnCreate('createMobile', { ...updateValues, id, values });
      } else if (history) {
        // TODO: Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
        history.push(`/admin/mobiles/${id}`);
      }
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Mobile (create)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const MobileFormInner = (props: FormProps & FormikProps<DialogValues>) => {
    const { handleSubmit, dirty, isSubmitting, values } = props;

    const updateDependentFields = (option: any, name: any, setFieldValue: any) => {
      // INFO: Validation not cleared.
      if (name === 'customer' && option === null) {
        setFieldValue('fixedNumber', '');
      }
    };

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Field
              {...props}
              id="customer"
              type="reactSelect2"
              options={toOptions(customers)}
              isClearable
              isDisabled={customerReadOnly}
              onFieldChange={updateDependentFields}
              md={4}
            />
            <Field {...props} id="mobileNumber" type="text" md={4} />
            <Field {...props} id="fixedNumber" type="text" md={4} disabled={!values.customer} />
            <Field {...props} id="portDate" disabled={!values.port} type="date" md={4} />
            <Field {...props} id="portTime" disabled={!values.port} type="time" md={4} />
            <Field {...props} id="port" type="checkbox" md={4} />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t('buttons.cancel')}
          </Button>
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </Modal.Footer>
      </Form>
    );
  };

  const MobileFormFormik = withFormik<FormProps, DialogValues>({
    mapPropsToValues: () => defaultValues,
    validationSchema,
    handleSubmit,
  })(MobileFormInner);

  return (
    <>
      <SpinnerButton
        onClick={() => setShow(!show)}
        className={dialogOnCreate ? 'btn-inline' : ''}
        type="button"
        label={buttonLabel || t('buttons.plus')}
      />
      {show && (
        <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${translation}.title`)}</Modal.Title>
          </Modal.Header>
          <MobileFormFormik translation={translation} />
        </Modal>
      )}
    </>
  );
};
