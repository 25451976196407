import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import * as Cookies from 'js-cookie';
import * as qs from 'qs';
import { Administrator } from '../state';

const debug = require('debug')('mvno:api');

export interface Authentication {
  profile: Administrator;
  token: string;
}

export interface CookieConfig {
  key: string;
  options: object;
}

export interface LocalStorageConfig {
  key: string;
}

export interface StoreConfig {
  cookie?: CookieConfig | false;
  localStorage?: LocalStorageConfig | false;
}

export default class Api {
  public axios: AxiosInstance;

  public storeConfig: StoreConfig;

  /**
   * Default constructor.
   * @param baseURL Your Strapi host.
   * @param axiosConfig Extend Axios configuration.
   */
  constructor(baseURL: string, storeConfig?: StoreConfig, requestConfig?: AxiosRequestConfig) {
    this.axios = axios.create({
      baseURL,
      paramsSerializer: qs.stringify,
      ...requestConfig,
    });
    this.storeConfig = {
      cookie: {
        key: 'jwt',
        options: {
          path: '/',
        },
      },
      localStorage: {
        key: 'jwt',
      },
      ...storeConfig,
    };

    if (this.isBrowser()) {
      let existingToken;
      if (this.storeConfig.cookie) {
        existingToken = Cookies.get(this.storeConfig.cookie.key);
      } else if (this.storeConfig.localStorage) {
        existingToken = JSON.parse(
          window.localStorage.getItem(this.storeConfig.localStorage.key) as string
        );
      }
      if (existingToken) {
        this.setToken(existingToken, true);
      }
    }
  }

  /**
   * Axios request
   * @param method Request method
   * @param url Server URL
   * @param requestConfig Custom Axios config
   */
  public async request(
    method: Method,
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<any> {
    try {
      const response: AxiosResponse = await this.axios.request({
        method,
        url,
        ...requestConfig,
      });
      debug(method, url, response.data, requestConfig);
      return response.data;
    } catch (error) {
      throw error; // new Error(error.response.data.error);
    }
  }

  /**
   * Register a new user.
   * @param email
   * @param password
   * @param firstName
   * @param lastName
   * @returns Authentication User token and profile
   */
  public async register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    language: string,
    url: string,
    login: string
  ): Promise<Authentication> {
    this.clearToken();
    const authentication: Authentication = await this.request('post', '/auth/register', {
      data: {
        email,
        password,
        firstName,
        lastName,
        language,
        url,
        login,
      },
    });
    this.setToken(authentication.token);
    return authentication;
  }

  /**
   * Login by getting an authentication token.
   * @param email Can either be an email or a username.
   * @param password
   * @returns Authentication User token and profile
   */
  public async login(email: string, password: string): Promise<Authentication> {
    this.clearToken();
    const authentication: Authentication = await this.request('post', '/auth/login', {
      data: {
        email,
        password,
      },
    });
    this.setToken(authentication.token);
    return authentication;
  }

  /**
   * Sends an email to a user with the link of your reset password page.
   * This link contains an URL param code which is required to reset user password.
   * Received link url format https://my-domain.com/rest-password?code=privateCode.
   * @param email
   * @param url Link that user will receive.
   */
  public async forgotPassword(email: string, url: string): Promise<void> {
    this.clearToken();
    await this.request('post', '/auth/reset-password-request', {
      data: {
        email,
        url,
      },
    });
  }

  /**
   * Updates the user password.
   * @param token Is the url params received from the email link (see forgot password).
   * @param password
   * @param passwordConfirmation
   */
  public async updatePassword(
    token: string,
    password: string,
    passwordConfirmation: string
  ): Promise<void> {
    this.clearToken();
    await this.request('patch', '/auth/update-password', {
      data: {
        token,
        password,
        passwordConfirmation,
      },
    });
  }

  /**
   * Sends an email to a user with a mail varification link.
   * @param id
   * @param email
   * @param url
   * @param login
   */
  public async verifyEmailAddress(
    id: string,
    email: string,
    url: string,
    login: string
  ): Promise<void> {
    await this.request('post', '/auth/confirm-email-request', {
      data: {
        type: 'email',
        id,
        email,
        url,
        login,
      },
    });
  }

  /**
   * List entries
   * @param contentTypePluralized
   * @param params Filter and order queries.
   */
  public getEntries(
    contentTypePluralized: string,
    params?: AxiosRequestConfig['params']
  ): Promise<object[]> {
    return this.request('get', `/${contentTypePluralized}`, {
      params,
    });
  }

  /**
   * Get the total count of entries with the provided criteria
   * @param contentType
   * @param params Filter and order queries.
   */
  public getEntryCount(
    contentType: string,
    params?: AxiosRequestConfig['params']
  ): Promise<object[]> {
    return this.request('get', `/${contentType}/count`, {
      params,
    });
  }

  /**
   * Get a specific entry
   * @param contentTypePluralized Type of entry pluralized
   * @param id ID of entry
   */
  public getEntry(contentTypePluralized: string, id: string): Promise<object> {
    return this.request('get', `/${contentTypePluralized}/${id}`);
  }

  /**
   * Create data
   * @param contentTypePluralized Type of entry pluralized
   * @param data New entry
   */
  public createEntry(
    contentTypePluralized: string,
    data: AxiosRequestConfig['data']
  ): Promise<object> {
    return this.request('post', `/${contentTypePluralized}`, {
      data,
    });
  }

  /**
   * Replace data
   * @param contentTypePluralized Type of entry pluralized
   * @param id ID of entry
   * @param data
   */
  public replaceEntry(
    contentTypePluralized: string,
    id: string,
    data: AxiosRequestConfig['data']
  ): Promise<object> {
    return this.request('put', `/${contentTypePluralized}/${id}`, {
      data,
    });
  }

  /**
   * Update data
   * @param contentTypePluralized Type of entry pluralized
   * @param id ID of entry
   * @param data
   */
  public updateEntry(
    contentTypePluralized: string,
    id: string,
    data: AxiosRequestConfig['data']
  ): Promise<object> {
    return this.request('patch', `/${contentTypePluralized}/${id}`, {
      data,
    });
  }

  /**
   * Delete an entry
   * @param contentTypePluralized Type of entry pluralized
   * @param id ID of entry
   */
  public deleteEntry(contentTypePluralized: string, id: string): Promise<object> {
    return this.request('delete', `/${contentTypePluralized}/${id}`);
  }

  /**
   * Set token on Axios configuration
   * @param token Retrieved by register or login
   */
  public setToken(token: string, comesFromStorage?: boolean): void {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    if (this.isBrowser() && !comesFromStorage) {
      if (this.storeConfig.localStorage) {
        window.localStorage.setItem(this.storeConfig.localStorage.key, JSON.stringify(token));
      }
      if (this.storeConfig.cookie) {
        Cookies.set(this.storeConfig.cookie.key, token, this.storeConfig.cookie.options);
      }
    }
  }

  /**
   * Remove token from Axios configuration
   */
  public clearToken(): void {
    delete this.axios.defaults.headers.common.Authorization;
    if (this.isBrowser()) {
      if (this.storeConfig.localStorage) {
        window.localStorage.removeItem(this.storeConfig.localStorage.key);
      }
      if (this.storeConfig.cookie) {
        Cookies.remove(this.storeConfig.cookie.key, this.storeConfig.cookie.options);
      }
    }
  }

  /**
   * Check if it runs on browser
   */
  private isBrowser(): boolean {
    return typeof window !== 'undefined';
  }
}

if (!process.env.REACT_APP_API_PATH) {
  throw new Error('process.env.REACT_APP_API_PATH is missing!');
}

export const api = new Api(process.env.REACT_APP_API_PATH, {
  localStorage: false,
});
