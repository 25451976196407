import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

import sv from './sv/translation';
import en from './en/translation';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: en,
      },
      sv: {
        translation: sv,
      },
    },
    lng: 'sv',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // react: {
    //   useSuspense: false, // https://github.com/i18next/react-i18next/issues/766
    // },
  });

export const changeLanguage = (language: string) => {
  localStorage.setItem('language', language);
  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }
};

export default i18n;
