import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Option } from './fields';

export interface Filter {
  [key: string]: any;
}

interface Field {
  name: string;
  options: Option[];
}

interface FilterFormProps {
  translation: string;
  fields: Field[];
  // onChange?: () => {};
  filter: Filter[];
  setFilter: (filter: Filter[]) => void;
}

export const FilterForm: React.SFC<FilterFormProps> = ({
  translation,
  filter,
  setFilter,
  fields /* , onChange */,
}) => {
  // const [filter, setFilter] = useState<Filter[]>([]);
  const { t } = useTranslation();
  const trans = `${translation}.filters`;

  const handleFilterChange = (option: any, { name }: any) => {
    const newFilter = [...filter];
    const index = filter.findIndex((f) => f.name === name);
    if (!option && index > -1) {
      newFilter.splice(
        filter.findIndex((f) => f.name === name),
        1
      );
    } else if (index > -1) {
      newFilter.splice(
        filter.findIndex((f) => f.name === name),
        1,
        { name, value: option.value }
      );
    } else {
      newFilter.push({ name, value: option.value });
    }
    // if (typeof onChange === 'function') {

    // }
    setFilter(newFilter);
  };

  return (
    <Form>
      {/* <h4>Filter</h4> */}
      <Form.Row>
        {fields.map(({ name, options }: { name: string; options: any }) => (
          <Form.Group as={Col} key={name}>
            <Form.Label>{t(`${trans}.${name}`)}</Form.Label>
            <Select
              name={name}
              onChange={handleFilterChange}
              options={options && options.filter((option: any) => option.value && option.label)}
              isClearable
            />
          </Form.Group>
        ))}
      </Form.Row>
    </Form>
  );
};
