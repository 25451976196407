import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row, Form, InputGroup, FormControl } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { useStateValue, Customer, Department } from '../../state';
import { CreateDepartmentDialog } from './CreateDepartmentDialog';
import { useApi, withFilters, toOptions, onlyUniqueObjects, toOption } from '../../lib';
import {
  SpinnerPageLoading,
  ImportButtons,
  Pager,
  Table,
  defaultSort,
  showToast,
  FilterForm,
  Filter,
} from '../../components';

export const Departments: React.SFC<RouteComponentProps> = ({ history }) => {
  const translation = 'departments';
  const { t } = useTranslation();
  const [{ settings, administrator }, dispatch] = useStateValue();
  const [filter, setFilter] = useState<Filter[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = useState<(number | string)[]>([]);
  const customerId = administrator.customers[0] || -1;

  const [rawDepartments, loadingDepartments] = administrator.isRole.sysAdmin
    ? useApi('departments')
    : useApi(`departments/customers/${customerId}`);

  if (loadingDepartments || !settings) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const customers = onlyUniqueObjects(rawDepartments.map((v: any) => v.customer));

  const departments = rawDepartments.map((department: any) => ({
    ...department,
    customer: department.customer && department.customer.name,
  }));

  // Filter options
  const departmentOptions = toOptions(departments, 'name', false, 'name');
  const customerOptions = toOptions(customers, 'name', false, 'name');
  const kamailios = new Set();
  departments.forEach(({ kamailio }: { kamailio: number }) => kamailios.add(kamailio));
  const kamailioOptions = [...Array.from(kamailios)].map((val) => ({ value: val, label: val }));

  const getRow = ({ name, customer, description, kamailio }: any) => (
    <>
      <Col>
        <div style={{ fontWeight: 600 }}>{name}</div>
      </Col>
      <Col>{customer}</Col>
      <Col>{description}</Col>
      <Col className="text-right">{kamailio}</Col>
    </>
  );

  const handleRowClick = (row: any) => {
    history.push({
      pathname: `/admin/departments/${row.id}`,
      state: { detail: 'response.data ' },
    });
  };

  const actions = [{ value: 0, label: t(`${translation}.actions.create`) }];

  const filteredItems = withFilters(departments, filter);

  return (
    <>
      {(administrator.isRole.sysAdmin || administrator.isRole.custAdmin) && (
        <div className="float-right mt-2">
          <CreateDepartmentDialog
            history={history}
            buttonLabel={t(`${translation}.createButton`)}
            customer={customers.length === 1 && toOption(customers[0])}
            customers={customers}
          />
        </div>
      )}
      <h1>{t(`${translation}.title`)}</h1>

      <FilterForm
        translation={translation}
        fields={[
          { name: 'name', options: departmentOptions },
          { name: 'customer', options: customerOptions },
          { name: 'kamailio', options: kamailioOptions },
        ]}
        filter={filter}
        setFilter={setFilter}
      />

      <Container fluid className="number-list mb-4 mt-4">
        <Table
          translation={translation}
          items={filteredItems}
          history={history}
          getRow={getRow}
          columns={['name', 'customer', 'description', 'kamailio']}
          onRowClick={handleRowClick}
          defaultCompare={{ field: 'name', ascending: true }}
          // selected={selected}
          // setSelected={setSelected}
          activePage={activePage}
          pageSize={pageSize}
        />
      </Container>

      <Pager
        totalItemsCount={filteredItems.length}
        pageRangeDisplayed={6}
        activePage={activePage}
        setActivePage={setActivePage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};
