import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { useStateValue, Mobile } from '../../state';
import {
  getPropertyFromArray,
  toOptions,
  useApi,
  withFilters,
  getOption,
  onlyUniqueObjects,
  toOption,
} from '../../lib';
import { CreateMobileDialog } from './CreateMobileDialog';
import {
  StatusIcon,
  SpinnerPageLoading,
  Pager,
  Table,
  defaultSort,
  FilterForm,
  Filter,
} from '../../components';

export const Mobiles: React.SFC<RouteComponentProps> = ({ history }) => {
  const translation = 'mobiles';
  const { t } = useTranslation();
  const [{ settings, administrator }] = useStateValue();
  const [filter, setFilter] = useState<Filter[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const customerId = administrator.customers[0] || -1;

  const [mobiles, loadingMobiles] = administrator.isRole.sysAdmin
    ? useApi(`mobiles`)
    : useApi(`mobiles/customers/${customerId}`);

  if (!administrator || !settings || loadingMobiles /* || loadingImsis */) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const customers = onlyUniqueObjects(mobiles, 'customer').map(
    (m: Mobile) => settings.customers && settings.customers.find((c: any) => c.id === m.customer)
  );

  // console.log('customers', customers);

  // Field options
  const mobileNumberOptions = toOptions(mobiles, 'mobileNumber', false, 'mobileNumber');
  const fixedNumberOptions = toOptions(mobiles, 'fixedNumber', false, 'fixedNumber');
  const customerOptions = toOptions(customers);

  const getRow = ({ mobileNumber, fixedNumber, customer, status, portTime, imsi }: any) => (
    <>
      <Col>
        <div style={{ fontWeight: 600 }}>{mobileNumber}</div>
      </Col>
      <Col>{fixedNumber}</Col>
      <Col>{getPropertyFromArray(settings.customers, customer, 'name')}</Col>
      {/* <Col>{toLocaleDateTimeString(portTime)}</Col> */}
      <Col className="text-right">
        <StatusIcon status={getOption(settings.mobileStatus, status)} />
      </Col>
      {/* <Col className="text-right">{getPropertyFromArray(imsis, imsi, 'iccid')}</Col> */}
    </>
  );

  const handleCompare = ({ field, ascending }: any) => (a: any, b: any) => {
    let fieldA = a[field];
    let fieldB = b[field];

    if (field === 'customer') {
      fieldA = getPropertyFromArray(settings.customers, a.customer, 'name');
      fieldB = getPropertyFromArray(settings.customers, b.customer, 'name');
    }

    return defaultSort(fieldA, fieldB, ascending);
  };

  const handleRowClick = (row: any) => {
    history.push({
      pathname: `/admin/mobiles/${row.id}`,
      state: { detail: 'response.data ' },
    });
  };

  const filteredItems = withFilters(mobiles, filter);

  return (
    <>
      {(administrator.isRole.sysAdmin || administrator.isRole.custAdmin) && (
        <div className="float-right mt-2">
          <CreateMobileDialog
            history={history}
            buttonLabel={t(`${translation}.createButton`)}
            customers={customers}
            customer={customers.length === 1 && toOption(customers[0])}
          />
        </div>
      )}
      <h1>{t(`${translation}.title`)}</h1>

      <FilterForm
        translation={translation}
        fields={[
          { name: 'mobileNumber', options: mobileNumberOptions },
          { name: 'fixedNumber', options: fixedNumberOptions },
          { name: 'customer', options: customerOptions },
          { name: 'status', options: settings.mobileStatus },
        ]}
        filter={filter}
        setFilter={setFilter}
      />

      <Container fluid className="number-list mb-4 mt-4">
        <Table
          translation={translation}
          items={filteredItems}
          history={history}
          getRow={getRow}
          columns={[
            'mobileNumber',
            'fixedNumber',
            'customer',
            // 'portTime',
            'status',
            // 'imsi'
          ]}
          onRowClick={handleRowClick}
          onCompare={handleCompare}
          defaultCompare={{ field: 'firstName', ascending: true }}
          activePage={activePage}
          pageSize={pageSize}
        />
      </Container>

      <Pager
        totalItemsCount={filteredItems.length}
        pageRangeDisplayed={6}
        activePage={activePage}
        setActivePage={setActivePage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};
