import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Img404 from '../images/404.png';

export const Page404: React.SFC = () => {
  const { t } = useTranslation();

  return (
    <div className="buttons text-center d-sm-flex justify-content-end ">
      <div className="align-self-center">
        <h1 className="display-2">{t('page404.title')}</h1>
        <div className="mt-4 mb-4">{t('page404.text')}</div>
        <div>
          <Link to="/">{t('page404.home')}</Link>
        </div>
        <div>
          <Link to="login">{t('page404.login')}</Link>
        </div>
        <div>
          <Link to="register">{t('page404.register')}</Link>
        </div>
      </div>
      <div className="mt-4">
        <img width="100%" src={Img404} alt="404" />
      </div>
    </div>
  );
};
