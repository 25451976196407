import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { History } from 'history';
import { Field } from '../../components/fields';
import { useStateValue } from '../../state';
import { SpinnerButton, showToast, FormProps } from '../../components';
import { api, logger, validateOrganizationNumber } from '../../lib';

export interface DialogValues {
  name: string;
  address: string;
  postAddress: string;
  postCode: string;
  orgNumber: string;
  emailContact1: string;
  phoneNumberContact1: string;
  emailContact2: string;
  phoneNumberContact2: string;
  emailTechContact1: string;
  phoneNumberTechContact1: string;
  department: string;
  kamailio: number;
}

interface CreateCustomerDialogProps {
  history?: History;
  buttonLabel?: string;
  dialogOnCreate?: any;
}

export const CreateCustomerDialog: React.SFC<CreateCustomerDialogProps> = ({
  history,
  buttonLabel,
  dialogOnCreate,
}) => {
  const translation = 'dialogs.createCustomer';
  const { t } = useTranslation();
  const [{ settings }, dispatch] = useStateValue();
  // const [values, setValues] = useState<DialogValues>(defaultValues);
  const [show, setShow] = useState(false);

  const defaultValues = {
    name: '',
    address: '',
    postAddress: '',
    postCode: '',
    orgNumber: '',
    emailContact1: '',
    phoneNumberContact1: '',
    emailContact2: '',
    phoneNumberContact2: '',
    emailTechContact1: '',
    phoneNumberTechContact1: '',
    department: '',
    kamailio: -1,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t(`${translation}.name.invalid`)),
    orgNumber: Yup.string()
      .required(t(`${translation}.orgNumber.missing`))
      .test('check-format', t(`${translation}.orgNumber.invalid`), (value) =>
        Boolean(validateOrganizationNumber(value || ''))
      ),
    department: Yup.string().required(t(`${translation}.department.invalid`)),
    kamailio: Yup.number().min(1, t(`${translation}.kamailio.invalid`)),
  });

  const handleSubmit = async (
    values: DialogValues,
    { setSubmitting }: FormikHelpers<DialogValues>
  ) => {
    try {
      const updateValues = {
        ...values,
        kamailio: Number(values.kamailio),
      };
      const { id } = (await api.createEntry('customers', updateValues)) as { id: number };
      setShow(false);
      if (typeof dialogOnCreate === 'function') {
        dialogOnCreate('createCustomer', { ...updateValues, id });
      } else if (history) {
        history.push(`/admin/customers/${id}`);
      }
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Customer (create)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const CustomerFormInner = (props: FormProps & FormikProps<DialogValues>) => {
    const { handleSubmit, dirty, isSubmitting } = props;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Field {...props} id="name" type="text" md={6} />
            <Field {...props} id="orgNumber" type="text" md={6} />
            <Field {...props} id="address" type="text" md={6} />
            <Field {...props} id="postAddress" type="text" md={3} />
            <Field {...props} id="postCode" type="text" md={3} />
          </Form.Row>
          <h5>{t(`${translation}.contactsHeader`)}</h5>
          <Form.Row>
            <Field {...props} id="emailContact1" type="text" md={6} />
            <Field {...props} id="phoneNumberContact1" type="text" md={6} />
            <Field {...props} id="emailContact2" type="text" md={6} />
            <Field {...props} id="phoneNumberContact2" type="text" md={6} />
            <Field {...props} id="emailTechContact1" type="text" md={6} />
            <Field {...props} id="phoneNumberTechContact1" type="text" md={6} />
          </Form.Row>
          <h5>{t(`${translation}.extrasHeader`)}</h5>
          <Form.Row>
            <Field {...props} id="department" type="string" md={6} />
            <Field {...props} id="kamailio" type="number" md={6} />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t('buttons.cancel')}
          </Button>
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </Modal.Footer>
      </Form>
    );
  };

  const CustomerFormFormik = withFormik<FormProps, DialogValues>({
    mapPropsToValues: () => defaultValues,
    validationSchema,
    handleSubmit,
  })(CustomerFormInner);

  return (
    <>
      <SpinnerButton
        onClick={() => setShow(!show)}
        className={dialogOnCreate ? 'btn-inline' : ''}
        type="button"
        label={buttonLabel || t('buttons.plus')}
      />
      {show && (
        <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${translation}.title`)}</Modal.Title>
          </Modal.Header>
          <CustomerFormFormik translation={translation} />;
        </Modal>
      )}
    </>
  );
};
