import React from 'react';
import { ReactBootstrapField } from '../../components/fields/ReactBootstrapField';
import { ReactSelectField2 } from '../../components/fields/ReactSelectField2';
import { ReactAsyncSelectField } from '../../components/fields/ReactAsyncSelectField';

export const Field: React.SFC<any> = (props) => {
  switch (props.type) {
    case 'reactSelect2':
      return <ReactSelectField2 {...props} />;
    case 'reactAsyncSelect':
      return <ReactAsyncSelectField {...props} />;
    default:
      return <ReactBootstrapField {...props} />;
  }
};
