import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { DepartmentForm, FormValues } from './DepartmentForm';
import { api, logger, getOptionValues } from '../../lib';
import { FormikHelpers } from 'formik';
import { useStateValue } from '../../state';
import { showToast } from '../../components';
import { Option } from '../../components/fields';

export const Department: React.SFC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
  history,
}) => {
  const { id } = params;
  const translation = 'department';
  const [, dispatch] = useStateValue();
  const { t } = useTranslation();

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    try {
      const updateValues = {
        ...values,
        customer: getOptionValues(values.customer as Option),
        kamailio: Number(values.kamailio),
      };
      await api.replaceEntry('departments', String(updateValues.id), updateValues);

      resetForm({ values });
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Department (save)', error);
    } finally {
      setSubmitting(false);
    }
  };

  // INFO: NO SP exists
  const handleDelete = async (id: number) => {
    try {
      await api.deleteEntry('departments', String(id));
      history.push(`/admin/departments`);
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Department (delete)', error);
    }
  };

  return (
    <>
      <h1>{t(`${translation}.title`)}</h1>
      <p>{t(`${translation}.text`)}</p>
      <DepartmentForm
        translation={`${translation}.form`}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        id={id}
      />
    </>
  );
};
