import React from 'react';
import {
  Circle,
  CheckCircle,
  AlertCircle,
  XCircle,
  MinusCircle,
  HelpCircle,
  AlertTriangle,
  Triangle,
} from 'react-feather';

const ICON_SIZE = 20;

const icon = (icon: string | undefined) => {
  switch (icon) {
    case 'AlertTriangle':
      return <AlertTriangle size={ICON_SIZE} />;
    case 'AlertCircle':
      return <AlertCircle size={ICON_SIZE} />;
    case 'CheckCircle':
      return <CheckCircle size={ICON_SIZE} />;
    case 'HelpCircle':
      return <HelpCircle size={ICON_SIZE} />;
    case 'MinusCircle':
      return <MinusCircle size={ICON_SIZE} />;
    case 'XCircle':
      return <XCircle size={ICON_SIZE} />;
    case 'Circle':
      return <Circle size={ICON_SIZE} />;
    case 'Triangle':
      return <Triangle size={ICON_SIZE} />;
    default:
      return <HelpCircle size={ICON_SIZE} />;
  }
};

interface StatusIcon {
  value: number | string;
  label: string;
  class?: string;
  hover?: string;
  icon?: string;
}

export const StatusIcon = ({
  status,
  iconFirst = false,
}: {
  status: StatusIcon | undefined;
  iconFirst?: boolean;
}) => {
  return (
    <span title={status && status.hover}>
      {!iconFirst && status && status.label}
      <span className={status && status.class}> {icon(status && status.icon)} </span>
      {iconFirst && status && status.label}
    </span>
  );
};
