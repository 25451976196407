import React, { useState } from 'react';
import { ConfirmDialog } from './dialogs';
import { SpinnerButton } from './SpinnerButton';

interface ConfirmButtonProps {
  onConfirm: (val: any) => void;
  isSubmitting?: boolean;
  disabled?: boolean;
  title: string;
  label: string;
  messageTitle: string;
  message: string;
  className?: string;
}

export const ConfirmButton: React.SFC<ConfirmButtonProps> = ({
  onConfirm,
  isSubmitting,
  disabled,
  title,
  label,
  messageTitle,
  message,
  className,
}) => {
  const [confirmDialog, setConfirmDialog] = useState(false);

  return (
    <>
      <SpinnerButton
        className={className ? className : 'mr-1'}
        variant="danger"
        type="button"
        onClick={() => setConfirmDialog(true)}
        disabled={disabled}
        title={title}
        label={label}
        isSubmitting={isSubmitting}
      />
      <ConfirmDialog
        show={confirmDialog}
        setShow={setConfirmDialog}
        onOk={onConfirm}
        title={messageTitle}
        message={message}
      />
    </>
  );
};
