import React, { useState, ReactElement } from 'react';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CollapseButton } from './CollapseButton';

interface CollapseHeaderProps {
  id: string;
  translation?: string;
  children: ReactElement;
}

export const CollapseHeader: React.SFC<CollapseHeaderProps> = ({
  id,
  translation,
  children,
}: CollapseHeaderProps) => {
  const { t } = useTranslation();
  const [collapsed, collapseSection] = useState(true);

  return (
    <div>
      <h4 className="mt-4">
        {t(`${translation}.sections.${id}`)}
        <CollapseButton collapsed={collapsed} onClick={() => collapseSection(!collapsed)} />
      </h4>
      <Collapse in={collapsed}>{children}</Collapse>
    </div>
  );
};
