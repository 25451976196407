import React from 'react';
import { Container } from 'react-bootstrap';
import { FormikHelpers } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { api, logger, getErrorMessage } from '../../lib';
import { ForgotPasswordForm, FormValues } from './ForgotPasswordForm';

const translation = 'forgotPassword';

export const ForgotPassword: React.SFC = () => {
  const { t } = useTranslation();

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setStatus, setFieldError }: FormikHelpers<FormValues>
  ) => {
    try {
      await api.forgotPassword(values.email, `${process.env.REACT_APP_APP_PATH}/update-password`);
      setStatus({
        status: 'success',
        message: t(`${translation}.emailPasswordRecovery`, { email: values.email }),
      });
    } catch (error) {
      logger('ForgotPassword.js', error);
      const { name, message } = getErrorMessage(error);
      if (name === 'NotFoundError') {
        setFieldError('email', t(`${translation}.errors.notFound`, { email: values.email }));
      } else {
        setStatus({ status: 'danger', message });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <h3>{t(`${translation}.title`)}</h3>
      <ForgotPasswordForm translation={`${translation}.form`} onSubmit={handleSubmit} />
      <Link to="/login">{t(`${translation}.readyToLogin`)}</Link>
    </Container>
  );
};
