import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface SortedHeaderProps {
  label: string;
  id: string;
  field: string;
  ascending: boolean;
  right: boolean;
  onClick: (id: string) => void;
}

const SortHeader: React.SFC<SortedHeaderProps> = ({
  label,
  id,
  field,
  ascending,
  right,
  onClick,
}) => {
  return (
    <div
      className="d-flex"
      style={{ cursor: 'pointer', justifyContent: right ? 'flex-end' : 'normal' }}
      onClick={() => onClick(id)}
      onKeyPress={() => {}} // Added to prevent eslint error
      role="button" // Added to prevent eslint error
      tabIndex={0} // Added to prevent eslint error
    >
      <div>{label}</div>
      <div
        style={{
          fontSize: 8,
          lineHeight: '9px',
          marginLeft: 6,
          marginTop: 3,
          color: '#bbb',
        }}
      >
        <div className={id === field && ascending ? 'text-success' : ''}>▲</div>
        <div className={id === field && !ascending ? 'text-success' : ''}>▼</div>
      </div>
    </div>
  );
};

export type Column = string | string[];

export interface Sort {
  field: string;
  ascending: boolean;
}

interface SortedHeadersProps {
  translation: string;
  columns: Column[];
  sort: Sort;
  allSelected: boolean;
  onAllCheckboxChange?: () => void;
  onSortHeaderClick: (field: string) => void;
}

export const SortedHeaders: React.SFC<SortedHeadersProps> = ({
  translation,
  columns,
  sort,
  allSelected,
  onAllCheckboxChange,
  onSortHeaderClick,
}) => {
  const { t } = useTranslation();

  return (
    <Row className="number-list-header">
      {typeof onAllCheckboxChange === 'function' && (
        <Col style={{ maxWidth: '30px' }}>
          <Form.Check
            // custom
            type="checkbox"
            id="all"
            checked={allSelected}
            onChange={() => onAllCheckboxChange()}
          />
        </Col>
      )}
      {columns.map((val, index) => {
        const arr = typeof val === 'string' ? [val] : val;
        return (
          <Col key={index}>
            {arr.map((name: string) => (
              <SortHeader
                id={name}
                key={name}
                {...sort}
                onClick={onSortHeaderClick}
                label={t(`${translation}.table.${name}`)}
                right={index === columns.length - 1} // Last item
              />
            ))}
          </Col>
        );
      })}
    </Row>
  );
};
