import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { History } from 'history';
import { Field, Option, optionSchemaShape } from '../../components/fields';
import { useStateValue } from '../../state';
import { SpinnerButton, showToast, FormProps } from '../../components';
import { api, logger, useApi, getOptionValues, toOptions } from '../../lib';

export interface DialogValues {
  name: string;
  description: string;
  customer: Option | number | undefined;
  kamailio: number;
}

interface CreateDepartmentDialogProps {
  history?: History;
  buttonLabel?: string;
  dialogOnCreate?: any;
  customer?: Option;
  customers: any;
}

export const CreateDepartmentDialog: React.SFC<CreateDepartmentDialogProps> = ({
  history,
  buttonLabel,
  dialogOnCreate,
  customer,
  customers,
}) => {
  const translation = 'dialogs.createDepartment';
  const { t } = useTranslation();
  const [{ settings }, dispatch] = useStateValue();
  // const [values, setValues] = useState<DialogValues>(defaultValues);
  const [show, setShow] = useState(false);

  const defaultValues = {
    name: '',
    description: '',
    customer,
    kamailio: -1,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t(`${translation}.name.invalid`)),
    customer: Yup.object()
      .shape(optionSchemaShape)
      .nullable()
      .required(t(`${translation}.customer.invalid`)),
    kamailio: Yup.number().min(1, t(`${translation}.kamailio.invalid`)),
  });

  const handleSubmit = async (
    values: DialogValues,
    { setSubmitting }: FormikHelpers<DialogValues>
  ) => {
    try {
      const updateValues = {
        ...values,
        customer: getOptionValues(values.customer as Option),
        kamailio: Number(values.kamailio),
      };
      const { id } = (await api.createEntry('departments', updateValues)) as { id: number };
      setShow(false);
      if (dialogOnCreate) {
        dialogOnCreate('createDepartment', { ...updateValues, id });
      } else if (history) {
        history.push(`/admin/departments/${id}`);
      }
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Department (create)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const DepartmentFormInner = (props: FormProps & FormikProps<DialogValues>) => {
    const { handleSubmit, dirty, isSubmitting } = props;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Field {...props} id="name" type="text" md={6} />
            <Field
              {...props}
              id="customer"
              type="reactSelect2"
              options={toOptions(customers)}
              isClearable
              md={6}
            />
            <Field {...props} id="description" type="text" md={8} />
            <Field {...props} id="kamailio" type="number" md={4} />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t('buttons.cancel')}
          </Button>
          <SpinnerButton disabled={!dirty} label={t('buttons.save')} isSubmitting={isSubmitting} />
        </Modal.Footer>
      </Form>
    );
  };

  const DepartmentFormFormik = withFormik<FormProps, DialogValues>({
    mapPropsToValues: () => defaultValues,
    validationSchema,
    handleSubmit,
  })(DepartmentFormInner);

  return (
    <>
      <SpinnerButton
        onClick={() => setShow(!show)}
        className={dialogOnCreate ? 'btn-inline' : ''}
        type="button"
        label={buttonLabel || t('buttons.plus')}
      />
      {show && (
        <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${translation}.title`)}</Modal.Title>
          </Modal.Header>
          <DepartmentFormFormik translation={translation} />;
        </Modal>
      )}
    </>
  );
};
