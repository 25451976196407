import React from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  SpinnerButton,
  DeleteButton,
  BasicFormProps,
  FormProps,
  SpinnerPageLoading,
} from '../../components';
import { useStateValue } from '../../state';
import { Field, Option, optionSchemaShape } from '../../components/fields';
import { toOptions, useApi, toOption, getValueFromArray } from '../../lib';
import { DialogValues } from '../Departments/CreateDepartmentDialog';

export interface FormValues extends DialogValues {
  id: number;
  meta: any;
  customer: Option | number | undefined;
  kamailio: number;
}

interface DepartmentFormProps extends BasicFormProps {
  id: string;
  onDelete: (id: number) => void;
}

export const DepartmentForm: React.SFC<DepartmentFormProps> = ({
  translation,
  onSubmit,
  onDelete,
  id,
}) => {
  const { t } = useTranslation();
  const [{ settings, administrator }] = useStateValue();
  const [department, loadingDepartment] = useApi('departments', id);
  const [customers, loadingCustomers] = useApi('customers');

  if (!administrator || !settings || loadingDepartment || loadingCustomers) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const formValues = {
    ...department,
    customer: toOption(getValueFromArray(customers, department.customer as number)),
    // toOption(customers, values.customer)
    // kamailio: values.meta.customers[0] && values.meta.customers[0].kamailio,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t(`${translation}.name.invalid`)),
    customer: Yup.object()
      .shape(optionSchemaShape)
      .nullable()
      .required(t(`${translation}.customer.invalid`)),
    kamailio: Yup.number().min(1, t(`${translation}.kamailio.invalid`)),
  });

  const DepartmentFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const { handleSubmit, dirty, isSubmitting } = props;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <div>
          <Form.Row>
            <Field {...props} id="name" type="text" md={6} />
            <Field
              {...props}
              id="customer"
              type="reactSelect2"
              options={toOptions(customers)}
              isClearable
              md={6}
            />
            <Field {...props} id="description" type="text" md={8} />
            <Field {...props} id="kamailio" type="number" md={4} />
          </Form.Row>
        </div>

        {(administrator.isRole.sysAdmin || administrator.isRole.custAdmin) && (
          <div className="d-flex justify-content-end">
            <DeleteButton
              onDelete={onDelete}
              disabled
              title="Not implemented in database!"
              isSubmitting={isSubmitting}
            />
            <SpinnerButton
              disabled={!dirty}
              label={t('buttons.save')}
              isSubmitting={isSubmitting}
            />
          </div>
        )}
      </Form>
    );
  };

  const DepartmentFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => formValues,
    validationSchema,
    handleSubmit: onSubmit,
  })(DepartmentFormInner);

  return <DepartmentFormFormik translation={translation} />;
};
