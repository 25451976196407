import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ImsiForm, FormValues } from './ImsiForm';
import { api, logger } from '../../lib';
import { FormikHelpers } from 'formik';
import { useStateValue } from '../../state';
import { showToast } from '../../components';

export const Imsi: React.SFC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
  history,
}) => {
  const { id } = params;

  // Redirect to list
  if (params.id === 'refresh') {
    history.push(`/admin/imsis`);
    return null;
  }

  const translation = 'imsi';
  const { t } = useTranslation();
  const [, dispatch] = useStateValue();

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      await api.replaceEntry('imsis', String(values.id), values);
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Imsi (save)', error);
    } finally {
      setSubmitting(false);
    }
  };

  // INFO: NO SP exists
  const handleDelete = async (id: number) => {
    try {
      await api.deleteEntry('imsis', String(id));
      history.push(`/admin/imsis`);
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Imsi (delete)', error);
    }
  };

  return (
    <>
      <h1>{t(`${translation}.title`)}</h1>
      <p>{t(`${translation}.text`)}</p>
      <ImsiForm
        translation={`${translation}.form`}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        id={id}
      />
    </>
  );
};
