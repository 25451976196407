import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

// Layout
import { AppBar, SideBar, Footer } from '../layout';
import { Toasts } from '../components';

// Login/Register
import { Register } from '../pages/Register';
import { ConfirmEmail } from '../pages/ConfirmEmail';
import { Login } from '../pages/Login';
import { Logout } from '../pages/Logout';
import { ForgotPassword } from '../pages/ForgotPassword';
import { UpdatePassword } from '../pages/UpdatePassword';

// Public pages
import { Home } from '../pages/Home';
// import { Public } from '../pages/Public';
import { Page404 } from '../pages/Page404';

// Protected pages
import { Administrator } from '../pages/Administrator';
import { Administrators } from '../pages/Administrators';
import { Mobile } from '../pages/Mobile';
import { Mobiles } from '../pages/Mobiles';
import { Imsi } from '../pages/Imsi';
import { Imsis } from '../pages/Imsis';
import { Customer } from '../pages/Customer';
import { Customers } from '../pages/Customers';
import { Subscription } from '../pages/Subscription';
import { Subscriptions } from '../pages/Subscriptions';
import { CreateSubscription } from '../pages/Subscriptions/CreateSubscription';
import { Department } from '../pages/Department';
import { Departments } from '../pages/Departments';
import { Function } from '../pages/Function';
// import { DbTest } from '../pages/DbTest';

export const PublicRoutes: React.SFC<RouteComponentProps> = ({ location: { pathname } }) => {
  const [, path] = pathname.split('/');

  return (
    <>
      <AppBar />
      <Toasts />
      <div className="body-wrapper">
        <div className={`page public ${path || 'home'}`}>
          <Switch>
            <Route exact path="/" component={Home} />
            {/* <Route path="/public" component={Public} /> */}
            <Route component={Page404} />
          </Switch>
        </div>
      </div>
      <Footer />
    </>
  );
};

export const LoginRoutes: React.SFC = () => (
  <>
    <AppBar loginPage />
    <Toasts />
    <div className="page login auth">
      <Route path="/register" component={Register} />
      <Route path="/confirm-email/:token" component={ConfirmEmail} />
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/update-password/:token" component={UpdatePassword} />
      <Route path="/logout" component={Logout} />
    </div>
  </>
);

export const AdminRoutes: React.SFC<RouteComponentProps> = ({ location: { pathname } }) => {
  const [, pageName] = pathname.split('/');

  return (
    <>
      <AppBar admin />
      <Toasts />
      <div className="body-wrapper">
        <div className="left-column">
          <SideBar />
        </div>
        <div className={`page admin ${pageName || 'home'}`}>
          <Switch>
            <Route path="/admin/administrators/:id" component={Administrator} />
            <Route path="/admin/administrators" component={Administrators} />
            <Route path="/admin/mobiles/:id" component={Mobile} />
            <Route path="/admin/mobiles" component={Mobiles} />
            <Route path="/admin/imsis/:id" component={Imsi} />
            <Route path="/admin/imsis" component={Imsis} />
            <Route path="/admin/customers/:id" component={Customer} />
            <Route path="/admin/customers" component={Customers} />
            <Route path="/admin/subscriptions/create" component={CreateSubscription} />
            <Route path="/admin/subscriptions/:id" component={Subscription} />
            <Route path="/admin/subscriptions" component={Subscriptions} />
            <Route path="/admin/departments/:id" component={Department} />
            <Route path="/admin/departments" component={Departments} />
            <Route path="/admin/functions/:id" component={Function} />
            {/* <Route path="/dbtest" component={DbTest} /> */}
          </Switch>
        </div>
        {/* <div className="right-column">Plats för lite extra information.</div> */}
      </div>
    </>
  );
};
