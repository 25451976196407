import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar, Card, Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { SubscriptionForm, FormValues } from './SubscriptionForm';
import { FunctionForm, FormValues as FunctionFormValues } from '../Function/FunctionForm';
import { useApi, api, logger, getOptionValues, toLocaleDateTimeString } from '../../lib';
import { FormikHelpers } from 'formik';
import { useStateValue, Mobile } from '../../state';
import { showToast, ProgressBar2 } from '../../components';

export const Subscription: React.SFC<RouteComponentProps<{ id: string }>> = ({
  match: { params },
  history,
}) => {
  const { id } = params;
  const translation = 'subscription';
  const { t } = useTranslation();
  const [, dispatch] = useStateValue();

  const [subscription, loadingSubscription] = useApi('subscriptions', id);
  const [consumedData] = useApi(`subscriptions/consumedData/${id}`);

  const handleSubmit = async (
    rawValues: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    const { fixedNumber, imsi, portDate, portTime, changedFields = [], ...values } = rawValues;

    try {
      const subscriptionValues: any = {
        ...values,
        department: getOptionValues(values.department),
        mobileNumber: getOptionValues(values.mobileNumber),
        status: getOptionValues(values.status as any),
        type: getOptionValues(values.type as any),
        dataType: getOptionValues(values.dataType as any),
      };
      const mobileNumberId = subscriptionValues.mobileNumber;

      await api.replaceEntry('subscriptions', String(values.id), subscriptionValues);

      // Update mobile
      if (mobileNumberId) {
        const mobile = (await api.getEntry('mobiles', String(mobileNumberId))) as Mobile;

        if (changedFields.includes('mobileNumber')) {
          await api.createEntry('misc/addjob', {
            parent: 0,
            type: 16, // Ändra MobilNumber/MSISDN
            data: { mnr_id: id, newmnr_id: mobileNumberId },
          });
        } else if (changedFields.includes('imsi')) {
          await api.createEntry('misc/addjob', {
            parent: 0,
            type: 15, // Ändra IMSI
            data: { mnr_id: mobileNumberId, newims_id: imsi.value },
          });
        } else {
          if (['fixedNumber', 'portTime', 'portDate'].some((cf) => changedFields.includes(cf))) {
            await api.replaceEntry('mobiles', String(mobileNumberId), {
              ...mobile,
              fixedNumber,
              portTime: portDate ? `${portDate} ${portTime || '00:00'}` : '',
            });
          }
          if (changedFields.includes('fixedNumber')) {
            await api.createEntry('misc/addjob', {
              parent: 0,
              type: 5, // Sync Kamailio
              data: { sub_id: id, type: 'check' },
            });
          }
        }
      }

      resetForm({ values: rawValues });
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Subscription (save)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDisable = async (update: any) => {
    try {
      await api.createEntry('misc/addjob', {
        parent: 0,
        type: 3, // Cancel subscription
        data: { sub_id: id },
      });

      // Change status
      const { customer, startTime, endTime, ...updateValues } = update;
      await api.replaceEntry('subscriptions', id, {
        ...updateValues,
        startTime: startTime.substring(0, 11),
        endTime: endTime.substring(0, 11),
        status: 6,
      });

      // Reload page
      history.push('/');
      history.goBack();
    } catch (error) {
      showToast({ title: 'Disable subscription', body: error.message, type: 'danger' }, dispatch);
      logger('Subscription (disable)', error);
    }
  };

  const handleFunctionFormSubmit = async (
    update: FunctionFormValues,
    props: FormikHelpers<FunctionFormValues>
  ) => {
    const { setSubmitting, resetForm } = props;

    try {
      const { changedFields, ...values } = update;

      // Requiered to use initialValues to detect changes.
      resetForm({ values });
    } catch (error) {
      showToast({ title: 'Save', body: error.message, type: 'danger' }, dispatch);
      logger('Function (save)', error);
    } finally {
      setSubmitting(false);
    }
  };

  const totalData = consumedData && consumedData.totalData;
  const usedData = consumedData && consumedData.consumedData;
  const remaining = usedData < totalData ? Math.ceil((1 - usedData / totalData) * 100) : 0;

  return (
    <>
      <div className="float-right mt-2">
        <Card bg="light" style={{ width: '22rem' }}>
          <Card.Body>
            <Card.Title>{t(`${translation}.mobileData`)}</Card.Title>
            <Card.Text className="mb-0">
              {t(`${translation}.totalData`)}{' '}
              <strong>
                {totalData} {t(`${translation}.megabyte`)}
              </strong>
            </Card.Text>
            <Card.Text>
              {t(`${translation}.usedData`)}{' '}
              <strong>
                {usedData} {t(`${translation}.megabyte`)}
              </strong>
            </Card.Text>
            <ProgressBar2 remaining={remaining} />
          </Card.Body>
        </Card>
      </div>
      <h1>{t(`${translation}.title`)}</h1>
      <p>{t(`${translation}.text`)}</p>
      {!loadingSubscription && (
        <SubscriptionForm
          translation={`${translation}.form`}
          subscription={subscription}
          onSubmit={handleSubmit}
          onDisable={handleDisable}
        />
      )}
      <FunctionForm translation={`function.form`} onSubmit={handleFunctionFormSubmit} id={id} />
    </>
  );
};
