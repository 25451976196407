const debug = require('debug')('mvno:app');

import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Link, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStateValue, setIsAuthenticated } from '../../state';
import { api, logger, getErrorMessage } from '../../lib';
import { FormikHelpers } from 'formik';
import { LoginForm, FormValues } from './LoginForm';
import { Connect } from '../../routes';

export const Login: React.SFC<RouteComponentProps> = ({ location, history }) => {
  const [{ authenticated }, dispatch] = useStateValue();
  const { t } = useTranslation();
  const translation = 'login';

  const handleSubmit = async (
    values: FormValues,
    { setFieldError, setStatus }: FormikHelpers<FormValues>
  ) => {
    try {
      const { email, password } = values;
      await api.login(email, password);
      dispatch(setIsAuthenticated(true));
      logger('Login.js', { email });
    } catch (error) {
      logger('Login.js', error);
      const { name, message } = getErrorMessage(error);
      if (['NotFoundError', 'UnauthorizedError'].includes(name)) {
        if (message.includes('is blocked')) {
          setFieldError('email', t(`${translation}.errors.blocked`, { email: values.email }));
        } else if (name === 'NotFoundError') {
          setFieldError('email', t(`${translation}.errors.notFound`, { email: values.email }));
        } else {
          setFieldError('password', t(`${translation}.errors.unauthorized`));
        }
      } else {
        setStatus({ status: 'danger', message });
      }
    }
  };

  return !authenticated ? (
    <Container>
      <h3>{t(`${translation}.title`)}</h3>
      <LoginForm translation={`${translation}.form`} onSubmit={handleSubmit} />
      <Link to="/forgot-password">{t(`${translation}.forgotPassword`)}</Link>
    </Container>
  ) : (
    <>
      <Connect history={history} />
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    </>
  );
};
