import React, { useState } from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  SpinnerButton,
  DeleteButton,
  BasicFormProps,
  FormProps,
  SpinnerPageLoading,
} from '../../components';
// import { useStateValue } from '../../state';
import { Field } from '../../components/fields';
import { DialogValues } from '../Imsis/CreateImsiDialog';
import { useApi, toOptions, toOption, getValueFromArray } from '../../lib';

export interface FormValues extends DialogValues {
  id: number;
}

interface ImsiFormProps extends BasicFormProps {
  id: string;
  onDelete: (id: number) => void;
}

export const ImsiForm: React.SFC<ImsiFormProps> = ({ translation, onSubmit, onDelete, id }) => {
  const { t } = useTranslation();
  // const [{ settings }] = useStateValue();
  const [imsi, loadingImsi] = useApi('imsis', id);
  const [customers, loadingCustomers] = useApi('customers');

  if (loadingImsi || loadingCustomers /* || !settings */) {
    return <SpinnerPageLoading message={t('loading.page')} />;
  }

  const formValues = {
    ...imsi,
    customer: toOption(getValueFromArray(customers, imsi.customer as number)),
    // state: settings.imsiStatus.find((s: any) => values.state === s.value)
  };

  const validationSchema = Yup.object().shape({
    imsi: Yup.string().required(t(`${translation}.imsi.invalid`)),
    iccid: Yup.string().required(t(`${translation}.iccid.invalid`)),
  });

  const ImsiFormInner = (props: FormProps & FormikProps<FormValues>) => {
    const { handleSubmit, dirty, isSubmitting } = props;

    return (
      <Form noValidate onSubmit={handleSubmit}>
        <div>
          <Form.Row>
            <Field {...props} disabled id="imsi" type="text" md={6} />
            <Field {...props} disabled id="iccid" type="text" md={6} />
            <Field
              {...props}
              id="customer"
              type="reactSelect2"
              isDisabled
              options={toOptions(customers)}
              md={6}
              isClearable
            />
            <Field {...props} disabled id="pin1" type="text" md={3} />
            <Field {...props} disabled id="pin2" type="text" md={3} />
            <Field {...props} disabled id="puk1" type="text" md={3} />
            <Field {...props} disabled id="puk2" type="text" md={3} />
          </Form.Row>
        </div>

        <div className="d-flex justify-content-end">
          <DeleteButton
            onDelete={onDelete}
            disabled
            title="Not implemented in database!"
            isSubmitting={isSubmitting}
          />
          <SpinnerButton
            disabled={!dirty}
            title="Not implemented in DB!"
            label={t('buttons.save')}
            isSubmitting={isSubmitting}
          />
        </div>
      </Form>
    );
  };

  const ImsiFormFormik = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => formValues,
    validationSchema,
    handleSubmit: onSubmit,
  })(ImsiFormInner);

  return <ImsiFormFormik translation={translation} />;
};
