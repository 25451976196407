// https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c#8322
// https://github.com/huboneo/todo-list/

import React, { createContext, useContext, useReducer, Dispatch, ReactNode } from 'react';
import { Toast } from '../components';
import { Option } from '../components/fields';

export interface Claim {
  action: string;
  customerAllow: boolean;
  departmentAllow: boolean;
  id: number;
  prio: number;
  roleId: number;
  userAllow: boolean;
}

export interface Role {
  description: string;
  label: string;
  value: number;
}

export interface Department {
  id: number;
  name: string;
  description: string;
  kamilioCustomerId: number;
  customer: number;
}

export interface Customer {
  address: string;
  emailContact1: string;
  emailContact2: string;
  emailTechContact1: string;
  id: number;
  name: string;
  orgNumber: string;
  phoneNumberContact1: string;
  phoneNumberContact2: string;
  phoneNumberTechContact1: string;
  postAddress: string;
  postCode: string;
}

interface Meta {
  claims: Claim[];
  customers: Customer[];
  // departments: Department[];
  mobileStatus: Option[];
  roles: Role[];
  subscriptionCallTypes: Option[];
  subscriptionDataTypes: Option[];
  subscriptionStatus: Option[];
}

export interface Roles {
  sysAdmin: boolean;
  custAdmin: boolean;
  depAdmin: boolean;
  userAdmin: boolean;
}

export const initialAdministrator = {
  blocked: true,
  confirmed: false,
  departments: [],
  admDepartments: [],
  email: '',
  firstName: '',
  id: 0,
  language: 'sv',
  lastAdminAccess: '',
  lastName: '',
  meta: {
    claims: [],
    customers: [],
    // departments: [],
    mobileStatus: [],
    roles: [],
    subscriptionCallTypes: [],
    subscriptionDataTypes: [],
    subscriptionStatus: [],
  },
  customers: [],
  roles: [],
  phone: '',
  isRole: {
    sysAdmin: false,
    custAdmin: false,
    depAdmin: false,
    userAdmin: false,
  },
};

export interface Administrator {
  blocked: boolean;
  confirmed: boolean;
  departments: (Department | number)[];
  admDepartments: (Option | number)[];
  email: string;
  firstName: string;
  id: number;
  language: string;
  lastAdminAccess: string;
  lastName: string;
  meta: Meta;
  customers: (Customer | number)[];
  roles: (Role | number)[];
  password?: string;
  phone: string;
  isRole: Roles;
}

export interface Imsi {
  id: number;
  imsi: string;
  iccid: string;
  puk1: string;
  puk2: string;
  pin1: string;
  pin2: string;
  state: number;
}

export interface Mobile {
  id: number;
  customer: number;
  fixedNumber: string;
  imsi: number;
  mobilenUmber: string;
  portTime: string;
  status: number;
}

export interface Settings {
  buildInfo: {
    buildTime: string;
    commitTime: string;
    buildTag: string;
  };
  languages: Option[];
  roles: Role[];
  claims: Claim[];
  mobileStatus: Option[];
  customers: Customer[];
  subscriptionCallTypes: Option[];
  subscriptionDataTypes: Option[];
  subscriptionStatus: Option[];
}

export const initialSettings: Settings = {
  buildInfo: {
    buildTime: '0000-00-00T00:00:00+0000', //2021-03-07T09:09:23+0000
    commitTime: '0000-00-00T00:00:00+00:00', // 2021-03-07T09:45:41+01:00
    buildTag: '0.0.0',
  },
  languages: [
    { value: 'sv', label: 'Svenska' },
    { value: 'en', label: 'Engelska' },
  ],
  roles: [],
  claims: [],
  mobileStatus: [],
  customers: [],
  subscriptionCallTypes: [],
  subscriptionDataTypes: [],
  subscriptionStatus: [],
};

export interface State {
  authenticated: boolean;
  administrator: Administrator;
  toasts: Toast[];
  settings: Settings;
}

export interface Action {
  type: string;
  payload?: any;
}

export type DispatchAction = Dispatch<Action>;

export type ContextState = [State, DispatchAction];

const StateContext = createContext<any>(null);

export const StateProvider = ({
  reducer,
  initialState,
  children,
}: {
  reducer: (state: State, action: Action) => State;
  initialState: State;
  children: ReactNode;
}) => {
  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext) as ContextState;
